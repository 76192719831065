import React, { useContext, useEffect, useState } from "react";

import { Modal, Button } from "antd";
import UserContext from "../../Context/UserContext";

const StopOrderModal = ({
  modalOpenStopOrder,
  setModalOpenStopOrder,
  socket,
  modifiedData,
  setRefresh,
}) => {
  const [deneme, setDeneme] = useState("");
  const {user} = useContext(UserContext)

  useEffect(() => {
    const timer = setInterval(() => {
      setModalOpenStopOrder(false);
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    setDeneme(
      modifiedData
        .filter((item) => item.is_started_asma === true)
        .map((item) => item.fiiliMiktar)[0]
    );
  }, [
    modifiedData
      .filter((item) => item.is_started_asma === true)
      .map((item) => item.fiiliMiktar)[0],
  ]);

  const handleYesButton = () => {
    if (socket) {
      socket.send(
        JSON.stringify({
          event_type: "CompleteEvent",
          user:user.accountDetail.name,

        })
      );
    }

    setModalOpenStopOrder(false);
    setRefresh((p) => !p);
    window.location.reload();
  };

  const handleNoButton = () => {
    if (socket) {
      socket.send(
        JSON.stringify({
          event_type: "StopEvent",
        })
      );
    }
    setModalOpenStopOrder(false);
  };

  return (
    <>
      <Modal
        title=""
        open={modalOpenStopOrder}
        footer={null}
        onOk={() => setModalOpenStopOrder(false)}
        onCancel={() => {
          setModalOpenStopOrder(false);
        }}
        className="min-w-max"
      >
        <div className=" p-5">
          <div className="flex justify-center items-center flex-col">
            <span className="text-[25px] font-extrabold">Fiili Miktar</span>
            <input
              onWheel={(e) => e.target.blur()} 
              type="number"
              className="border-[2px] border-black text-[35px] h-[100px]"
            />
            <span className="font-[25px] text-[25px]">
              Mevcut Fiili Miktar: {deneme}
            </span>
          </div>

          <div className="text-center mt-7 text-[25px] font-extrabold">
            Siparişi Bitirmek İstediğinize Emin misiniz?
          </div>
          <div className="flex justify-center gap-4 mt-[30px]">
            <Button
              onClick={handleYesButton}
              className="bg-mainGreen hover:bg-secondGreen w-[300px] h-[70px] text-[30px] text-white hover:text-[35px] hover:!text-white"
            >
              SİPARİŞİ BİTİR
            </Button>
            <Button
              onClick={handleNoButton}
              className="bg-mainRed hover:bg-secondRed w-[300px] h-[70px] text-[30px] text-white hover:text-[35px] hover:!text-white"
            >
              SİPARİŞİ DURDUR
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StopOrderModal;
