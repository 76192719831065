import React, { useContext } from "react";
import "./ChangeOrderModal.css";
import { Modal } from "antd";
import { useState } from "react";
import { SwapOutlined } from "@ant-design/icons";
import ModalHeaderLayout from "../../../layouts/ModalHeaderLayout";
import UserContext from "../../../Context/UserContext";

const ChangeOrderModal = ({ isModalOpen, setIsModalOpen, socket }) => {
  const {user} = useContext(UserContext)

  const [changeOrderData, setChangeOrderData] = useState({
    seq1: "",
    seq2: "",
  });

  const handleChange = (e) => {
    setChangeOrderData({ ...changeOrderData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    if (changeOrderData.seq1 > changeOrderData.seq2) {
      socket.send(
        JSON.stringify({
          event_type: "PeriodChangeEvent",
          seq1: changeOrderData.seq2,
          seq2: changeOrderData.seq1,
          user:user.accountDetail.name,

        })
      );
    } else {
      socket.send(
        JSON.stringify({
          event_type: "PeriodChangeEvent",
          seq1: changeOrderData.seq1,
          seq2: changeOrderData.seq2,
          user:user.accountDetail.name,

        })
      );
    }
  };

  return (
    <Modal
      title=<ModalHeaderLayout title="Sipariş Değiştir" />
      bodyStyle={{ height: "350px" }}
      open={isModalOpen}
      footer={null}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => {
        setChangeOrderData({
          seq1: "",
          seq2: "",
        });
        setIsModalOpen(false);
      }}
      centered
      className="min-w-max"
    >
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label>Eski</label>
            <input
              name="seq1"
              onWheel={(e) => e.target.blur()} 
              type="number"
              required
              min="1"
              max="50"
              onChange={handleChange}
            />
          </div>
          <div className="changeOrderIcon">
            <SwapOutlined />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label>Yeni</label>
            <input
              name="seq2"
              required
              min="1"
              max="50"
              onWheel={(e) => e.target.blur()} type="number"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="changeReason">
          <label>Değişiklik Nedeni :</label>
          <select
            required
            className="changeOrderSelect"
            style={{ width: "70%" }}
            onChange={handleChange}
          >
            <option selected disabled value="">
              Seç
            </option>
            <option value="Anamontaj Planı Değişti">
              Anamontaj Planı Değişti
            </option>
            <option value="U Gövde stokta yok">U Gövde stokta yok</option>
            <option value="Single Gövde stokta yok">
              Single Gövde stokta yok
            </option>
          </select>
        </div>
        <button
          disabled={changeOrderData.seq1 < 1 || changeOrderData.seq2 < 1}
          className="submitButton"
          type="submit"
        >
          Gönder
        </button>
      </form>
    </Modal>
  );
};

export default ChangeOrderModal;
