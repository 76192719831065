import React, { useContext, useState, useEffect } from 'react'
import './FirstPage.css'
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
// import UserContext from '../../Context/UserContext';
import TermsConditions from '../../Components/TermsConditions';
import UserContext from '../../Context/UserContext';
// import { useAuth0 } from '@auth0/auth0-react';


const FirstPage = () => {
    const isAuthenticated = useIsAuthenticated()
    const { instance } = useMsal()

    const {  setUser } = useContext(UserContext)
    const [checkControl, setCheckControl] = useState(false)

    const handleChangeCheck = () => {

        setCheckControl(!checkControl)

        // if (checkControl === false) {
        //     console.log("true")
        // }
        // if (checkControl === true) {
        //     console.log("false")
        // }

    }

    const handleSignIn = () => {
        instance.loginRedirect({
            scopes: ['user.read']
        })
    }

    useEffect(() => {
        setUser({ isLogin: isAuthenticated, accountDetail: instance?.getActiveAccount() });
    }, [isAuthenticated, instance])

    // console.log("home active account", instance.getActiveAccount());

    // console.log(checkControl)
    // const { loginWithRedirect, isAuthenticated  } = useAuth0();
    // useEffect(() => {
    //     // Sayfa yüklendiğinde loginWithRedirect() otomatik olarak çalışsın
    //     loginWithRedirect();
    // }, []);


    return (
        <div>
            <div className='homePage' >
                <div className='terms-box'>
                    <div >
                        <div className='terms-text'>
                            <TermsConditions />
                        </div>
                        <div>

                            <label style={{fontSize: "13px"}}>
                            <input
                            style={{marginRight: "4px"}}
                                type="checkbox"
                                value={checkControl}
                                onClick={handleChangeCheck}
                            />
                             Okudum,anladım. Kabul ediyorum.
                            </label>
                        </div>
                        <div className='signInButton'>
                            <Button disabled={checkControl === false} className="signInOn" 
                            // onClick={handleSignIn}
                            onClick={handleSignIn}
                            >Sign In</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FirstPage
