import React from 'react'

const TermsConditions = () => {
    return (
        <div>
            <p>
                <span style={{ fontWeight: "bold", textAlign: "center", alignItems: "center" }}>BSH EV ALETLERİ SANAYİ VE TİCARET A.Ş.
                    KONTEYNER TAKİP PORTALI KULLANICILARI
                    AYDINLATMA METNİ</span>
                <br />
                <br />
                <span style={{ fontWeight: "bold", textAlign: "center" }}>1.VERİ SORUMLUSU</span>
                <br />
                Kişisel verileriniz veri sorumlusu sıfatıyla Fatih Sultan Mehmet Mahallesi Balkan Caddesi No:51 34771 Ümraniye/İstanbul adresinde mukim BSH Ev Aletleri Sanayi ve Ticaret A.Ş. (“Şirket”) tarafından, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) uyarınca aşağıda açıklanan kapsamda işlenebilecektir.

                Kişisel verilerinizin Şirketimiz tarafından işlenme amaçları konusunda detaylı bilgiler, https://www.bsh-group.com/tr/ adresinden erişilebilen BSH Ev Aletleri Sanayi ve Ticaret A.Ş. Kişisel Verilerin Korunması ve İşlenmesi Politikası’nda yer almaktadır.
                <br />
                <br />
                <span style={{ fontWeight: "bold", textAlign: "center" }}>2.KİŞİSEL VERİLERİNİZİN İŞLENME AMACI VE HUKUKİ SEBEPLERİ</span>
                <br />
                Toplanan kişisel verileriniz, Kanun’da öngörülen temel ilkelere uygun olarak ve Kanun’un 5. maddesinde belirtilen kişisel veri işleme şartları dahilinde, Şirketimiz ile çalışanı/yetkilisi olduğunuz firma arasındaki iş ilişkisi kapsamında aşağıdaki amaçlar (“Amaçlar”) ve hukuki sebeplerle işlenecektir.
                <br />
                İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması hukuki sebebine dayalı olarak;
                <br />
                •	Konteyner Takip Portal’ına (“Portal”) kayıt işlemlerinizin gerçekleştirilmesi,
                <br />
                •	Portal hesabınızın işletilmesi,
                <br />
                •	Portal’a ilişkin güvenlik tanımlamalarının yapılması,
                <br />
                •	Lojistik süreçlerinin planlanması ve yönetimi,
                <br />
                •	Şirketimizin ticari veya iş stratejilerinin planlanması veya icrası,
                <br />
                •	Finans veya muhasebe işlerinin takibi,
                <br />
                •	Gerekli raporlamaların sağlanması,
                <br />
                •	Verilerin doğru ve güncel olmasının sağlanması,
                <br />
                •	İş faaliyetlerinin etkinlik/verimlilik veya yerindelik analizlerinin gerçekleştirilmesi,
                <br />
                •	Şirketimizin dahil olduğu hukuki süreçlerin yürütülmesi,
                <br />
                •	Şirketimizin ve Şirketimizle iş ilişkisi içerisinde olan ilgili kişilerin hukuki ve teknik güvenliğinin temini faaliyetlerinin planlanması veya icrası,
                <br />
                •	Şirketimiz tarafından yürütülen ticari veya operasyonel faaliyetlerin gerçekleştirilmesi için ilgili iş birimlerimiz tarafından gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi.
                <br />
                Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması hukuki sebebine dayalı olarak;
                <br />
                •	İleride meydana gelmesi muhtemel olası uyuşmazlıklarda delil teşkil etmesi amacı ile kişisel verilerin genel zamanaşımı süresince saklanması.
                <br />
                Kanunlarca açıkça öngörülmesi ve veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması hukuki sebebine dayalı olarak;
                <br />
                •	Resmi kurum taleplerinin yerine getirilmesi,
                <br />
                •	Verilerin doğru ve güncel olmasının sağlanması,
                <br />
                •	Yetkili kuruluşlara mevzuattan kaynaklı bilgi verilmesi,
                <br />
                •	Mevzuatta öngörülen saklama yükümlülüklerine uygunluğun sağlanması,
                <br />
                •	İlgili kişi başvurularının mevzuata uygun olarak yanıtlanması ve gerekli işlemlerin gerçekleştirilmesi.
                <br />
                <br />
                <span style={{ fontWeight: "bold", textAlign: "center" }}>3.KİŞİSEL VERİLERİNİZİN AKTARILDIĞI TARAFLAR VE AKTARIM AMAÇLARI</span>
                <br />
                Toplanan kişisel verileriniz, Kanun’da öngörülen temel ilkelere uygun olarak ve Kanun’un 8. maddesinde belirtilen aktarım şartları dahilinde, Şirketimiz tarafından Amaçlar kapsamında tedarikçilerimize, iş ortaklarımıza, yetkili servislerimize, kanunen yetkili kamu kurumlarına veya özel kurumlara, açık rızanızı vermeniz halinde ise kimlik ve iletişim verileriniz barındırma hizmeti alınması kapsamında Portal’ın barındırma alt yapısını sağlayan yurt dışında mukim tedarikçi Amazon Web Services Inc.’e Kanun’un 9.maddesine uygun olarak aktarılabilecektir.
                <br />
                <br />
                <span style={{ fontWeight: "bold", textAlign: "center" }}>4.KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMİ</span>
                <br />
                Kişisel verileriniz, Şirketimiz tarafından Şirketimizle temas etmeniz halinde, elektronik ortamda Portal, elektronik posta ve telefon görüşmesi kanallarıyla (yöntemleriyle) toplanabilecektir.
                <br /><br />
                <span style={{ fontWeight: "bold", textAlign: "center" }}>5.İLGİLİ KİŞİ OLARAK KANUN’UN 11. MADDESİNDE SAYILAN HAKLARINIZ</span>
                <br />
                İlgili kişi olarak Kanun’un 11. maddesinde düzenlenen haklarınıza yönelik başvurularınızı, https://media3.bsh-group.com/Documents/<br />MCDOC02939519_BSH_Veri_Sahibi_Basvuru_Formu.pdf adresinden ulaşabileceğiniz BSH Ev Aletleri Sanayi ve Ticaret A.Ş. Veri Sahibi Başvuru Formu’nu doldurarak Şirketimize iletebilirsiniz. Talebinizin niteliğine göre en kısa sürede ve en geç otuz gün içinde başvurularınız ücretsiz olarak sonuçlandırılacaktır; ancak işlemin ayrıca bir maliyet gerektirmesi halinde Kişisel Verileri Koruma Kurulu tarafından belirlenecek tarifeye göre tarafınızdan ücret talep edilebilecektir.
                <br />
                <br />
                <span style={{ fontWeight: "bold", textAlign: "center", alignItems: "center" }}>KONTEYNER TAKİP PORTALI KULLANICISI ARAYÜZ ÖNERİSİ VE AÇIK RIZA METNİ</span>
                <br />

                Kişisel verileriniz BSH Ev Aletleri Sanayi ve Ticaret A.Ş. Konteyner Takip Portalı Kullanıcıları Aydınlatma Metni uyarınca işlenmektedir.
                <br />
                <br />
                Kimlik ve iletişim verilerimin, barındırma hizmeti alınması kapsamında Portal’ın barındırma alt yapısını sağlayan yurt dışında mukim tedarikçi Amazon Web Services Inc.’e aktarılmasını kabul ediyorum.

            </p>
        </div>
    )
}

export default TermsConditions