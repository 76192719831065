import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Radio,
  Cascader,
  Upload,
  Rate,
  Drawer,
  Steps,
  Divider
} from 'antd';

import { SendOutlined } from '@ant-design/icons';

import { useLocation } from "react-router-dom";
import { useContext } from "react";
import axios from 'axios'
import CachedIcon from '@mui/icons-material/Cached';
import * as XLSX from 'xlsx'
import GetAppIcon from '@mui/icons-material/GetApp';
import UserContext from '../Context/UserContext';
import { apiUrl } from '../config';
import TableContext from '../Context/TablePosition';
const { TextArea } = Input;
const { Step } = Steps


const NewReport = () => {

  const location = useLocation();
  const { user } = useContext(UserContext)

  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState();
  const [incidents, setGetIncidents] = useState([])
  const [incidentsAll, setGetIncidentsAll] = useState([])
  const [status1, setStatus1] = useState([])
  const [status2, setStatus2] = useState([])
  const [status3, setStatus3] = useState([])
  const [status1Role, setStatus1Role] = useState([])
  const [status2Role, setStatus2Role] = useState([])
  const [status3Role, setStatus3Role] = useState([])
  const [modifiedData, setModifiedData] = useState([])
  const { setCurrentUrl } = useContext(TableContext)

  const [tabIndex, setTabIndex] = useState(1);

  const [formData, setFormData] = useState({
    topic: "",
    islem: "",
    table: "",
    importance: 1,
    desc: "",
    desc2: "",
  })

  const [modalOpen, setModalOpen] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false)

  const getData = () => {
    setLoading(true);
    axios.get(`${apiUrl}/getIncidents/${user.accountDetail.name}`)
      .then(response => setGetIncidents(response.data))
      .catch((error) => console.log(error.message))
    setLoading(false);
  };


  const getDataAll = () => {
    setLoading(true);

    axios.get(`${apiUrl}/getIncidents/all`)
      .then(response => setGetIncidentsAll(response.data))
      .catch((error) => console.log(error.message))
    setLoading(false);
  };

  useEffect(() => {
    const status1 = incidentsAll.filter((item => item?.status === "1"))
    const status2 = incidentsAll.filter((item => item?.status === "2"))
    const status3 = incidentsAll.filter((item => item?.status === "3"))
    setStatus1(status1)
    setStatus2(status2)
    setStatus3(status3)

  }, [incidentsAll])

  useEffect(() => {
    const status1Role = incidents.filter((item => item?.status === "1"))
    const status2Role = incidents.filter((item => item?.status === "2"))
    const status3Role = incidents.filter((item => item?.status === "3"))
    setStatus1Role(status1Role)
    setStatus2Role(status2Role)
    setStatus3Role(status3Role)

  }, [incidents])


  const onFinish = (values) => {
    setIsDisabled(true)
    axios.post(`${apiUrl}/addIncident`, ({
      islem: formData.islem,
      topic: formData.topic,
      table: formData.table.toString(),
      importance: formData.importance,
      desc: formData.desc,
      user: user.accountDetail.idTokenClaims.name, idToken: user.accountDetail.username,
      role: user.accountDetail.idTokenClaims.roles[0],
      email: user.accountDetail.idTokenClaims.email
    })).then(res => {
      setLoading(true)
      if (res.status === 200) {
        setFormData({
          topic: "",
          islem: "",
          table: "",
          importance: "",
          desc: "",
          desc2: ""
        })
        setModalOpen(true)
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
    });
  };

  const handleClickDesc = (e) => {
    e.preventDefault()
    axios.post(`${apiUrl}/addIncident`, ({
      desc2: formData.desc2,
    })).then(res => {
      setLoading(true)
      if (res.status === 200) {
        setFormData({
          topic: "",
          islem: "",
          table: "",
          importance: "",
          desc: "",
          desc2: ""
        })

      }
    });
  }

  useEffect(() => {
    getDataAll();
    getData();
  }, [refresh]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    localStorage.setItem("urlpaintshop", location.pathname)
    setCurrentUrl(localStorage.getItem("urlpaintshop"));
  }, [location.pathname, setCurrentUrl])

  const desc = ['Normal', 'Önemli', 'Çok Önemli'];

  useEffect(() => {
    if (user.accountDetail.idTokenClaims.roles[0] === "ps_admin") {
      const dataList = ((tabIndex === 1 && incidentsAll) || (tabIndex === 2 && status1) || (tabIndex === 3 && status2) || (tabIndex === 4 && status3)).map((item, index) => ({

        User: item?.user,
        Topic: item?.topic,
        Table: item?.tableName,
        Description: item?.description,
        CreateDate: item?.createDate?.slice(0, 10) + " " + item?.createDate?.slice(11, 16),
        Response: item?.response,
        Email: item?.replyEmail

      }))
      setModifiedData([...dataList]);
    }
    else {
      const dataList = ((tabIndex === 1 && incidents) || (tabIndex === 2 && status1Role) || (tabIndex === 3 && status2Role) || (tabIndex === 4 && status3Role)).map((item, index) => ({

        User: item?.user,
        Topic: item?.topic,
        Table: item?.tableName,
        Description: item?.description,
        CreateDate: item?.createDate?.slice(0, 10) + " " + item?.createDate?.slice(11, 16),
        Response: item?.response,
        Email: item?.replyEmail

      }))
      setModifiedData([...dataList]);
    }


  }, [incidentsAll, status1, status2, status3, tabIndex, incidents])

  console.log(formData)

  const handleOnExport = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(modifiedData);

    XLSX.utils.book_append_sheet(wb, ws, "Requests");
    XLSX.writeFile(wb, "Requests.xlsx")
  }
  return (
    <div className='flex flex-col w-[90%] laptop:w-[55%] m-auto bg-white p-4 shadow-2xl rounded-3xl	'>
      <div className='flex items-center w-full h-full justify-between'>
        <h2 className='text-4xl underline font-bold flex-1 text-center'>Talep Formu</h2>
        <Button className="flex" type="primary"
          onClick={() => {
            setVisible(true)
            setSize('large')
          }
          }>
          {"Taleplerim"}
        </Button>
      </div>

      <Drawer size={size} visible={visible} placement="right" onClose={() => setVisible(false)} markClosable={true}>
        <Button style={{ marginLeft: "85%", marginTop: "-70px", position: "absolute" }} onClick={() => setRefresh(!refresh)}><CachedIcon /></Button>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => setTabIndex(1)}
          >
            Tümü ({user?.accountDetail?.idTokenClaims?.roles[0] === "ps_admin" ? incidentsAll.length : incidents.length})
          </Button>
          <Button
            onClick={() => setTabIndex(2)}
          >
            Bekleyenler ({user?.accountDetail?.idTokenClaims?.roles[0] === "ps_admin" ? status1.length : status1Role.length})
          </Button>
          <Button
            onClick={() => setTabIndex(3)}
          >
            İşlemde olanlar ({user?.accountDetail?.idTokenClaims?.roles[0] === "ps_admin" ? status2.length : status2Role.length})
          </Button>
          <Button
            onClick={() => setTabIndex(4)}
          >
            Tamamlananlar ({user?.accountDetail?.idTokenClaims?.roles[0] === "ps_admin" ? status3.length : status3Role.length})
          </Button>
          <Button className="exportDataHelp" onClick={handleOnExport}><GetAppIcon /></Button>
        </div>
        <Divider />
        {
          user?.accountDetail?.idTokenClaims?.roles[0] === "ps_admin" ?
            ((tabIndex === 1 && incidentsAll) || (tabIndex === 2 && status1) || (tabIndex === 3 && status2) || (tabIndex === 4 && status3)).map(item => {
              return (
                <div key={item?.id}>
                  <div className='flex flex-row'>
                    <div className='flex flex-col w-full'>
                      <div><b>Kullanıcı : </b> {item?.user}</div>
                      <div><b>Oluşturma Tarihi : </b> {item?.createDate.slice(0, 10) + " / " + item?.createDate.slice(11, 16)} </div>
                      <div><b>Başlık : </b> {item?.topic}</div>
                      <div><b>Tablo : </b> {item?.tableName.replace(/,/g, " -> ")}</div>
                      <p><b>Açıklama : </b> {item?.description}<br /></p>
                      <p><b>Cevap : </b> {item?.response}<br /></p>
                      {/* {
                        item?.response !== null &&
                        <div>
                          <div className='flex flex-row items-center'>

                            <div className='flex w-full'>
                              <p className='flex items-center w-full'><b>Açıklama-2 : </b>
                              {
                                item.desc2 !== null ? item.desc2 
                                :
                                <textarea name="desc2" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} className='border-2 w-[80%] ml-2' />
                              }
                              </p>
                              {
                                item.desc2 === null &&
                                <Button className='flex justify-center items-center' onClick={(e) => handleClickDesc(e)}><SendOutlined /></Button>
                              }
                            </div>
                          </div>
                          <p><b>Cevap-2 : </b> {item?.response2}<br /></p>
                        </div>

                      } */}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "200px" }}>
                      <Steps size="small" direction="vertical" current={item?.status}>
                        <Step title="Bekliyor"></Step>
                        <Step title="İşlemde"></Step>
                        <Step title="Tamamlandı"></Step>
                      </Steps>
                    </div>

                  </div>
                  <Divider />
                </div>
              )

            })
            : ((tabIndex === 1 && incidents) || (tabIndex === 2 && status1Role) || (tabIndex === 3 && status2Role) || (tabIndex === 4 && status3Role)).map(item => {
              return (
                <div key={item?.id}>
                  <div className='flex flex-row'>
                    <div className='flex flex-col w-full'>
                      <div><b>Kullanıcı : </b> {item?.user}</div>
                      <div><b>Oluşturma Tarihi : </b> {item?.createDate.slice(0, 10) + " / " + item?.createDate.slice(11, 16)} </div>
                      <div><b>Başlık : </b> {item?.topic}</div>
                      <div><b>Tablo : </b> {item?.tableName.replace(/,/g, " -> ")}</div>
                      <p><b>Açıklama : </b> {item?.description}<br /></p>
                      <p><b>Cevap : </b> {item?.response}<br /></p>
                      {/* {
                        item?.response !== null &&
                        <div>
                          <div className='flex flex-row items-center'>
                            {
                              item?.desc2 !== null ? item?.desc2
                                :
                                <div>
                                  <p className='flex items-center w-full'><b>Açıklama-2 : </b> <textarea name="desc2" onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} className='border-2 w-[80%] ml-2' /><br /></p>
                                  <Button className='flex justify-center items-center' onClick={(e) => handleClickDesc(e)}><SendOutlined /></Button>
                                </div>
                            }

                          </div>
                          <p><b>Cevap-2 : </b> {item?.response2}<br /></p>
                        </div>
                      } */}

                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "200px" }}>
                      <Steps size="small" direction="vertical" current={item?.status}>
                        <Step title="Bekliyor"></Step>
                        <Step title="İşlemde"></Step>
                        <Step title="Tamamlandı"></Step>
                      </Steps>
                    </div>

                  </div>
                  <Divider />
                </div>
              )

            })
        }
      </Drawer>


      <Form onFinish={onFinish}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
      >   <br />

        <Form.Item label="Kullanıcı" >
          <span style={{ fontWeight: "bold", fontSize: "15px" }}> {user.accountDetail.name}</span>
        </Form.Item>
        <Form.Item name="islem" rules={[
          {
            required: true,
            message: 'Lütfen seçim yapınız!',
          },
        ]} label="İşlem">
          <Radio.Group name="islem" onChange={handleChange}>
            <Radio value="hata"> Hata</Radio>
            <Radio value="talep"> Talep </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="topic" rules={[
          {
            required: true,
            message: 'Lütfen alanı doldurunuz!',
          },
        ]} label="Başlık" >
          <Input maxLength="50" name="topic" onChange={handleChange} />
        </Form.Item>


        <Form.Item name="table" rules={[
          {
            required: true,
            message: 'LÜtfen Tablo seçiniz!',
          },
        ]} label="Tablo">
          <Cascader onChange={(table) => setFormData({ ...formData, table })}
            options={
              [
                {
                  value: 'Asma',
                  label: 'Asma',
                  // children: [
                  //   {
                  //     value: 'Import',
                  //     label: 'Import',
                  //     children: [
                  //       {
                  //         value: "İthalat Dolu",
                  //         label: "İthalat Dolu",
                  //       },

                  //     ],
                  //   },

                  // ],
                },
                {
                  value: 'Asma SPV',
                  label: 'Asma SPV',

                },
                {
                  value: 'Toplama',
                  label: 'Toplama',

                },
                {
                  value: 'Stok Seviyeleri',
                  label: 'Stok Seviyeleri',

                },
                {
                  value: 'PowerBI',
                  label: 'PowerBI',

                },
                {
                  value: 'Dashboard',
                  label: 'Dashboard',

                },
                {
                  value: 'Alarm',
                  label: 'Alarm',

                },
                {
                  value: 'Alarm2',
                  label: 'Alarm2',

                },
                {
                  value: 'Üretim',
                  label: 'Üretim',

                },

                {
                  value: 'Rework/Hurda',
                  label: 'Rework/Hurda',

                },
                {
                  value: 'Durum Bilgisi',
                  label: 'Durum Bilgisi',

                },
                {
                  value: 'Vardiya Planı',
                  label: 'Vardiya Planı',

                },
                {
                  value: 'Kontrol Geçmişi',
                  label: 'Kontrol Geçmişi',

                },
                {
                  value: 'Arıza Geçmişi',
                  label: 'Arıza Geçmişi',

                },
                {
                  value: 'Diğer',
                  label: 'Diğer',

                },
              ]}
          />
        </Form.Item>
        <Form.Item name="importance" label="Önem Derecesi">
          <span>
            <Rate onChange={(importance) => setFormData({ ...formData, importance })} tooltips={desc} count={3} value={formData.importance} style={{ color: "red" }}
            />
            {formData.importance ? <span className="ant-rate-text">{desc[formData.importance - 1]}</span> : ''}
          </span>
        </Form.Item>
        <Form.Item name="desc" rules={[
          {
            required: true,
            message: 'Lütfen alanı doldurunuz!',
          },
        ]} label="Açıklama">
          <TextArea name="desc" onChange={handleChange} rows={5} />
        </Form.Item>


        <Button key="submit" htmlType="submit" className="flex w-full justify-center bg-smartopt text-white text-xl font-semibold items-center"
          disabled={isDisabled === true}
        >
          <span>Gönder</span>
        </Button>


      </Form>

    </div>
  )
}

export default NewReport