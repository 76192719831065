import React, { useContext, useEffect } from "react";
import StokTable from "../Components/StokTable";
import StokTable1 from "../Components/StokTable1";
import TableContext from "../Context/TablePosition";
import { useLocation } from "react-router-dom";


export default function StokPage() {

  const { setCurrentUrl } = useContext(TableContext)
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("urlpaintshop", location.pathname)
    setCurrentUrl(localStorage.getItem("urlpaintshop"));
  }, [location.pathname, setCurrentUrl])
  
  return (
    <div className="flex w-full flex-col h-full mt-4 overflow-auto ">
      <div className="flex flex-col desktop:flex-row gap-4">
        <StokTable />
        <StokTable1 />
      </div>
    </div>
  );
}
