import React, { useContext, useEffect } from "react";
import { Modal } from "antd";
import { useState } from "react";
import ModalHeaderLayout from "../../layouts/ModalHeaderLayout";
import UserContext from "../../Context/UserContext";

const AddOrderModal = ({
  isModalOpenAddOrder,
  setIsModalOpenAddOrder,
  socket,
  allData,
}) => {
  const [formData, setFormData] = useState({
    target: "",
    order: "",
    tip: "",
    color: "",
    sequence: ""
  });
  const { user } = useContext(UserContext)


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let allSPVNo = allData
    .filter(item => item.fields.is_started_asma === false)
    .map(item => item.fields.sequence);


  const handleSubmit = (e) => {
    socket.send(
      JSON.stringify({
        event_type: "NewOrderAddEvent",
        production: formData.target,
        siparis: formData.order,
        sequence: formData.sequence,
        tip: formData.tip,
        renk: formData.color,
        user: user.accountDetail.name,

      })
    );
  };

  return (
    <Modal
      title=<ModalHeaderLayout title="Sipariş Ekle" />
      open={isModalOpenAddOrder}
      footer={null}
      onOk={() => setIsModalOpenAddOrder(false)}
      onCancel={() => {
        setFormData({
          target: "",
          order: "",
          tip: "",
          color: "",
          sequence: ""
        });
        setIsModalOpenAddOrder(false);
      }}
      centered
      className="min-w-max mb-40"
    >
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col text-3xl gap-5 p-5 w-[700px]">
          <div className="flex flex-row w-full gap-2 items-center">
            <div className="w-56 flex justify-between">
              <label>Hedef</label>
              <span>:</span>
            </div>
            <input
              name="target"
              onWheel={(e) => e.target.blur()} 
              type="number"
              required
              min="1"
              onChange={handleChange}
              value={formData.target}
              placeholder="Hedef miktarını giriniz."
              className="flex w-full text-center text-3xl border-[2px] border-black rounded-md"
            />
          </div>

          <div className="flex flex-row w-full gap-2 items-center">
            <div className="w-56 flex justify-between">
              <label>Sipariş</label>
              <span>:</span>
            </div>
            <select
              name="order"
              onChange={handleChange}
              value={formData.order}
              required
              className="flex w-full text-center text-3xl border-[2px] border-black rounded-md"
            >
              <option disabled selected value="">
                Seç
              </option>
              <option value="Çift Gövde Ön">Çift Gövde Ön</option>
              <option value="Gövde">Gövde</option>
            </select>
          </div>
          <div className="flex flex-row w-full gap-2 items-center">
            <div className="w-56 flex justify-between">
              <label>Tip</label>
              <span>:</span>
            </div>

            <select
              name="tip"
              onChange={handleChange}
              value={formData.tip}
              required
              className="flex w-full text-center text-3xl border-[2px] border-black rounded-md"
            >
              <option disabled selected value="">
                Seç
              </option>
              {(() => {
                switch (formData.order) {
                  case "Gövde": return <>
                    <option value="Single F14">Single F14 Gövde</option>
                    <option value="U">U Gövde</option>
                  </>
                  case "Çift Gövde Ön": return <>
                    <option value="F14">F14</option>
                    <option value="F23">F23</option>
                  </>
                  default: return null
                }
              })()}

            </select>
          </div>
          <div className="flex flex-row w-full gap-2 items-center">
            <div className="w-56 flex justify-between">
              <label>Renk</label>
              <span>:</span>
            </div>

            <select
              name="color"
              onChange={handleChange}
              value={formData.color}
              required
              className="flex w-full text-center text-3xl border-[2px] border-black rounded-md"
            >
              <option disabled selected value="">
                Seç
              </option>
              <option value="Silver">Silver</option>
              <option value="Beyaz">Beyaz</option>
              <option value="Cast Iron">Cast Iron</option>

            </select>
          </div>
          <div className="flex flex-row w-full gap-2 items-center">
            <div className="w-56 flex justify-between">
              <label>SPV No</label>
              <span>:</span>
            </div>
            <select
              name="sequence"
              onChange={handleChange}
              value={formData.sequence}
              required
              className="flex w-full text-center text-3xl border-[2px] border-black rounded-md"
            >
              <option className="text-lg" disabled selected value="">
                Seç
              </option>
              {
                allSPVNo?.map((item,index) => {
                  return <option key={index} className="text-lg" value={item}>{item}</option>
                })
              }
            </select>
          </div>
        </div>

        <button
          className="h-[80px] bg-mainYellow w-full text-[40px] rounded-b-xl hover:bg-secondYellow"
          type="submit"
        >
          Gönder
        </button>
      </form>
    </Modal>
  );
};

export default AddOrderModal;
