import React, { useContext, useState } from "react";
import TopButton from "./TopButton";
import ChangeOrderModal from "./ModalComponent/ChangeOrderModal/ChangeOrderModal";
import BreakDownModalToplama from "./ModalComponent/BreakDownModalToplama";
import Ariza from "../assets/image/ariza.png";
import ExitIcon from "../assets/image/exit-icon.png";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { apiUrl } from "../config";
import UserContext from "../Context/UserContext";

export default function TopBar({ setStart, start, modifiedData, faultTitle,socket }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenBreakDownModal, setisOpenBreakDownModal] = useState(false);
  const [reasonData, setReasonData] = useState([])
  const { user } = useContext(UserContext)

  const buttonData = [
    {
      title: (faultTitle === "Failure" && "Arıza Bitir") || (faultTitle === "Start" && "Arıza Başlat"),
      icon: Ariza
    }, { title: "Çıkış", icon: ExitIcon },
  ];
  const { instance } = useMsal();
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;
  const handleLogOut = () => {
    instance.logoutRedirect();
    localStorage.clear();
  };

  const faultButton =  () => {
    axios.get(`${apiUrl}/getFailureReasons/${window.location.pathname === "/asma-spv" || window.location.pathname === "/asma" ? "Asma" : "Toplama"}`, {
      auth: {
        username: username,
        password: password
      }
    })
    .then((response) => {
      setReasonData(response.data);
    })
    .catch((error) => console.log(error));

    if (faultTitle === "Start") {
      return setisOpenBreakDownModal(true)
    } else {
      axios.post(`${apiUrl}/addFailure`,
        ({
          user: user.accountDetail.name,
          reason_id: 1,
        })
      )
    }
  };

  return (
    <>
      <ChangeOrderModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <BreakDownModalToplama
        isOpenBreakDownModal={isOpenBreakDownModal}
        setisOpenBreakDownModal={setisOpenBreakDownModal}
        modifiedData={modifiedData}
        reasonData={reasonData}
        socket={socket}
      />
      <div className="flex flex-row items-center justify-center">
        {buttonData &&
          buttonData.map((item, index) => (
            <React.Fragment key={index}>
              <TopButton
                title={item.title}
                icon={item.icon}
                onClick={(() => {
                  switch (index) {
                    case 0:
                      return faultButton;
                      // return null;

                    case 1:
                      return handleLogOut;
                  }
                })()}
              />
            </React.Fragment>
          ))}
      </div>
    </>
  );
}
