import { Button, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { apiUrl } from '../../config'
import SingularParameterModal from '../ModalComponent/ConstraintModal/SingularParameterModal'

const SingularParameters = () => {

    const [allData, setAllData] = useState([])
    const [modifiedData, setModifiedData] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState([])
    const [refreshPage, setRefreshPage] = useState(false)
    const [rule, setRule] = useState("")
    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_SECRET_KEY;

    const getData = () => {
        axios.get(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/getSingularParameter`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then(res => setAllData(res.data))
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getData()
    }, [refreshPage])

    const handleClick = (record) => {
        setSelectedRow(record)
        setModalOpen(true)
        setRule("changeOrder")
    }
    useEffect(() => {
        const dataList = allData?.map((item, index) => ({
            key: index + 1,
            hds_capacity: item?.hds_capacity,
            hds_efficiency: item?.hds_efficiency,
            hds_low_limit: item?.hds_low_limit,

        }));

        setModifiedData([...dataList]);
    }, [allData]);

    const columns = [
        {
            title: "Parametre",
            dataIndex: "parameter",
            align: "center",
            width: 120,
        },
        {
            title: "Değeri",
            dataIndex: "value",
            width: 90,
            align: "center",
        },

    ];
    const parameterLabels = {
        'hds_capacity': 'HDS Kapasitesi',
        "hds_efficiency":"HDS Verimlilik",
        'hds_low_limit': 'HDS Alt Limit',
        'hds_high_limit': 'HDS Üst Limit',
        'yer1_inventory_capacity': 'Yer 1 Stok Alanı Kapasitesi',
        'yer2_inventory_capacity': 'Yer 2 Stok Alanı Kapasitesi',
        'num_frozen_period': 'Sabit Tutulan Sipariş Sayısı',
        'min_lot_size': 'Minimum Sipariş Adedi',
        'par_asma_toplama': 'Asma Toplama Arası Uzaklık',
        'parColorChangePenalty':"Renk Değişim Katsayısı",
        'parMaterialGroupingPenalty':"Renk Gruplama Katsayısı",
        'parEndStockPenalty': 'Bitiş Stok Cezası',
        'parSafetyUsagePenalty': 'Güvenlik Stoğu Kullanım Cezası',
        'parLowStockPenalty': 'HDS Düşük Stok Cezası',
        'parGovdeLowStockPen': 'Gövde Stok Cezası',
        'parGovdeOnLowStockPen': 'Gövde Ön Stok Cezası',
        'parChosenStopCoef': 'Duruş Seçilen Periyod Cezası',
        
    
    };
    
    const prepareTableData = (rawData, isLeftTable) => {
        if (!Array.isArray(rawData)) {
            console.error('Data is not an array');
            return [];
        }
    
        return rawData.map((item, index) => {
            const rows = [];
            Object.keys(item).forEach(key => {
                if (
                    (isLeftTable && (
                        key === 'hds_capacity' ||
                        key === 'hds_efficiency' ||
                        key === 'hds_low_limit' ||
                        key === 'hds_high_limit' ||
                        key === 'yer1_inventory_capacity' ||
                        key === 'yer2_inventory_capacity' ||
                        key === 'num_frozen_period' ||
                        key === 'min_lot_size' ||
                        key === 'par_asma_toplama'
                    )) ||
                    (!isLeftTable && (
                        key === 'parColorChangePenalty' ||
                        key === 'parMaterialGroupingPenalty' ||
                        key === 'parEndStockPenalty' ||
                        key === 'parSafetyUsagePenalty' ||
                        key === 'parLowStockPenalty' ||
                        key === 'parGovdeLowStockPen' ||
                        key === 'parGovdeOnLowStockPen' ||
                        key === 'parChosenStopCoef'
                    ))
                ) {
                    rows.push({
                        key: `${index + 1}-${key}`,
                        parameter: parameterLabels[key] || key, 
                        value: item[key],
                    });
                }
            });
            return rows;
        }).flat();
    };
    
    

    useEffect(() => {
        const dataList = allData?.map((item, index) => ({
            key: index + 1,
            ...item,
        }));
        const leftTableData = prepareTableData(dataList, true);
        const rightTableData = prepareTableData(dataList, false);
        setModifiedData([leftTableData, rightTableData]);
    }, [allData]);



    return (
        <div style={{ maxHeight: '82vh', overflowY: 'auto' }}>
            <SingularParameterModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                modifiedData={modifiedData}
                selectedRow={selectedRow}
                rule={rule}
                setRefreshPage={setRefreshPage}
                refreshPage={refreshPage}
                allData={allData}
            />
            <div className="flex p-4 flex-col h-full gap-2 ">
                <div className='flex w-max bg-smartopt rounded-lg'>
                    <Button className='text-white font-semibold w-44' onClick={() => {
                        setModalOpen(true)
                        setRule("newOrder")
                    }}>
                        Değiştir
                    </Button>
                </div>
                <div className='flex flex-row gap-3'>
                    <div className="tableAsma flex h-full overflow-auto w-full">
                        <Table
                            bordered
                            sticky
                            dataSource={modifiedData[0]} // Left table data
                            columns={columns}
                            scroll={{ x: 700 }}
                            pagination={{ pageSize: 10 }}
                        />
                    </div>
                    <div className="tableAsma flex h-full overflow-auto w-full">
                        <Table
                            bordered
                            sticky
                            dataSource={modifiedData[1]} // Right table data
                            columns={columns}
                            scroll={{ x: 700 }}
                            pagination={{ pageSize: 10 }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingularParameters