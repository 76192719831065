import React from "react";

export default function TopButton({ title, icon, onClick }) {
  return (
    <div
      className=" bg-mainYellow w-[150px] h-[150px] pb-[5px] flex flex-col items-center rounded-md cursor-pointer text-[24px] shadow-lg justify-between py-4 font-bold m-[10px] hover:bg-secondYellow hover:shadow-2xl hover:scale-105 "
      onClick={onClick}
    >
      <img
        src={icon}
        style={{ width: "80px", marginTop: "0", height: "80px" }}
      />
      <div>{title}</div>
    </div>
  );
}
