import { useEffect, useState } from "react";
import { Table, DatePicker } from "antd";
import axios from "axios";
import { apiUrl } from "../config";

export default function FailureHistoryTable() {
    const [modifiedData, setModifiedData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [archiveDate, setArchiveDate] = useState("")

    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_SECRET_KEY;


    const getData = () => {
        setLoading(true)
        axios.get(`${apiUrl}/getFailureControlHistory`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then((response) => setAllData(response.data))
            .catch((error) => console.log(error))
        setLoading(false)
    }

    const getDataArchive = () => {
        setLoading(true)
        axios.get(`${apiUrl}/getFailureControlHistoryAtDate/${(archiveDate)}`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then((response) => setAllData(response.data))
            .catch((error) => console.log(error))
        setLoading(false)
    }

    useEffect(() => {
        setAllData([])
        if (archiveDate) getDataArchive()
        else getData();
    }, [archiveDate]);

    useEffect(() => {
        const dataList = allData?.map((item, index) => ({
            key: index + 1,
            failure_duration: item?.failure_duration,
            failure_ended: item?.failure_ended?.slice(0, 10) + " ---> " + item?.failure_ended?.slice(11, 16),
            failure_started: item?.failure_started?.slice(0, 10) + " ---> " + item?.failure_started?.slice(11, 16),
            is_failure_resolved: item?.is_failure_resolved === true ? "Çözüldü" : "Çözülmedi",
            failure_reason:item?.failure_reason?.failure_reason,
            created_by:item?.created_by
        }));

        setModifiedData([...dataList]);
    }, [allData]);
    const handleTableChange = (pagination, filters, sorter, currentTable) => {
    };

    //* table column başlıkları
    const columns = [
        {
            title: "Arıza Süresi (DK)",
            dataIndex: "failure_duration",
            key: "failure_duration",
            align: "center",
            width: 130,

        },
        {
            title: "Arıza Başlangıç Tarihi",
            dataIndex: "failure_started",
            key: "failure_started",
            align: "center",
            width: 130,
        },
        {
            title: "Arıza Bitiş Tarihi",
            dataIndex: "failure_ended",
            key: "failure_ended",
            align: "center",
            width: 200,
        },
        {
            title: "Arıza Durumu",
            dataIndex: "is_failure_resolved",
            key: "is_failure_resolved",
            align: "center",
            width: 200,
        },
        {
            title: "Arıza Sebebi",
            dataIndex: "failure_reason",
            key: "failure_reason",
            align: "center",
            width: 200,
        },
        {
            title: "Kullanıcı",
            dataIndex: "created_by",
            key: "created_by",
            align: "center",
            width: 200,
        },
        
        // {
        //     title: "Değişiklik Bilgisi",
        //     dataIndex: "change_info",
        //     key: "change_info",
        //     align: "center",
        //     width: 200,
        //     render: (record, row) => {
        //         return <div className="flex flex-col">
        //             <div>
        //                 {row?.change_info.split("Period:")[0]}
        //             </div>
        //             <div>
        //                 Period: {row?.change_info.split("Period:")[1]}
        //             </div>
        //         </div>
        //     }
        // },
    ];
    const onChange = (date, dateString) => {
        setArchiveDate(dateString);
    };
    return (
        <div className="flex flex-col gap-2 overflow-auto">
            <div className="text-center p-2 shadow-md bg-labelColor font-bold rounded-md justify-between flrx flex-col " >
                <h2 className="text-lg" >Arıza Geçmişi Tablosu</h2>
                <div><DatePicker onChange={onChange} placeholder="Tarih seçiniz" /></div>

                <div className="tableAsma flex h-full overflow-auto w-full">

                    <Table
                        // className="table-main1"
                        bordered
                        sticky
                        loading={loading}
                        dataSource={modifiedData}
                        rowClassName={(record, index) => {
                            return record.is_started_asma === true ||
                                record.is_completed_asma === true
                                ? "table-first-item table-height"
                                : index % 2 === 0
                                    ? "table-row-light table-height"
                                    : "table-row-dark table-height";
                        }}

                        onChange={handleTableChange}
                        columns={columns}
                        pagination={{ pageSize: 50 }}
                        scrollToFirstRowOnChange={true}
                        scroll={{x:1000}}


                    />
                </div>
            </div>
        </div>
    );
}
