import React, { useState, useEffect, useRef } from "react";
import { Modal, DatePicker, Form } from "antd";
import ModalHeaderLayout from "../../../layouts/ModalHeaderLayout";
import axios from "axios";
import { apiUrl } from "../../../config";
import moment from 'moment';

const ServiceRequestsModal = ({
  modalOpen,
  setModalOpen,
  selectedRow,
  rule,
  setRefreshPage,
  refreshPage,
  material,
  setRule
}) => {
  const [formData, setFormData] = useState({
    demand: "",
    body_material: "",
    front_body_material: "",
    date: null
  });
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;
  const [form] = Form.useForm(); 

  useEffect(() => {
    if (selectedRow && rule === "changeOrder") {
      // form.setFieldsValue({ 
      //   date: selectedRow?.date,
      // });
      setFormData({
        demand: selectedRow.demand,
        body_material: selectedRow.body_materialid,
        front_body_material: selectedRow.front_body_materialid,
        date: moment(selectedRow.date)._d
      });
    } else {
      form.setFieldsValue({ date: null });
      setFormData({
        demand: "",
        body_material: "",
        front_body_material: "",
        date: null
      });
    }
  }, [selectedRow, modalOpen,rule]);

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "body_material") {
      setFormData({
        ...formData,
        [name]: value,
        front_body_material: ""
      });
    } else if (name === "front_body_material") {
      setFormData({
        ...formData,
        [name]: value,
        body_material: ""
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestMethod = rule === "changeOrder" ? axios.put : axios.post;
    let requestData;
    if (rule === "changeOrder") {
      requestData = { ...formData, id: selectedRow.id };
    }
    if (rule === "newOrder") {
      requestData = { ...formData };
    }
    requestMethod(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/addServiceDemand`,
      requestData, {
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`
      }
    }).then(res => {
      if (res.status === 200) {
        setFormData({
          demand: "",
          body_material: "",
          front_body_material: "",
          date: null
        });
        resetFields()
        setModalOpen(false);
        setRefreshPage(!refreshPage);
      }
    })
      .catch(err => console.log(err));
  };

  const disabledDate = (current) => {
    // Disable past dates
    return current && current < moment().startOf('day');
  };

  const disabledTime = () => ({
    disabledHours: () => moment().toDate() >= moment(formData.date).toDate() ? getDisabledHours() : [],
  });

  const getDisabledHours = () => {
    var hours = [];
    for (let i = 0; i <= (moment().hour()) + 2; i++) {
      hours.push(i);
    }
    return hours;
  };
  console.log(formData)
  const resetFields = () => {
    form.resetFields(["date"]); // Formdaki date alanını sıfırla
  };
  return (
    <Modal
      title={<ModalHeaderLayout title={rule === "changeOrder" ? "Talep Değişikliği" : "Talep Ekle"} />}
      open={modalOpen}
      footer={null}
      onCancel={() => {
        setRule("")
        setModalOpen(false);
        resetFields(); // Formdaki date alanını sıfırla
      }}
      centered
      className="min-w-max mb-40"
    >
      <Form form={form} initialValues={{ date:moment() }}>
        <div className='bg-white pl-10 pr-10 pb-10 rounded-2xl shadow-2xl'>
          <div className='grid laptop:grid-cols-2 desktop:grid-cols-3 largeDesktop:grid-cols-4 gap-7'>
            <div className="flex flex-col">
              <label htmlFor="Talep">Talep </label>
              <input
                onChange={(e) => handleChange(e)}
                onWheel={(e) => e.target.blur()} type="number" min="0"
                name="demand"
                value={formData.demand}
                className="bg-gray-200 rounded h-8 block w-full mt-1"
              />
            </div>
            <div className="flex flex-col mt-1">
              <label htmlFor="Saat">Tarih/Saat </label>
              <Form.Item name="date">
                <DatePicker
                  showTime={{ format: 'HH:00:00', defaultValue: moment('00:00:00', 'HH:mm') }}
                  className="w-full"
                  disabledDate={disabledDate}
                  disabledTime={disabledTime}
                  inputReadOnly={true}
                  allowClear
                  onSelect={(e) => setFormData({ ...formData, date: e.$d })}
                  
                />
              </Form.Item>
            </div>
            <div className="flex flex-col">
              <label htmlFor="body_material">Gövde Seç :</label>
              <select
                onChange={(e) => handleChange(e)}
                name="body_material"
                value={formData.body_material}
                className="bg-gray-200 rounded h-8 block w-full mt-1"
              >
                <option value="">Seçiniz</option>
                {
                  material && material.govdeMaterial.map((item, index) => {
                    return <option key={index} value={item.id}>{item?.tanim}</option>
                  })
                }
              </select>
            </div>
            <div className="flex flex-col">
              <label htmlFor="front_body_material">Gövde Ön Seç :</label>
              <select
                onChange={(e) => handleChange(e)}
                name="front_body_material"
                value={formData.front_body_material}
                className="bg-gray-200 rounded h-8 block w-full mt-1"
              >
                <option value="">Seçiniz</option>
                {
                  material && material.govdeOnMaterial.map((item, index) => {
                    return <option key={index} value={item.id}>{item?.tanim}</option>
                  })
                }
              </select>
            </div>
          </div>

          <div>
            <button type="submit" className="bg-smartopt hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10 w-full" onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </Form>

    </Modal>
  );
};

export default ServiceRequestsModal;
