import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../Components/Navbar";
import { useLocation } from "react-router-dom";

const HomeLayout = ({ children }) => {
  let location = useLocation();

  const [usePadding, setUsePadding] = useState(false);

  useEffect(() => {
    const noPaddingPages = ["/alarm", "/alarm2", "/dashboard", "/dashboard2", "/newDashboard", "/help-center","/constraints","/beta-screen"];

    if (noPaddingPages.includes(location.pathname)) setUsePadding(false);
    else setUsePadding(true);
  }, [location.pathname]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <NavBar />
      <div className={`flex h-full w-full ${usePadding ? "px-5 pb-20" : ""}`}>
        {children}
      </div>
    </div>
  );
};

export default HomeLayout;
