import React, { useContext, useEffect, useState } from "react";
import TopButton from "./TopButton";
import { useMsal } from "@azure/msal-react";

import ChangeOrderModal from "./ModalComponent/ChangeOrderModal/ChangeOrderModal";
import BreakDownModal from "./ModalComponent/BreakDownModal";
import Ariza from "../assets/image/ariza.png";
import Baslat from "../assets/image/baslat.png";
import ExitIcon from "../assets/image/exit-icon.png";
import Pause from "../assets/image/pause.png";
import StopOrderModal from "./ModalComponent/StopOrderModal";
import Cancel from "../assets/image/cancel.png";
import { useAuth0 } from '@auth0/auth0-react';
import UserContext from "../Context/UserContext";
import axios from "axios";
import { apiUrl } from "../config";

export default function TopBar({
  setRefresh,
  socket,
  faultTitle,
  modifiedData,
  cardData,
  alarm
}) {
  const [modalOpenStopOrder, setModalOpenStopOrder] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenBreakDownModal, setisOpenBreakDownModal] = useState(false);
  const { instance } = useMsal();
  const { user } = useContext(UserContext)
  const [reasonData, setReasonData] = useState([])
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;

  const buttonData = [
    // { title: startTitle, icon: startTitle === "Çalışıyor" ? Pause : Baslat },
    { title: "Bitir", icon: Cancel },
    {
      title: (faultTitle === "Failure" && "Arıza Bitir") || (faultTitle === "Start" && "Arıza Başlat"),
      icon: Ariza
    },
    { title: "Çıkış", icon: ExitIcon },
  ];

  // const startButton = () => {
  //   if (startTitle === "Çalışıyor") {
  //     if (socket) {
  //       socket.send(
  //         JSON.stringify({
  //           event_type: "StopEvent",
  //         })
  //       );
  //     }
  //   } else {
  //     socket.send(
  //       JSON.stringify({
  //         event_type: "StartEvent",
  //       })
  //     );
  //   }
  // };
  const handleLogOut = () => {
    instance.logoutRedirect();
    localStorage.clear();
  };

  const faultButton = () => {

    axios.get(`${apiUrl}/getFailureReasons/${window.location.pathname === "/asma-spv" || window.location.pathname === "/asma" ? "Asma" : "Toplama"}`, {
      auth: {
        username: username,
        password: password
      }
    })
    .then((response) => {
      setReasonData(response.data);
    })
    .catch((error) => console.log(error));


    if (faultTitle === "Start") {
      return setisOpenBreakDownModal(true)
    } else {
      axios.post(`${apiUrl}/addFailure`,
        ({
          user: user.accountDetail.name,
          reason_id: 1,
        })
      )
    }

    
  };

  const handleYesButton = () => {
    if (socket) {
      socket.send(
        JSON.stringify({
          event_type: "CompleteEvent",
          user: user.accountDetail.name,

        })
      );
    }

    setModalOpenStopOrder(false);
    setRefresh((p) => !p);
    window.location.reload();
  };

  return (
    <>
      <ChangeOrderModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        socket={socket}
      />
      <BreakDownModal
        isOpenBreakDownModal={isOpenBreakDownModal}
        setisOpenBreakDownModal={setisOpenBreakDownModal}
        modifiedData={modifiedData}
        socket={socket}
        cardData={cardData}
        reasonData={reasonData}
      />
      <StopOrderModal
        modalOpenStopOrder={modalOpenStopOrder}
        setModalOpenStopOrder={setModalOpenStopOrder}
        modifiedData={modifiedData}
        socket={socket}
        cardData={cardData}
        setRefresh={setRefresh}
      />
      <div className="flex flex-row items-center justify-center">
        {buttonData &&
          buttonData.map((item, index) => (
            <React.Fragment key={index}>
              <TopButton
                title={item.title}
                icon={item.icon}
                onClick={(() => {
                  // eslint-disable-next-line default-case
                  switch (index) {
                    // case 0:
                    //   return startButton;
                    case 0:
                      return handleYesButton;
                    // return null
                    case 1:
                      return faultButton;
                    // return null

                    case 2:
                      return handleLogOut;
                    // return () => logout({ logoutParams: { returnTo: 'https://www.bsh.paintshop.smartopt.com.tr/' } })

                    default:
                      return null;
                  }
                })()}
              />
            </React.Fragment>
          ))}
      </div>
    </>
  );
}
