import { useEffect, useState } from "react";
import { Table } from "antd";
import GovdeOnStokModal from "./ModalComponent/GovdeOnStokModal";
import TableFooter from "./TableFooter";
import { apiUrl } from "../config";
import axios from "axios";

export default function SAPGovdeOnStok() {
  const [modifiedData, setModifiedData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;

  const getData = () => {
    setLoading(true);
    axios
      .get(`${apiUrl}/getMatInitial`, {
        auth: {
          username: username,
          password: password,
        },
      })
      .then((response) => {
        setAllData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const dataList = allData?.map((item, index) => ({
      key: index + 1,
      malzeme_kodu: item?.malzeme_kodu,
      unrestr: item?.unrestr,
      real_stock: item?.real_stock,
    }));

    setModifiedData([...dataList]);
  }, [allData]);

  const handleTableChange = (pagination, filters, sorter, currentTable) => {
    // Handle table change logic here
  };

  const columns = [
    {
      title: "Malzeme Kodu",
      dataIndex: "malzeme_kodu",
      key: "malzeme_kodu",
      align: "center",
      width: 80,
    },
    {
      title: "Stok",
      dataIndex: "real_stock",
      key: "real_stock",
      align: "center",
      width: 100,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <h2 className="text-center p-2 shadow-md bg-labelColor font-bold rounded-md">
        Gövde Ön Başlangıç Stok
      </h2>
      <Table
        bordered
        sticky
        dataSource={modifiedData}
        rowClassName={(record, index) => {
          return index % 2 === 0
            ? "table-row-light table-height"
            : "table-row-dark table-height";
        }}
        onChange={handleTableChange}
        columns={columns}
        pagination={{ pageSize: 6 }}
        footer={() => {
          const batchDate = allData?.[0]?.order_batch?.batch_date;

         
          const formattedDate =
            batchDate && batchDate.length >= 16
              ? batchDate.slice(0, 10) + " " + batchDate.slice(11, 16)
              : ""; 

          return <TableFooter dateTime={formattedDate} />;
        }}
        loading={loading}
      />

    </div>
  );
}
