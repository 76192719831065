import { useEffect, useState } from "react";
import { Table } from "antd";
import TableFooter from "./TableFooter";
import { apiUrl } from "../config";
import axios from "axios";

export default function GovdeStok() {
  const [modifiedData, setModifiedData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;

  const getData = () => {
    setLoading(true)
    axios.get(`${apiUrl}/getLatestGovdeStokSeviyeleri`, {
      auth: {
        username: username,
        password: password
      }
    })
      .then((response) => setAllData(response.data))
      .catch((error) => console.log(error))
    setLoading(false)
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const dataList = allData?.map((item, index) => ({
      key: index + 1,
      material: item?.material,
      description: item?.material_description?.split('-')[1],
      unrestr: item?.hds_stok,
    }));

    setModifiedData([...dataList]);
  }, [allData]);

  const handleTableChange = (pagination, filters, sorter, currentTable) => {
    // console.log('Various parameters', pagination, filters, sorter);
    // setFilteredInfo(filters);
    // setSortedInfo(sorter);
    // setCurrentTable(currentTable?.currentDataSource)
  };

  useEffect(() => {
    allData?.length === 0 || allData?.length === undefined
      ? setLoading(true)
      : setLoading(false);
  }, [allData]);

  const columns = [
    {
      title: "Malzeme Kodu",
      dataIndex: "material",
      key: "material",
      align: "center",
      width: 80,
    },

    {
      title: "Tanım",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: 100,
    },
    {
      title: "Mevcut Stok",
      dataIndex: "unrestr",
      key: "unrestr",
      align: "center",
      width: 80,
    },
  ];

  return (
    <div className="flex flex-col gap-2">


      <h2 className="text-center p-2 shadow-md bg-labelColor font-bold rounded-md">
        SAP Gövde Stok
      </h2>

      <Table
        bordered
        sticky
        dataSource={modifiedData}
        // loading={loading}
        rowClassName={(record, index) => {
          //* biten column'un renginin yeşil olmasını istemiyorsak, || record.is_completed === true kodunu sil
          // console.log("record", record);
          return index % 2 === 0
            ? "table-row-light table-height"
            : "table-row-dark table-height";
        }}
        onChange={handleTableChange}
        columns={columns}
        // scroll={{ x: 2000 }}
        pagination={{ pageSize: 100 }}
        footer={() => (
          <TableFooter
            dateTime={
              allData
                ?.map((item) => item?.created_date)[0]
                ?.slice(0, 10) +
              " " +
              allData?.map((item) => item?.created_date)[0]?.slice(11, 16)
            }
          />
        )}
        loading={loading}
      />
    </div>
  );
}
