import { useContext, useEffect, useState } from "react";
import UserContext from "../Context/UserContext";
import TableContext from "../Context/TablePosition";
import { useLocation } from "react-router-dom";

const Dashboard2 = () => {
  const { user } = useContext(UserContext)
  const [isFullScreen, setIsFullScreen] = useState(false);

  const smartoptUser = ["sap5ib@bosch.com"]

  const { setCurrentUrl } = useContext(TableContext)
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("urlpaintshop", location.pathname)
    setCurrentUrl(localStorage.getItem("urlpaintshop"));
  }, [location.pathname, setCurrentUrl])
  
  useEffect(() => {
    // Tam ekran modunu kontrol et ve kapat
    if (document.fullscreenElement) {
      setIsFullScreen(true);
      document.exitFullscreen();
    }

    // Belirtilen saatlerde sayfayı yenile
    const refreshPageAtScheduledTimes = () => {
      const now = new Date();
      const scheduledRefreshTimes = [
        { hour: 0, minute: 35 },
        { hour: 1, minute: 35 },
        { hour: 2, minute: 35 },
        { hour: 3, minute: 35 },
        { hour: 4, minute: 35 },
        { hour: 5, minute: 35 },
        { hour: 6, minute: 35 },
        { hour: 7, minute: 35 },
        { hour: 8, minute: 35 },
        { hour: 9, minute: 35 },
        { hour: 10, minute: 35 },
        { hour: 11, minute: 35 },
        { hour: 12, minute: 35 },
        { hour: 13, minute: 35 },
        { hour: 14, minute: 35 },
        { hour: 15, minute: 35 },
        { hour: 16, minute: 35 },
        { hour: 17, minute: 35 },
        { hour: 18, minute: 35 },
        { hour: 19, minute: 35 },
        { hour: 20, minute: 35 },
        { hour: 21, minute: 35 },
        { hour: 22, minute: 35 },
        { hour: 23, minute: 35 }

      ];

      scheduledRefreshTimes.forEach(time => {
        const refreshDate = new Date();
        refreshDate.setHours(time.hour, time.minute, 0, 0);

        if (refreshDate <= now) {
          refreshDate.setDate(refreshDate.getDate() + 1);
        }

        const timeToRefresh = refreshDate - now;
        setTimeout(() => {
          document.location.reload();
        }, timeToRefresh);
      });
    };
    refreshPageAtScheduledTimes();

    return () => {
      clearTimeout(refreshPageAtScheduledTimes);
    };
  }, []);


  useEffect(() => {
    // Tam ekran modunu aç
    if (isFullScreen) {
      document.documentElement.requestFullscreen();
    }
  }, [isFullScreen]);


  return (
    <div className="flex w-full h-full flex-col">
      {
        smartoptUser.includes(user.accountDetail.username) ?
          <iframe
            title="BSH_boyahane"
            width="100%"
            height="100%"
            src="https://app.powerbi.com/reportEmbed?reportId=8b2d53f0-0125-4ff6-bfdf-1917aac872cd&autoAuth=true&ctid=c279b48b-bd28-48aa-80af-6398c99f8b56"
            frameborder="0"
            allowFullScreen="true"
          >

          </iframe>
          :
          <iframe
            title="BSH_boyahane"
            width="100%"
            height="100%"
            src="https://app.powerbi.com/reportEmbed?reportId=fa0db0e8-e0b6-42f1-9a92-e857113b5986&appId=7c5475cd-96f5-47a6-a680-1fba83986d88&autoAuth=true&ctid=0ae51e19-07c8-4e4b-bb6d-648ee58410f4"
            frameborder="0"
            allowFullScreen="true"
          >

          </iframe>
      }

    </div>
  );
};

export default Dashboard2;
