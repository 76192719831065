import { Button, Input, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { apiUrl } from '../../config'


const ColorMatrix = () => {

    const [allData, setAllData] = useState([])
    const [modifiedData, setModifiedData] = useState([])
    const [editingKey, setEditingKey] = useState('')
    const [refreshPage, setRefreshPage] = useState(false)
    const [formData, setFormData] = useState({
        id: "",
        par_color_change:""
    });

    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_SECRET_KEY;

    const getData = () => {
        axios.get(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/getColorMatrix/all`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then(res => setAllData(res.data))
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getData()
    }, [refreshPage])

    useEffect(() => {
        const dataList = allData?.map((item, index) => ({
            key: index + 1,
            from_desc: item?.from_color.description,
            to_desc: item?.to_color.description,
            body_material: item?.body_material?.tanim,
            par_color_change: item?.par_color_change,
            id: item?.id
        }));

        setModifiedData([...dataList]);
    }, [allData]);

    const handleEdit = (record) => {
        setEditingKey(record.key);
    };

    const handleSave = () => {
        axios.put(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/updateColorMatrix`, formData, {
            auth: {
                username: username,
                password: password
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setFormData({
                        id: "",
                        par_color_change:""
                    })
                    setEditingKey('')
                    setRefreshPage(true)
                }
            })
            .catch(err => console.log(err))
    };

    const handleCancel = () => {
        setEditingKey('');
    };

    const isEditing = (record) => record.key === editingKey;

    const columns = [
        {
            title: "Önceki Renk",
            dataIndex: "from_desc",
            key: "from_desc",
            align: "center",
            width: 120,
        },
        {
            title: "Sonraki Renk",
            dataIndex: "to_desc",
            key: "to_desc",
            align: "center",
            width: 120,
        },
        {
            title: "Renk Değişim Süresi (Dakika)",
            dataIndex: "par_color_change",
            key: "par_color_change",
            align: "center",
            width: 120,
            render: (text, record) => {
                const editable = isEditing(record);

                return editable ? (
                    <Input value={text} type='number' name='par_color_change' onChange={(e) => handleInputChange(e.target.value, record)} />
                ) : (
                    text
                );
            },
        },
        {
            title: "İşlem",
            dataIndex: "islem",
            key: "islem",
            align: "center",
            width: 150,
            render: (text, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <div className='flex flex-row gap-2 justify-center items-center'>
                        <Button onClick={handleSave} type="primary">Kaydet</Button>
                        <Button onClick={handleCancel} type='default'>İptal Et</Button>
                    </div>
                ) : (
                    <Button onClick={() => handleEdit(record)} style={{ fontFamily: "sans-serif" }} className='text-white bg-smartopt font-semibold'>
                        Düzenle
                    </Button>
                );
            },
        },
    ];

    const handleInputChange = (value, record) => {
        const newData = [...modifiedData];
        const index = newData.findIndex((item) => record.key === item.key);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, par_color_change: value });
            setModifiedData(newData);
            setFormData({
                ...formData,
                par_color_change: value,
                id: record.id,
            });
        }
    };

    return (
        <div style={{ maxHeight: '82vh', overflowY: 'auto' }}>
            <div className="flex p-4 flex-col h-full gap-2 ">
                <div className="tableAsma flex h-full overflow-auto w-full">
                    <Table
                        bordered
                        sticky
                        dataSource={modifiedData}
                        columns={columns}
                        scroll={{ x: 700 }}
                        pagination={{ pageSize: 6 }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ColorMatrix;
