import { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { ReloadOutlined } from '@ant-design/icons';

import GovdeOnStokModal from "../Components/ModalComponent/GovdeOnStokModal";
import TableFooter from "./TableFooter";
import { baseUrl } from "../config";
export default function GovdeOnEmniyet() {
  const [modifiedData, setModifiedData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isModalOpenStok, setIsModalOpenStok] = useState(false);
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    const handleOnline = () => {
      console.log("Yeniden Bağlanıyor...");
      const ws = new WebSocket(`${baseUrl}/ws/frontbodysafetystock-socket-server/`);
      setSocket(ws);
    };

    const handleOffline = () => {
      console.log("Çevrimdışı - WebSocket bağlantısı kapatıldı.");
      if (socket) {
        socket.close();
      }
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [socket]);

  useEffect(() => {
    const ws = new WebSocket(
      `${baseUrl}/ws/frontbodysafetystock-socket-server/`
    );
    setSocket(ws);

    return () => {
      console.log("close home socket");
      ws.close();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.onopen = function (event) {
        console.log("Socket opened");
      };
    }
  }, []);

  useEffect(() => {
    let timer;

    if (socket) {
      socket.onmessage = function (e) {
        let newData = JSON.parse(e.data);

        // console.log("Asma", newData);
        setAllData(newData?.data);
      };
    }
    timer = setInterval(() => {
      if (socket.readyState === WebSocket.OPEN) {
        console.log("send HealthCheck");
        socket.send(
          JSON.stringify({
            event_type: "HealthCheck",
          })
        );
      } else if (socket.readyState === WebSocket.CLOSED) {
        console.log("Socket is closed. Reopening...");
        // Yeniden bağlantı kurma işlemini başlat
        const newSocket = new WebSocket(`${baseUrl}/ws/frontbodysafetystock-socket-server/`);
        setSocket(newSocket);
      }
    }, 8000);
    return () => clearInterval(timer);


  }, [refresh, socket]);

  useEffect(() => {
    const dataList = allData?.map((item, index) => ({
      key: index + 1,
      material: item?.fields?.material,
      readable_description: item?.fields?.readable_description,
      created_at: item?.fields?.created_at,
      unrestr: item?.fields?.unrestr,
      pk: item.pk,
    }));

    setModifiedData([...dataList]);
  }, [allData]);
  // console.log("modified data", modifiedData);
  const handleTableChange = (pagination, filters, sorter, currentTable) => {
    // console.log('Various parameters', pagination, filters, sorter);
    // setFilteredInfo(filters);
    // setSortedInfo(sorter);
    // setCurrentTable(currentTable?.currentDataSource)
  };

  useEffect(() => {
    allData?.length === 0 || allData?.length === undefined
      ? setLoading(true)
      : setLoading(false);
  }, [allData]);

  const columns = [
    {
      title: "Malzeme Kodu",
      dataIndex: "material",
      key: "material",
      align: "center",
      width: 80,
    },

    {
      title: "Tanım",
      dataIndex: "readable_description",
      key: "readable_description",
      align: "center",
      width: 100,
    },
    {
      title: "Mevcut Stok",
      dataIndex: "unrestr",
      key: "unrestr",
      align: "center",
      width: 80,
      render: (record, row) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: "40px",
            }}
          >
            <div style={{ flex: 1, textAlign: "center" }}>
              {row.unrestr}
            </div>
            <Button
              onClick={(e) => {
                setSelectedRow(row);
                setIsModalOpenStok(true);
              }}
              style={{ fontFamily: "sans-serif" }}
            >
              <ReloadOutlined />
            </Button>
          </div>

        );
      },
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <GovdeOnStokModal
        isModalOpenStok={isModalOpenStok}
        setIsModalOpenStok={setIsModalOpenStok}
        socket={socket}
        selectedRow={selectedRow}
      />
      <h2 className="text-center p-2 shadow-md bg-labelColor font-bold rounded-md">
        Gövde Ön Emniyet
      </h2>

      <Table
        bordered
        sticky
        dataSource={modifiedData}
        rowClassName={(record, index) => {
          return index % 2 === 0
            ? "table-row-light table-height"
            : "table-row-dark table-height";
        }}
        onChange={handleTableChange}
        columns={columns}
        pagination={{ pageSize: 6 }}
        footer={() => <TableFooter showData />}
        loading={loading}
      />
    </div>
  );
}
