import Modal from "antd/es/modal/Modal";
import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import ModalHeaderLayout from "../../layouts/ModalHeaderLayout";
import { Button } from "antd";
import UserContext from "../../Context/UserContext";
import axios from 'axios'
import { apiUrl } from '../../config';

export default function BreakDownModalToplama({
  isOpenBreakDownModal,
  setisOpenBreakDownModal,
  reasonData,
  socket,
}) {

  const { user } = useContext(UserContext)
  const [reasonValue, setReasonValue] = useState({
    reason: ""
  })

  const handleChange = (e) => {
    setReasonValue(e.target.value)
  }

  const handlePost = (e) => {
    e.preventDefault();
    axios.post(`${apiUrl}/addFailure`,
      ({
        user: user.accountDetail.name,
        reason_id: reasonValue,
      })
    )
    // if (socket) {
    //   socket.send(
    //     JSON.stringify({
    //       event_type: "FailureEvent",
    //       user: user.accountDetail.name,
    //       reason_id: reasonValue
    //     })
    //   );
    // }
    setReasonValue({reason:""})
    setisOpenBreakDownModal(false)

  };
  
  return (
    <Modal
      title=<ModalHeaderLayout title="Arıza Seç" />
      open={isOpenBreakDownModal}
      footer={null}
      onOk={() => setisOpenBreakDownModal(false)}
      onCancel={() => {
        setReasonValue({
          reason: ""
        })
        setisOpenBreakDownModal(false)
      }}
      className="min-w-max"
    >
      <div className="flex flex-col w-full p-5 ">
        <select name="reason" value={reasonValue.reason} onChange={(e) => handleChange(e)} className="border flex h-12 text-xl">
          <option value="">Seçiniz</option>
          {
            reasonData && reasonData?.map((item, index) => {
              return <option key={index} value={item?.id}>{item?.failure_reason}</option>
            })
          }

        </select>
      </div>
      <div className="flex p-5 justify-end ">
        <Button onClick={handlePost} className="bg-smartopt text-white h-10 w-28 text-lg">Gönder</Button>
      </div>

    </Modal>
  );
}
