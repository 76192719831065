import React, { useContext, useEffect } from "react";
import { Modal } from "antd";
import { useState } from "react";
import ModalHeaderLayout from "../../../layouts/ModalHeaderLayout";
import UserContext from "../../../Context/UserContext";
import axios from "axios";
import { apiUrl } from "../../../config";

const ColorModal = ({
  modalOpen,
  setModalOpen,
  selectedRow,
  allData,
  rule,
  setRefreshPage,
  refreshPage
}) => {
  const [formData, setFormData] = useState({
    description: "",
    front_body_balanced: "",
  });
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;
  useEffect(() => {
    if (selectedRow && rule === "changeOrder") {
      setFormData({
        description: selectedRow.description,
        front_body_balanced: selectedRow.front_body_balanced ,

      });
    } else {
      setFormData({
        description: "",
        front_body_balanced: "",

      });
    }
  }, [selectedRow, modalOpen]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const requestMethod = rule === "changeOrder" ? axios.put : axios.post;
    let requestData;
    if (rule === "changeOrder") {
      requestData = { ...formData, id: selectedRow.id };
    }
    if (rule === "newOrder") {
      requestData = { ...formData };
    }
    requestMethod(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/addColor`,
      requestData, {
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`
      }
    })
      .then(res => {
        if (res.status === 200) {
          setFormData({
            description: selectedRow.description ,
            front_body_balanced: selectedRow.front_body_balanced ,
          })
          setModalOpen(false)
          setRefreshPage(!refreshPage)
        }
      })
      .catch(err => console.log(err))
  }
  return (
    <Modal
      title=<ModalHeaderLayout title={rule === "changeOrder" ? "Renk Değişikliği" : "Renk Ekle"} />
      open={modalOpen}
      footer={null}
      onOk={() => setModalOpen(false)}
      onCancel={() => {
        setModalOpen(false);
      }}
      centered
      className="min-w-max mb-40"
    >
      <form className='bg-white pl-10 pr-10 pb-10 rounded-2xl shadow-2xl' onSubmit={handleSubmit}>
        <div className='grid laptop:grid-cols-2 desktop:grid-cols-3 largeDesktop:grid-cols-4 gap-7'>

          <div>
            <label htmlFor="description">Tanım</label>
            <input onChange={(e) => handleChange(e)} type="text" name="description" value={formData.description} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="front_body_balanced">Gövde Ön Dengeleme :</label>
            <select onChange={(e) => handleChange(e)} name="front_body_balanced" value={formData.front_body_balanced} className="bg-gray-200 rounded h-8 block w-full mt-1">
              <option value="">Seçiniz</option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
        </div>
        <div>
          <button type="submit" className="bg-smartopt hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10 w-full">Submit</button>
        </div>
      </form>
    </Modal>
  );
};

export default ColorModal;
