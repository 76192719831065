import React, { useContext, useEffect } from "react";
import { Modal } from "antd";
import { useState } from "react";
import ModalHeaderLayout from "../../../layouts/ModalHeaderLayout";
import axios from "axios";
import { apiUrl } from "../../../config";

const BodyTypeModalFront = ({
  modalOpen,
  setModalOpen,
  selectedRow,
  allData,
  rule,
  setRefreshPage,
  refreshPage
}) => {
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;

  const [formData1, setFormData1] = useState({
    description: "",
  });

  useEffect(() => {
   
    if (selectedRow && rule === "changeOrder") {
      setFormData1({
        description: selectedRow.description ,
      });
    } else {
      
      setFormData1({
        description: "",
      });
    }
  }, [selectedRow, modalOpen]);

  const handleChange1 = (e) => {
    setFormData1({ ...formData1, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestMethod = rule === "changeOrder" ? axios.put : axios.post;
    let requestData;
  
    if (rule === "changeOrder") {
      requestData = { ...formData1, id: selectedRow.id };
    }
   
    if (rule === "newOrder") {
      requestData = formData1;
    }

    requestMethod(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/addFrontBodyMaterialType`,
      requestData, {
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`
      }
    }).then(res => {
      if (res.status === 200) {
        setFormData1({
          description: "",
        });
        setModalOpen(false)
        setRefreshPage(!refreshPage)
      }
    })
      .catch(err => console.log(err))
  }
  return (
    <Modal
      title=<ModalHeaderLayout title={rule === "changeOrder" ? "Gövde Tipi Değişikliği" : "Gövde Tipi Ekle"} />
      open={modalOpen}
      footer={null}
      onOk={() => setModalOpen(false)}
      onCancel={() => {
        setModalOpen(false);
      }}
      centered
      className=" mb-40"
    >
      <form className='bg-white p-3 rounded-2xl shadow-2xl' onSubmit={handleSubmit}>
       
        <div className='grid '>
          <div>
            <label htmlFor="description">Gövde Ön Tipi</label>
            <input onChange={(e) => handleChange1(e)} type="text" name="description" value={formData1.description} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>

        </div>

        <div>
          <button type="submit" className="bg-smartopt hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10 w-full">Submit</button>

        </div>


      </form>
    </Modal>
  );
};

export default BodyTypeModalFront;
