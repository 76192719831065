export const navItems = [
  { title: "Asma", link: "/asma" },
  { title: "Asma SPV", link: "/asma-spv" },
  { title: "Toplama", link: "/toplama" },
  { title: "Stok Seviyeleri", link: "/stok-seviyeleri" },
  {
    title: "Dashboardlar",
    menuItems: [
      { title: "Dashboard", link: "/dashboard" },
      { title: "Dashboard2", link: "/dashboard2" },
    ],
  },
  {
    title: "Alarmlar",
    menuItems: [
      { title: "Alarm", link: "/alarm" },
      { title: "Alarm2", link: "/alarm2" },
    ],
  },
  {
    title: "Üretim",
    menuItems: [
      { title: "Üretim", link: "/uretim" },
      { title: "Rework/Hurda", link: "/rework-hurda" },
    ],
  },
  { title: "Durum Bilgisi", link: "/status-info" },

  { title: "Vardiya Planı", link: "/vardiya-planı" },
  {
    title: "Geçmiş",
    menuItems: [
      { title: "Kontrol Geçmişi", link: "/kontrol-gecmisi" },
      { title: "Arıza Geçmişi", link: "/ariza-gecmisi" },
      { title: "PLC verileri", link: "/plc" },
    ],
  },
  // { title: "Canlı Kısıt", link: "/constraints" },
  { title: "Beta", link: "/beta-screen" },
  { title: "Yardım", link: "/help-center" },
  
];
