import { Button, Table } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { apiUrl } from '../../config'
import ColorModal from '../ModalComponent/ConstraintModal/ColorModal'
import ServiceRequestsModal from '../ModalComponent/ConstraintModal/ServiceRequestsModal'

const ServiceRequests = () => {

    const [allData, setAllData] = useState([])
    const [modifiedData, setModifiedData] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState([])
    const [refreshPage, setRefreshPage] = useState(false)
    const [rule, setRule] = useState("")

    const [material, setMaterial] = useState({
        govdeMaterial: [],
        govdeOnMaterial: []
    })
    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_SECRET_KEY;

    const getData = () => {
        axios.get(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/getServiceDemand/all`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then(res => setAllData(res.data))
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getData()
    }, [refreshPage])

    const handleClick = (row) => {
        setSelectedRow(row)
        setModalOpen(true)
        setRule("changeOrder")
        axios.get(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/getBodyMaterial/service`, {
            auth: {
                username: username,
                password: password
            }
        })
        .then(res => {
            setMaterial(prevMaterial => ({ ...prevMaterial, govdeMaterial: res.data }));
            return axios.get(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/getFrontBodyMaterial/service`, {
                auth: {
                    username: username,
                    password: password
                }
            });
        })
        .then(res => {
            setMaterial(prevMaterial => ({ ...prevMaterial, govdeOnMaterial: res.data }));
        })
        .catch(err => console.log(err));
    }
    useEffect(() => {
        const dataList = allData?.map((item, index) => ({
            key: index + 1,
            date: item?.date,
            demand: item?.demand,
            body_materialid: item?.body_material?.id,
            body_material: item?.body_material?.tanim,

            front_body_materialid: item?.front_body_material?.id,
            front_body_material: item?.front_body_material?.tanim,

            id: item?.id
        }));

        setModifiedData([...dataList]);
    }, [allData]);

    const columns = [
        {
            title: "Talep",
            dataIndex: "demand",
            key: "demand",
            align: "center",
            width: 120,
        },
        {
            title: "Tarih/Saat",
            dataIndex: "date",
            key: "date",
            width: 90,
            align: "center",
            render: (record, row) => {
                return (
                    <div>
                        {row.date.slice(0,10) + " " + row.date.slice(11,14) + "00"}
                    </div>
                );
            },
        },
        {
            title: "Gövde",
            dataIndex: "body_material",
            key: "body_material",
            align: "center",
            width: 120,
        },
        {
            title: "Gövde Ön",
            dataIndex: "front_body_material",
            key: "front_body_material",
            align: "center",
            width: 120,
        },
        {
            title: "İşlem",
            dataIndex: "islem",
            key: "islem",
            align: "center",
            width: 150,
            render: (record, row) => {
                return (
                    <div>
                        <Button
                            onClick={() => handleClick(row)}
                            style={{ fontFamily: "sans-serif" }}
                            className='text-white bg-smartopt font-semibold'
                        >
                            Değiştir
                        </Button>
                    </div>
                );
            },
        },



    ];


    return (
        <div style={{ maxHeight: '82vh', overflowY: 'auto' }}>
            <ServiceRequestsModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                modifiedData={modifiedData}
                selectedRow={selectedRow}
                rule={rule}
                setRule ={setRule}
                setRefreshPage={setRefreshPage}
                refreshPage={refreshPage}
                material={material}
            />
            <div className="flex p-4 flex-col h-full gap-2 ">
                <div className='flex w-max bg-smartopt rounded-lg'>
                    <Button className='text-white font-semibold' onClick={() => {
                        setModalOpen(true)
                        setRule("newOrder")
                        axios.get(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/getBodyMaterial/service`, {
                            auth: {
                                username: username,
                                password: password
                            }
                        })
                            .then(res => {
                                setMaterial(prevMaterial => ({ ...prevMaterial, govdeMaterial: res.data }));
                                return axios.get(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/getFrontBodyMaterial/service`, {
                                    auth: {
                                        username: username,
                                        password: password
                                    }
                                });
                            })
                            .then(res => {
                                setMaterial(prevMaterial => ({ ...prevMaterial, govdeOnMaterial: res.data }));
                            })
                            .catch(err => console.log(err));
                    }}>
                        Talep Ekle
                    </Button>
                </div>
                <div className="tableAsma flex h-full overflow-auto w-full">
                    <Table
                        bordered
                        sticky
                        dataSource={modifiedData}
                        // loading={loading}
                        // rowClassName={(record, index) => {
                        //     //* biten column'un renginin yeşil olmasını istemiyorsak, || record.is_completed === true kodunu sil
                        //     // console.log("record", record);
                        //     return record.is_started_asma === true ||
                        //         record.is_completed_asma === true
                        //         ? "table-first-item table-height"
                        //         : index % 2 === 0
                        //             ? "table-row-light table-height"
                        //             : "table-row-dark table-height";
                        // }}
                        // onChange={handleTableChange}
                        columns={columns}
                        scroll={{ x: 700 }}
                        pagination={{ pageSize: 6 }}
                    // scrollToFirstRowOnChange={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default ServiceRequests