import React, { useContext, useEffect } from "react";
import { Modal } from "antd";
import { useState } from "react";
import ModalHeaderLayout from "../../../layouts/ModalHeaderLayout";
import axios from "axios";
import { apiUrl } from "../../../config";
import UserContext from "../../../Context/UserContext";

const MalzemeKoduModal = ({
  modalOpen,
  setModalOpen,
  selectedRow,
  rule,
  setRefreshPage,
  refreshPage,
  getColor,
  bodyMaterialType
}) => {
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;

  const [formData, setFormData] = useState({
    malzeme_kodu: "",
    tanim: "",
    min_inventory_periodically: "",
    put_inventory_before_demand: "",
    production_rate_according_demand: "",
    par_obj_func_stop_exceeding_demand: "",
    critical: "",
    end_min_inventory: null,
    end_max_inventory: null,
    hour_delayed: "",
    produce_close_demand: "",
    body_color: "",
    body_material_type: "",
    is_service: "",
  });


  useEffect(() => {
    if (modalOpen) {
      if (selectedRow && rule === "changeOrder") {
        setFormData({
          malzeme_kodu: selectedRow.malzeme_kodu || "",
          tanim: selectedRow.tanim || "",
          min_inventory_periodically: selectedRow.min_inventory_periodically || "",
          put_inventory_before_demand: selectedRow.put_inventory_before_demand == 0 ? 0 : (selectedRow.put_inventory_before_demand || ""),
          production_rate_according_demand: selectedRow.production_rate_according_demand || "",
          par_obj_func_stop_exceeding_demand: selectedRow.par_obj_func_stop_exceeding_demand || "",
          critical: String(selectedRow.critical) || "",
          end_min_inventory: selectedRow.end_min_inventory || null,
          end_max_inventory: selectedRow.end_max_inventory || null,
          hour_delayed: selectedRow.hour_delayed || "",
          produce_close_demand: String(selectedRow.produce_close_demand) || "",
          body_color: selectedRow.body_colorid || "",
          body_material_type: selectedRow.body_material_typeid || "",
          is_service: String(selectedRow.is_service) || "",
        });
      } else if (rule === "newOrder") {
        setFormData({
          malzeme_kodu: "",
          tanim: "",
          min_inventory_periodically: "",
          put_inventory_before_demand: "",
          production_rate_according_demand: "",
          par_obj_func_stop_exceeding_demand: "",
          critical: "",
          end_min_inventory: null,
          end_max_inventory: null,
          hour_delayed: "",
          produce_close_demand: "",
          body_color: "",
          body_material_type: "",
          is_service: "",
        });
      }
    }
  }, [selectedRow, modalOpen, rule]);
  const { user } = useContext(UserContext)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const requestMethod = rule === "changeOrder" ? axios.put : axios.post;
    console.log(rule)
    let requestData;
    if (rule === "changeOrder") {
      requestData = { ...formData, id: selectedRow.id };
    }
    if (rule === "newOrder") {
      requestData = formData;
    }

    requestMethod(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/addBodyMaterial`,
      requestData, {
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`
      },
      params:{
        username : user?.accountDetail?.name
      }
    })
      .then(res => {
        if (res.status === 200) {
          setFormData({
            malzeme_kodu: selectedRow.malzeme_kodu || "",
            tanim: selectedRow.tanim || "",
            min_inventory_periodically: selectedRow.min_inventory_periodically || "",
            put_inventory_before_demand: selectedRow?.put_inventory_before_demand || "55",
            production_rate_according_demand: selectedRow.production_rate_according_demand || "",
            par_obj_func_stop_exceeding_demand: selectedRow.par_obj_func_stop_exceeding_demand || "",
            critical: selectedRow.critical || "",
            end_min_inventory: selectedRow.end_min_inventory || null,
            end_max_inventory: selectedRow.end_max_inventory || null,
            hour_delayed: selectedRow.hour_delayed || "",
            produce_close_demand: selectedRow.produce_close_demand || "",
            body_color: selectedRow.body_colorid,
            body_material_type: selectedRow.body_material_typeid,
            is_service: selectedRow.is_service || "",
          });
          setModalOpen(false)
          setRefreshPage(!refreshPage);
        }
      })
      .catch(err => console.log(err));
  }
  
  return (
    <Modal
      title=<ModalHeaderLayout title={rule === "changeOrder" ? "Malzeme Kodu Değişikliği" : "Malzeme Kodu Ekle"} />
      open={modalOpen}
      footer={null}
      onOk={() => setModalOpen(false)}
      onCancel={() => {
        setModalOpen(false);
      }}
      centered
      className="min-w-max mb-40"
    >
      <form className='bg-white pl-10 pr-10 pb-10 rounded-2xl shadow-2xl' onSubmit={handleSubmit}>

        <div className='grid laptop:grid-cols-2 desktop:grid-cols-3 largeDesktop:grid-cols-4 gap-7'>
          <div>
            <label htmlFor="malzeme_kodu">Malzeme Kodu</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="malzeme_kodu" value={formData.malzeme_kodu} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="tanim ">Tanım</label>
            <input onChange={(e) => handleChange(e)} type="text" name="tanim" value={formData.tanim} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          {
            formData.critical === "false" ?
          <div>
            <label htmlFor="min_inventory_periodically">Sürekli Minimum Stok:</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="min_inventory_periodically" value={formData.min_inventory_periodically} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          : null
          }
          <div>
            <label htmlFor="fname1">Talepten Önce Stoğa Girme Saati:</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()}  type="number" min="0" name="put_inventory_before_demand" value={formData.put_inventory_before_demand} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="fname1">Talebe göre Üretim Oranı:</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" step="any" min="0" name="production_rate_according_demand" value={formData.production_rate_according_demand} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="critical">Ürün Kritik mi?</label>
            <select onChange={(e) => handleChange(e)} name="critical" value={formData.critical} className="bg-gray-200 rounded h-8 block w-full mt-1">
              <option value="">Seçiniz</option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          {
            formData.critical === "true" ?
              <>
                <div>
                  <label htmlFor="fname1">Bitiş Minimum Stoğu :</label>
                  <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="end_min_inventory" value={formData.end_min_inventory} className="bg-gray-200 rounded h-8 block w-full mt-1" />
                </div>
                <div>
                  <label htmlFor="fname1">Bitiş Maksimum Stoğu :</label>
                  <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="end_max_inventory" value={formData.end_max_inventory} className="bg-gray-200 rounded h-8 block w-full mt-1" />
                </div>
              </>
              : null
          }

          <div>
            <label htmlFor="fname1">Ürün Kaç Saat Ertelensin? :</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="hour_delayed" value={formData.hour_delayed} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="produce_close_demand">Talebe Yakın Üretilsin mi?</label>
            <select onChange={(e) => handleChange(e)} name="produce_close_demand" value={formData.produce_close_demand} className="bg-gray-200 rounded h-8 block w-full mt-1">
              <option value="">Seçiniz</option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div>
            <label htmlFor="is_service">Servis Ürünü mü?</label>
            <select onChange={(e) => handleChange(e)} name="is_service" value={formData.is_service} className="bg-gray-200 rounded h-8 block w-full mt-1">
              <option value="">Seçiniz</option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>

          <div>
            <label htmlFor="body_color">Gövde Rengi :</label>
            <select onChange={(e) => handleChange(e)} name="body_color" value={formData.body_color} className="bg-gray-200 rounded h-8 block w-full mt-1">
              <option value="">Seçiniz</option>
              {
                getColor && getColor.map(item => {
                  return <option value={item.id}>{item.description}</option>
                })
              }
            </select>
          </div>

          <div>
            <label htmlFor="body_material_type">Gövde Tipi :</label>
            <select onChange={(e) => handleChange(e)} name="body_material_type" value={formData.body_material_type} className="bg-gray-200 rounded h-8 block w-full mt-1">
              <option value="">Seçiniz</option>
              {
                bodyMaterialType && bodyMaterialType.map(item => {
                  return <option value={item.id}>{item.description}</option>
                })
              }
            </select>
          </div>
          <div>
            <label htmlFor="par_obj_func_stop_exceeding_demand">Talebi Aşmama Amaç Fonksiyonu Katsayı Parametresi :</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="par_obj_func_stop_exceeding_demand" value={formData.par_obj_func_stop_exceeding_demand} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
        </div>
        <div>
          <button type="submit" className="bg-smartopt hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10 w-full">Submit</button>
        </div>

      </form>
    </Modal>
  );
};

export default MalzemeKoduModal;
