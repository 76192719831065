import React, { useContext, useEffect, useState } from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import "./alert2.css";
import SmartoptLogo1 from "../../assets/image/smartoptLogo1.png";
import { baseUrl } from "../../config";
import TableContext from "../../Context/TablePosition";
import { NavLink, useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from "antd";

const Alert2 = () => {
  const [socket, setSocket] = useState(null);
  const [colorChangeTime, setColorChangeTime] = useState(null);
  const [plannedStopTime, setPlannedStopTime] = useState("");
  const [distanceColorChangeTime, setDistanceColorChangeTime] = useState(null);
  const [distancePlannedStopTime, setDistancePlannedStopTime] = useState(null);
  const [onTickRework, setOnTickRework] = useState({});
  const [onTickColor, setOnTickColor] = useState({});
  // const [isInFailure, setIsInFailure] = useState(false)
  const [second, setSecond] = useState(59);
  const [allData, setAllData] = useState("")
  const [allData1, setAllData1] = useState({})
  const [currentData, setCurrentData] = useState("")
  const [currentColor, setCurrentColor] = useState("")
  const { setCurrentUrl } = useContext(TableContext)
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("urlpaintshop", location.pathname)
    setCurrentUrl(localStorage.getItem("urlpaintshop"));
  }, [location.pathname, setCurrentUrl])

  useEffect(() => {
    const ws = new WebSocket(
      `${baseUrl}/ws/dashboard-socket-server/`
    );
    setSocket(ws);

    return () => {
      console.log("Alert2 socket closed");

      ws.close();
    };
  }, []);


  useEffect(() => {
    const handleOnline = () => {
      console.log("Yeniden Bağlanıyor...");
      const ws = new WebSocket(`${baseUrl}/ws/dashboard-socket-server/`);
      setSocket(ws);
    };

    const handleOffline = () => {
      console.log("Çevrimdışı - WebSocket bağlantısı kapatıldı.");
      if (socket) {
        socket.close();
      }

    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);

    };
  }, [socket]);

  useEffect(() => {
    let timer;

    if (socket) {
      socket.onopen = function (event) {
        console.log("Alarm2 Socket opened");
      };
      socket.onmessage = (e) => {
        let newData = JSON.parse(e.data);
        console.log(newData)

        if (newData.type === "Color_Change_Time") {
          setAllData1(newData?.next_color)
          setCurrentColor(newData?.current_color)

        }
        if (newData.type === "Current_Situation") {
          setAllData(newData?.reason)
          setCurrentData(newData?.data)

        }


        if (newData.type === "Color_Change_Time") {
          // setPlannedStopTime(newData.data)
          setColorChangeTime(newData.data);
        }
        if (newData.type === "Rework_Time") {
          setPlannedStopTime(newData.data);
        }

      };

      timer = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) {
          console.log("send HealthCheck");
          socket.send(
            JSON.stringify({
              event_type: "HealthCheck",
            })
          );
        } else if (socket.readyState === WebSocket.CLOSED) {
          console.log("Socket is closed. Reopening...");
          const newSocket = new WebSocket(`${baseUrl}/ws/dashboard-socket-server/`);
          setSocket(newSocket);
        }
      }, 8000);
      return () => clearInterval(timer);
    }
  }, [socket]);

  useEffect(() => {
    if (colorChangeTime) {
      const currentDate = new Date();

      const [hours, minutes, seconds] = colorChangeTime.split(":");

      currentDate.setHours(hours);
      currentDate.setMinutes(minutes);
      currentDate.setSeconds(seconds);
      if (new Date().getTime() > currentDate.getTime()) {
        currentDate.setDate(currentDate.getDate() + 1);
      }
      setDistanceColorChangeTime(currentDate);
    }
    if (plannedStopTime) {
      // Şu anki tarih bilgisini al
      const currentDate = new Date();

      // "timeString"i saat, dakika ve saniye olarak ayır
      const [hours, minutes, seconds] = plannedStopTime.split(":");

      // "currentDate"i "timeString" saatine ayarla
      currentDate.setHours(hours);
      currentDate.setMinutes(minutes - 30);
      currentDate.setSeconds(seconds);
      if (new Date().getTime() > currentDate.getTime()) {
        currentDate.setDate(currentDate.getDate() + 1);
      }
      setDistancePlannedStopTime(currentDate);
    }
  }, [
    colorChangeTime,
    plannedStopTime,
    onTickColor.seconds,
    onTickRework.seconds,
  ]);

  useEffect(() => {
    let time;

    time = setInterval(() => {
      setSecond(second - 1);
    }, 1000);
    if (second === -1) {
      setSecond(59);
    }
    return () => clearInterval(time);
  }, [second]);

  return (
    <div className="flex h-fullflex-row w-full">
      <Button className="absolute mt-4 ml-4 p-2 bg-white flex justify-center items-center gap-1 text-lg font-semibold border-4 h-12">
        <NavLink to="/asma" className="flex items-center gap-2">
          <ArrowLeftOutlined />
          Ana Sayfa
        </NavLink>
      </Button>

      <div className="absolute right-0 top-0 ">
        <img className="w-[200px] mt-2 mr-4" src={SmartoptLogo1} alt="Smartopt Logo" />
      </div>
      <div
        className="flex h-full w-full flex-col pt-20"
        style={
          onTickColor.days === 0 &&
            onTickColor.hours <= 1 &&
            onTickColor.hours >= 0 &&
            onTickColor.minutes < 7 &&
            onTickColor.minutes >= 0 &&
            onTickColor.seconds > 0
            ? second % 2 === 0
              ? { backgroundColor: "rgba(255,104,64)" }
              : null
            : null
        }
      >
        <p className="text-[50px] largeDesktop:text-[70px]  font-extrabold text-white p-8 rounded-lg shadow-xl  m-auto  bg-gradient-to-r largeDesktop:w-[600px] w-[450px] from-mainGray  via-secondGray  to-mainGray  text-center">
          Renk Değişimi
        </p>
        <div className="flex justify-center items-center w-full h-full">
          {distanceColorChangeTime && (
            <div className="flex flex-col gap-10 justify-center items-center">
              <FlipClockCountdown
                to={distanceColorChangeTime}
                renderMap={[false, true, true, true]}
                className="flip-clock"
                labels={["GÜN", "SAAT", "DAKİKA", "SANİYE"]}
                labelStyle={{ fontWeight: "bolder" }}
                onTick={(e) => setOnTickColor(e.timeDelta)}
              />

              <p className="font-bold text-4xl">Şimdiki Renk : {currentColor}</p>
              <p className="font-bold text-4xl">Sonraki Renk : {allData1}</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex h-full bg-black w-5">|</div>
      <div
        className="flex h-full w-full flex-col pt-20"
        style={
          currentData === "Üretim" ? { backgroundColor: "green" } :
            currentData === "Duruş" ? { backgroundColor: "yellow" } :
              second % 2 === 0 ? { backgroundColor: "rgba(255,104,64)" } :
                { backgroundColor: "white" }
        }
      >
        <p className="text-[50px] largeDesktop:text-[70px] font-extrabold text-white p-8 rounded-lg shadow-xl m-auto bg-gradient-to-r  largeDesktop:w-[600px] w-[450px] from-mainGray  via-secondGray  to-mainGray  text-center">
          Sistem Durumu
        </p>
        {
          currentData === "Arıza" ?
            <div style={second % 2 === 0 ? { color: "white" } : { color: "black" }} className="justify-center flex flex-col items-center w-full h-full text-[70px] font-extrabold mb-28 ">
              Arıza Var
              <p className="text-5xl ">Arıza Sebebi:  {allData ? allData : ""}</p>

            </div>
            :
            <div className="justify-center flex items-center w-full h-full text-[70px] font-extrabold mb-28 text-white flex-col">
              {allData}
            </div>
        }


      </div>
    </div>
  );
};

export default Alert2;
