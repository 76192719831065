import React, { useContext, useEffect, useState } from "react";
import Voice from "../../assets/voice/alarm.mp3";
import "./Alert.css";
import SmartoptLogo1 from "../../assets/image/smartoptLogo1.png";
import { baseUrl } from "../../config";
import TableContext from "../../Context/TablePosition";
import { useLocation } from "react-router-dom";

const Alert = () => {
  const [second, setSecond] = useState(59);
  const [alarm, setAlarm] = useState(false);
  const [nextProduction, setNextProduction] = useState({
    orderNo: "",
    siparis: "",
    target: null,
  });
  const [socket, setSocket] = useState(null);
  const { setCurrentUrl } = useContext(TableContext)
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("urlpaintshop", location.pathname)
    setCurrentUrl(localStorage.getItem("urlpaintshop"));
  }, [location.pathname, setCurrentUrl])


  useEffect(() => {
    const handleOnline = () => {
      console.log("Yeniden Bağlanıyor...");
      const ws = new WebSocket(`${baseUrl}/ws/hang-socket-server/`);
      setSocket(ws);
    };

    const handleOffline = () => {
      console.log("Çevrimdışı - WebSocket bağlantısı kapatıldı.");
      if (socket) {
        socket.close();
      }

    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);

    };
  }, [socket]);

  useEffect(() => {
    const ws = new WebSocket(
      `${baseUrl}/ws/hang-socket-server/`
    );
    setSocket(ws);

    return () => {
      ws.close();
    };
  }, []);


  useEffect(() => {
    let time;

    time = setInterval(() => {
      setSecond(second - 1);
    }, 1000);

    if (second === -1) {
      setSecond(59);
    }

    return () => clearInterval(time);
  }, [second]);

  useEffect(() => {
    let timer;

    if (socket) {
      socket.onmessage = function (e) {
        let data = JSON.parse(e.data);
        setAlarm(data?.type?.info_type || data?.info_type || data?.type);
        setNextProduction({
          orderNo: data?.type?.next_order_nr,
          siparis: data?.type?.next_siparis,
          target: data?.type?.next_target,
        });
      };
    }
    timer = setInterval(() => {
      if (socket.readyState === WebSocket.OPEN) {
        console.log("send HealthCheck");
        socket.send(
          JSON.stringify({
            event_type: "HealthCheck",
          })
        );
      } else if (socket.readyState === WebSocket.CLOSED) {
        console.log("Socket is closed. Reopening...");
        const newSocket = new WebSocket(`${baseUrl}/ws/hang-socket-server/`);
        setSocket(newSocket);
      }
    }, 8000);
    return () => clearInterval(timer);
  }, [alarm, socket]);

  return (
    <div
      className="flex w-full h-full justify-center items-center"
      style={
        alarm === "Alarm"
          ? second % 2 === 0
            ? { backgroundColor: "rgba(255,104,64)" }
            : { backgroundColor: "#fff" }
          : null
      }
    >
      {/* <div className="absolute right-0 top-0 ">
        <img className="w-[250px] mt-2 mr-4" src={SmartoptLogo1} alt="Smartopt Logo" />
      </div> */}
      <div
        className="cardContainer"
        style={
          alarm === "Alarm"
            ? second % 2 === 0
              ? { backgroundColor: "#fff" }
              : { backgroundColor: "rgba(255,104,64)", color: "#fff" }
            : null
        }
      >
        {alarm === "Alarm" ? (
          <div className="flex w-full h-full flex-col">
            <audio src={Voice} autoPlay="Autoplay" loop />

            <div className="flex justify-center  text-[50px] font-extrabold underline">Sıradaki Sipariş</div>
            <div className="flex flex-col mt-12 ml-7">
              <div className="flex flex-row mb-16 h-10 largeDesktop:mb-28">
                <p className="w-[360px] font-extrabold text-4xl">Sipariş Numarası  </p>
                <p className="font-bold text-4xl w-3/4">: {nextProduction.orderNo}</p>
              </div>
              <div className="flex flex-row mb-16 h-10 largeDesktop:mb-28">
                <p className="w-[360px] font-extrabold text-4xl">Sipariş Adı</p>
                <p className="font-bold text-4xl w-3/4"> : {nextProduction.siparis}</p>
              </div>
              <div className="flex flex-row mb-16 h-10 largeDesktop:mb-28">
                <p className="w-[360px] font-extrabold text-4xl">Sipariş Miktarı</p>
                <p className="font-bold text-4xl w-3/4"> : {nextProduction.target}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-6xl font-extrabold">Mevcut Sipariş Devam Ediyor...</div>
        )}
      </div>
    </div>
  );
};

export default Alert;
