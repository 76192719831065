import React from "react";

const BoxShadow = ({ children, extraClass }) => {
  return (
    <div
      className={` ${extraClass} bg-mainYellow mt-[10px] h-[150px] rounded-md shadow-lg w-full p-1`}
    >
      {children}
    </div>
  );
};

export default BoxShadow;
