import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './i18next'
import { PublicClientApplication, EventType } from '@azure/msal-browser'

const pca = new PublicClientApplication({
  auth: {
    clientId: "ca4850fc-c52b-4758-9ecb-89936efe8fa1",
    authority: "https://login.microsoftonline.com/0ae51e19-07c8-4e4b-bb6d-648ee58410f4",
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (Level, message, containsPII) => {
        // console.log(message)
      },
      // logLevel:"Verbose"
      logLevel: "Info"

    }
  }
})

pca.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account)
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App
      msaLInstance={pca}
    />
  </BrowserRouter>
);

