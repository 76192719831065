import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TableContext from '../Context/TablePosition';
import { Tabs } from 'antd';
import MalzemeKodu from '../Components/Constraints/MalzemeKodu';
import Color from '../Components/Constraints/Color';
import BodyType from '../Components/Constraints/BodyType';
import MalzemeKoduFront from '../Components/Constraints/MalzemeKoduFront';
import BodyTypeFront from '../Components/Constraints/BodyTypeFront';
import ServiceRequests from '../Components/Constraints/ServiceRequests';
import ColorMatrix from '../Components/Constraints/ColorMatrix';
import SingularParameters from '../Components/Constraints/SingularParameters';
import BetaScreen from '../Components/BetaScreen';

const { TabPane } = Tabs;

const BetaScreenPage = () => {
    const { setCurrentUrl } = useContext(TableContext);
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("1"); 

    useEffect(() => {
        localStorage.setItem("urlpaintshop", location.pathname);
        setCurrentUrl(localStorage.getItem("urlpaintshop"));
    }, [location.pathname, setCurrentUrl]);

    const handleTabChange = (key) => {
        setActiveTab(key); 
    };

    return (
        <div className='w-full'>
            <Tabs
                className='w-full border '
                defaultActiveKey="1"
                activeKey={activeTab} // Aktif sekmenin durumunu izlemek için prop'u güncelledik.
                onChange={handleTabChange} // Tab değiştiğinde çalışacak fonksiyonu belirledik.
                tabBarStyle={{ background: 'rgba(0, 0, 0, 0.85)', accentColor: "yellow", fontFamily: "sans-serif" }}
            >
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Gövde Malzeme Kodu</span>} key="1">
                    <MalzemeKodu />
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Gövde Ön Malzeme Kodu</span>} key="2">
                    <MalzemeKoduFront />
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Gövde Tipi</span>} key="3">
                    <BodyType />
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Gövde Ön Tipi</span>} key="4">
                    <BodyTypeFront />
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Renk</span>} key="5">
                    <Color />
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Servis Talepleri</span>} key="6">
                    <ServiceRequests />
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Renk Matrisi</span>} key="7">
                    <ColorMatrix />
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Tekil Prametreler</span>} key="8">
                    <SingularParameters />
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Beta</span>} key="9">
                    <BetaScreen />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default BetaScreenPage;
