import React, { useContext, useEffect } from "react";
import ControlHistoryTable from "../Components/ControlHistoryTable";
import TableContext from "../Context/TablePosition";
import { useLocation } from "react-router-dom";
export default function ControlHistory() {
  const { setCurrentUrl } = useContext(TableContext)
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("urlpaintshop", location.pathname)
    setCurrentUrl(localStorage.getItem("urlpaintshop"));
  }, [location.pathname, setCurrentUrl])
  return (
    <div className="flex w-full flex-col h-full mt-4 ">
          <ControlHistoryTable />
    </div>
  );
}
