import React from "react";

export default function CardItem({ data, title }) {
  return (
    <div className="flex flex-row items-center gap-6">
      <span className="text-[17px] font-bold w-[170px] ">{title}</span>
      <span className="text-red-500 text-[18px] font-bold fon whitespace-nowrap">
        {data}
      </span>
    </div>
  );
}
