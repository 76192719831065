import React, { useContext } from "react";
import { Modal } from "antd";
import { useState } from "react";
import UserContext from "../../Context/UserContext";

const ChangeTargetModal = ({
  isModalOpenTarget,
  setIsModalOpenTarget,
  socket,
  selectedRow,
  allData
}) => {
  const [formData, setFormData] = useState({});
  const [isValid, setIsValid] = useState(true);
  const { user } = useContext(UserContext)

  const selectedItem = allData?.find(item => String(item?.fields?.order_nr) === String(selectedRow.orderNo));

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });


    setIsValid(e.target.value >= selectedItem.fields.actual_production_asma);

  };


  const handleSubmit = (e) => {
    // e.preventDefault();
    if (isValid) {
      socket.send(
        JSON.stringify({
          event_type: "ProductionChangeEvent",
          order_nr: selectedRow.orderNo,
          production: formData[`target${selectedRow.orderNo}`]
            ? formData[`target${selectedRow.orderNo}`]
            : selectedRow.target,
          user: user.accountDetail.name,

        })
      );
    }
  };

  return (
    <>
      <Modal
        centered
        open={isModalOpenTarget}
        footer={null}
        onOk={() => setIsModalOpenTarget(false)}
        onCancel={() => {
          setFormData({});
          setIsModalOpenTarget(false);
        }}
        className="min-w-max top-1/2 min-h-[300px] justify-between "
      >
        <form
          onSubmit={handleSubmit}
          className="flex !min-h-[300px]  flex-col justify-between"
        >
          <div className="flex text-5xl p-5 mt-10 flex-row w-full gap-2 items-center ">
            <div className="flex flex-row">
              <div className="w-56  flex justify-between mt-2">
                <label>Hedef</label>
                <span>:</span>
              </div>
              <input
                name={`target${selectedRow.orderNo}`}
                onWheel={(e) => e.target.blur()} 
                type="number"
                required
                min="1"
                onChange={handleChange}
                defaultValue={formData.target}
                placeholder="Hedef miktarını giriniz."
                className={`flex w-4/6 text-center text-5xl border-[2px] border-black rounded-md ${!isValid ? "border-red-500" : ""
                  }`} />
            </div>
            {!isValid && (
              <span className="text-red-500 ml-2 text-base">
                Hedef miktarı fiili miktardan küçük olamaz!
              </span>
            )}
          </div>

          <button
            className="h-[80px] bg-mainYellow w-full text-[40px] rounded-b-xl hover:bg-secondYellow"
            type="submit"
            disabled={!isValid}

          >
            Gönder
          </button>
        </form>
      </Modal>
    </>
  );
};

export default ChangeTargetModal;
