import { useEffect, useState } from "react";
import { Table } from "antd";
import axios from "axios";
import { apiUrl } from "../../config";

export default function Table2() {
  const [modifiedData, setModifiedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);

  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;

  const getData = () => {
    setLoading(true)
    axios.get(`${apiUrl}/getReworkJunk`, {
      auth: {
          username: username,
          password: password
      }
  })
      .then((response) => setAllData(response.data))
      .catch((error) => console.log(error))
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, []);

  console.log(allData)

  useEffect(() => {
    const dataList = allData?.map((item, index) => ({
      key: index + 1,
      orderType: item?.type,
      rework: item?.rework,
      junk: item?.junk,


    }));

    setModifiedData([...dataList]);
  }, [allData]);
  console.log("modified data", modifiedData);
  const handleTableChange = (pagination, filters, sorter, currentTable) => {
    // console.log('Various parameters', pagination, filters, sorter);
    // setFilteredInfo(filters);
    // setSortedInfo(sorter);
    // setCurrentTable(currentTable?.currentDataSource)
  };

  const columns = [

    {
      title: "Malzeme Tanımı",
      dataIndex: "orderType",
      key: "orderType",
      align: "center",
      width: 180,
    },

    {
      title: "Rework Miktarı",
      dataIndex: "rework",
      key: "rework",
      align: "center",
      width: 120,
    },
    {
      title: "Hurda Miktarı",
      dataIndex: "junk",
      key: "junk",
      align: "center",
      width: 120,
    },
  ];


  return (
    <div className="flex flex-col gap-2">
      <h2 className="text-center p-2 shadow-md bg-labelColor font-bold rounded-md" >Günlük Rework-Hurda</h2>

      <Table
        bordered
        sticky
        dataSource={modifiedData}
        loading={loading}
        rowClassName={(record, index) => {
          return record.is_started_asma === true ||
            record.is_completed_asma === true
            ? "table-first-item table-height"
            : index % 2 === 0
              ? "table-row-light table-height"
              : "table-row-dark table-height";
        }}
        onChange={handleTableChange}
        columns={columns}
        pagination={{ pageSize: 999999999 }}
      />
    </div>
  );
}
