import React from "react";
import { Modal } from "antd";
import { useState } from "react";

const GovdeOnStokModal = ({
  isModalOpenStok,
  setIsModalOpenStok,
  socket,
  selectedRow,
}) => {
  const [formData, setFormData] = useState({
    stok: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    socket.send(
      JSON.stringify({
        event_type: "UpdateData",
        id: selectedRow.pk,
        new_value: formData.stok,
      })
    );
  };

  return (
    <Modal
      open={isModalOpenStok}
      centered
      footer={null}
      onOk={() => setIsModalOpenStok(false)}
      onCancel={() => {
        setFormData({});
        setIsModalOpenStok(false);
      }}
      className="min-w-max top-1/2"
    >
      <form
        onSubmit={handleSubmit}
        className="flex !min-h-[300px] !min-w-[500px] flex-col justify-between"
      >
        <div className="flex flex-col text-5xl mt-10 gap-5 p-5 min-w-[800px]">
          <div className=" w-full items-center flex-row flex justify-between ">
            <label className="whitespace-nowrap">Mevcut Stok</label>
            <span>:</span>
            <input
              name="stok"
              onWheel={(e) => e.target.blur()} 
              type="number"
              required
              min="0"
              onChange={handleChange}
              placeholder="Mevcut Stok Giriniz."
              className="border-[2px] border-black text-[35px] p-8 h-[50px] flex "
            />
          </div>
        </div>
        <button
          className="h-[80px] bg-mainYellow w-full text-[40px] rounded-b-xl hover:bg-secondYellow"
          type="submit"
        >
          Gönder
        </button>
      </form>
    </Modal>
  );
};

export default GovdeOnStokModal;
