import React, { useContext, useEffect } from "react";
import GovdeOnEmniyet from "../Components/GovdeOnEmniyet";
import SAPGovdeOnStok from "../Components/SAPGovdeOnStok";
import GovdeStok from "../Components/GövdeStok";
import TableContext from "../Context/TablePosition";
import { useLocation } from "react-router-dom";
export default function GovdeOnPage() {
  const { setCurrentUrl } = useContext(TableContext)
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("urlpaintshop", location.pathname)
    setCurrentUrl(localStorage.getItem("urlpaintshop"));
  }, [location.pathname, setCurrentUrl])
  return (
    <div className="flex w-full flex-col h-full mt-4 ">
      <div className="flex flex-col desktop:flex-row gap-4 overflow-auto">
        <GovdeStok />
        <SAPGovdeOnStok />
        <GovdeOnEmniyet />
      </div>
    </div>
  );
}
