import React, { useContext, useEffect } from "react";
import TableContext from "../Context/TablePosition";
import { useLocation } from "react-router-dom";
import PlcTable from "../Components/PlcTable";


export default function Plc() {
  const { setCurrentUrl } = useContext(TableContext)
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("urlpaintshop", location.pathname)
    setCurrentUrl(localStorage.getItem("urlpaintshop"));
  }, [location.pathname, setCurrentUrl])

  return (
    <div className="flex w-full flex-col h-full mt-4 ">
      <PlcTable />
    </div>
  );
}
