import { useContext, useEffect, useState } from "react";
import { Table, Pagination, Button, Popover } from "antd";
import { Excel } from "antd-table-saveas-excel";
import TopBarToplama from "../Components/TopBarToplama";
import TabbarDataCardLayout from "../layouts/TabbarDataCardLayout";
import DataCard from "../Components/DataCard";
import { baseUrl } from "../config";
import TableContext from "../Context/TablePosition";
import { useLocation } from "react-router-dom";

export default function Toplama() {
  const [modifiedData, setModifiedData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [start, setStart] = useState(false);
  const [startTitle, setStartTitle] = useState("Start");
  const [completed, setCompleted] = useState(0);
  const [typeInfo, setTypeInfo] = useState({});
  const [faultTitle, setFaultTitle] = useState("");

  const [current, setCurrent] = useState(1);
  const [lastHanger, setLastHanger] = useState({});
  const [socket, setSocket] = useState(null);
  const { setCurrentUrl } = useContext(TableContext)
  const location = useLocation();
  const [wsStatus, setWsStatus] = useState(true)


  useEffect(() => {
    const handleOnline = () => {
      console.log("Yeniden Bağlanıyor...");
      const ws = new WebSocket(`${baseUrl}/ws/collect-socket-server/`);
      setSocket(ws);
      setWsStatus(true)

    };

    const handleOffline = () => {
      console.log("Çevrimdışı - WebSocket bağlantısı kapatıldı.");
      if (socket) {
        socket.close();
        setWsStatus(false)

      }
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [socket]);



  useEffect(() => {
    const ws = new WebSocket(
      `${baseUrl}/ws/collect-socket-server/`
    );
    setSocket(ws);

    return () => {
      console.log("close home socket");
      ws.close();
    };
  }, []);

  useEffect(() => {
    allData.length > 0 &&
      allData.find((item, index) => {
        if (item.fields.is_completed_toplama === true) {
          return setCompleted(index + 1);
        }
      });
  }, [allData]);

  useEffect(() => {
    if (socket) {
      socket.onopen = function (event) {
        console.log("Socket opened");
      };
    }
  }, []);

  useEffect(() => {
    let timer;
    let newData = {};
    if (socket) {
      socket.onmessage = function (e) {
        newData = JSON.parse(e.data);
        // console.log("Toplama", newData);
        setStartTitle(
          JSON.parse(e.data).type === "Started"
            ? "Başlat"
            : JSON.parse(e.data).type === "Updated"
              ? "Çalışıyor"
              : "Çalışıyor"
        );
        setAllData(
          newData?.data
        );
        setTypeInfo(newData)
        setLastHanger(newData.last_hanger)
        setFaultTitle(
          newData?.system_status
        );
      };


      if (start === true) {
        setStartTitle(allData.length > 0 ? "Çalışıyor" : "Başlat");
        socket.send(
          JSON.stringify({
            event_type: "StartEvent",
          })
        );
      }
      timer = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) {
          console.log("send HealthCheck");
          socket.send(
            JSON.stringify({
              event_type: "HealthCheck",
            })
          );
        } else if (socket.readyState === WebSocket.CLOSED) {
          console.log("Socket is closed. Reopening...");
          const newSocket = new WebSocket(`${baseUrl}/ws/collect-socket-server/`);
          setSocket(newSocket);
        }
      }, 8000);
      return () => clearInterval(timer);
    }
  }, [start, allData.length, socket]);


  useEffect(() => {
    const dataList = allData?.map((item, index) => ({
      key: index + 1,
      orderNo: Math.floor(item?.fields?.order_nr),
      spvNo: item?.fields?.sequence,
      date: item?.fields?.date,
      time: item?.fields?.period,
      description: item?.fields?.siparis,
      target: item?.fields?.uretim,
      fiiliMiktarGovde: item?.fields?.actual_production_toplama_govde,
      fiiliMiktarGovdeOn: item?.fields?.actual_production_toplama_govde_on,
      is_completed_toplama: item?.fields?.is_completed_toplama,
      is_started_toplama: item?.fields?.is_started_toplama,
      toplama_period: item?.fields?.toplama_period,

    }));

    setModifiedData([...dataList]);
  }, [allData]);

  useEffect(() => {
    let is_started_true_array = []
    const deneme = modifiedData.filter(item => item.is_started_toplama === true)
    deneme.map(item => is_started_true_array.push(item.orderNo))
    const deneme1 = modifiedData.find(item => item.orderNo === Math.max(...is_started_true_array))
    setCurrent(Math.floor(deneme1?.key) <= 6 || deneme1?.key === undefined ? 1 : (deneme1?.key / 6) % 2 === 0 ? Math.floor(deneme1?.key / 6) : Math.floor((deneme1?.key / 6) + 1))

  }, [modifiedData]);

  const handleTableChange = (pagination, filters, sorter, currentTable) => {
    // console.log('Various parameters', pagination, filters, sorter);
    // setFilteredInfo(filters);
    // setSortedInfo(sorter);
    // setCurrentTable(currentTable?.currentDataSource)
  };

  //* table column başlıkları
  const columns = [
    {
      title: "Sipariş Numarası",
      dataIndex: "orderNo",
      key: "orderNo",
      align: "center",
      width: 80,
    },
    {
      title: "Saat",
      dataIndex: "toplama_period",
      key: "toplama_period",
      align: "center",
      width: 80,
    },


    {
      title: "Sipariş İçeriği",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: 200,
    },
    {
      title: "Hedef",
      dataIndex: "target",
      key: "target",
      align: "center",
      width: 100,
    },
    {
      title: "Fiili Miktar (Gövde)",
      dataIndex: "fiiliMiktarGovde",
      key: "fiiliMiktarGovde",
      align: "center",
      width: 100,
    },
    {
      title: "Fiili Miktar (Gövde Ön)",
      dataIndex: "fiiliMiktarGovdeOn",
      key: "fiiliMiktarGovdeOn",
      align: "center",
      width: 100,
    },

  ];

  const getColumns = () => {
    return columns;
  };

  const handleClickExcel = () => {
    const excel = new Excel();
    let newColumns = columns.filter(
      (column) => column.title !== "Spv No" && column.title !== "Sayaç"
    );

    newColumns.push({ title: "Spv No", dataIndex: "spvNo" });
    newColumns.push({ title: "Sayaç", dataIndex: "sayac" });


    excel
      .addSheet("test")
      .addColumns(newColumns)
      .addDataSource(modifiedData, {
        str2Percent: true,
      })
      .saveAs("Excel.xlsx");
  };

  const onChange1 = (page) => {
    console.log(page);
    setCurrent(page);
  };

  const pageSize = 6;

  const getData = (current, pageSize) => {
    // Normally you should get the data from the server
    return modifiedData.slice((current - 1) * pageSize, current * pageSize);
  };
  useEffect(() => {
    localStorage.setItem("urlpaintshop", location.pathname)
    setCurrentUrl(localStorage.getItem("urlpaintshop"));
  }, [location.pathname, setCurrentUrl])

  return (
    <div className="flex w-full flex-col h-full">
      <TabbarDataCardLayout>
        <TopBarToplama
          handleClickExcel={handleClickExcel}
          setStart={setStart}
          start={start}
          socket={socket}
          startTitle={startTitle}
          modifiedData={modifiedData}
          faultTitle={faultTitle}
        />
        <DataCard
          cardData={modifiedData.find(
            (item) => item.is_started_toplama === true
          )}
          counter={allData[completed]?.fields?.silver_production}
          typeInfo={typeInfo}
          lastHanger={lastHanger}

        />
      </TabbarDataCardLayout>
      <div className="flex justify-end gap-5">
        <Popover content={`Sistem Durumu: ${wsStatus ? "Aktif" : "Aktif Değil"}`}>

          <div
            className={`flex mt-[-10px] mr-4 w-6 h-6 rounded-full ${wsStatus ? 'bg-green-500' : 'bg-red-500'} ${wsStatus ? 'shadow-green-700 shadow-md' : 'shadow-red-700 shadow-md'}`}
          ></div>
        </Popover>

      </div>
      <div className="tableAsma flex h-full overflow-auto w-full mt-2">

        <Table
          bordered
          sticky
          dataSource={getData(current, pageSize, modifiedData)}
          // loading={loading}
          className="table-main-toplama"
          rowClassName={(record, index) => {
            //* biten column'un renginin yeşil olmasını istemiyorsak, || record.is_completed === true kodunu sil
            // console.log("record", record);
            return record.is_started_toplama === true ? "table-first-item table-height-toplama " :
              record.is_completed_toplama === true ? "table-first-item-complete table-height-toplama "
                : index % 2 === 0
                  ? "table-row-light table-height-toplama"
                  : "table-row-dark table-height-toplama";
          }}
          onChange={handleTableChange}
          columns={getColumns()}
          // scroll={{ x: 2000 }}
          // pagination={{ pageSize: 6 }}
          pagination={false}
          scrollToFirstRowOnChange={true}

        />
      </div>
      <Pagination className="text-end" current={current} onChange={onChange1} total={Math.floor(modifiedData.length * 1.6666666667)} pageSize={pageSize} />

    </div>
  );
}
