import { useEffect, useState } from "react";
import { Table, DatePicker } from "antd";
import axios from "axios";
import { apiUrl } from "../config";

export default function VardiyaPlan() {
    const [modifiedData, setModifiedData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [archiveDate, setArchiveDate] = useState("")

    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_SECRET_KEY;


    const getData = () => {
        setLoading(true)
        axios.get(`${apiUrl}/getDailyBSHStartStopResults`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then((response) => setAllData(response.data))
            .catch((error) => console.log(error))
        setLoading(false)
    }

    const getDataArchive = () => {
        setLoading(true)
        axios.get(`${apiUrl}/getBSHStartStopResultsAtDate/${(archiveDate)}`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then((response) => setAllData(response.data))
            .catch((error) => console.log(error))
        setLoading(false)
    }

    useEffect(() => {
        setAllData([])
        if (archiveDate) getDataArchive()
        else getData();

    }, [archiveDate]);

    useEffect(() => {
        const dataList = allData?.map((item, index) => ({
            key: index + 1,
            siparis: item?.siparis,
            period: item?.period,
            order_no: item?.order_nr,
            uretim: item?.uretim, fiiliMiktarGovde: item?.actual_production_toplama_govde,
            fiiliMiktarGovdeOn: item?.actual_production_toplama_govde_on,
            toplama_period: item?.toplama_period,
            target: item?.uretim,

        }));

        setModifiedData([...dataList]);
    }, [allData]);

    const handleTableChange = (pagination, filters, sorter, currentTable) => {

    };

    const onChange = (date, dateString) => {
        setArchiveDate(dateString);
    };

    const columns = [
        {
            title: "Sipariş Numarası",
            dataIndex: "order_no",
            key: "order_no",
            align: "center",
            width: 110,
        },
        {
            title: "Asma Saati",
            dataIndex: "period",
            key: "period",
            align: "center",
            width: 130,

        },
        {
            title: "Toplama Saati",
            dataIndex: "toplama_period",
            key: "toplama_period",
            align: "center",
            width: 80,
        },

        {
            title: "Sipariş",
            dataIndex: "siparis",
            key: "siparis",
            align: "center",
            width: 200,
        },
        {
            title: "Üretim",
            dataIndex: "uretim",
            key: "uretim",
            align: "center",
            width: 80,
        },
        {
            title: "Fiili Miktar (Gövde)",
            dataIndex: "fiiliMiktarGovde",
            key: "fiiliMiktarGovde",
            align: "center",
            width: 100,
        },
        {
            title: "Fiili Miktar (Gövde Ön)",
            dataIndex: "fiiliMiktarGovdeOn",
            key: "fiiliMiktarGovdeOn",
            align: "center",
            width: 100,
        },

        {
            title: "Hedef",
            dataIndex: "target",
            key: "target",
            align: "center",
            width: 100,
        },

    ];

    return (
        <div className="flex flex-col gap-2 overflow-auto">
            <div className="text-center p-2 shadow-md bg-labelColor font-bold rounded-md justify-between flrx flex-col " >
                <h2 className="text-lg" >Vardiya Planı</h2>
                <div><DatePicker onChange={onChange} placeholder="Tarih seçiniz" /></div>

            </div>
            <div className="tableAsma flex h-full overflow-auto w-full">

                <Table
                    // className="table-main1"
                    bordered
                    sticky
                    loading={loading}
                    dataSource={modifiedData}
                    rowClassName={(record, index) => {
                        return record.is_started_asma === true ||
                            record.is_completed_asma === true
                            ? "table-first-item table-height"
                            : index % 2 === 0
                                ? "table-row-light table-height"
                                : "table-row-dark table-height";
              
                    }}

                    onChange={handleTableChange}
                    columns={columns}
                    pagination={{ pageSize: 6 }}
                    scrollToFirstRowOnChange={true}


                />
            </div>
        </div>
    );
}
