import { Button, Table,Popconfirm,message } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { apiUrl } from '../../config'
import BodyTypeModalFront from '../ModalComponent/ConstraintModal/BodyTypeModalFront'

const BodyTypeFront = () => {

    const [allData, setAllData] = useState([])
    const [modifiedData, setModifiedData] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState([])
    const [refreshPage, setRefreshPage] = useState(false)
    const [rule, setRule] = useState("")
    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_SECRET_KEY;

    const getData = () => {
        axios.get(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/getFrontBodyMaterialType/all`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then(res => setAllData(res.data))
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getData()
    }, [refreshPage])

    const handleClick = (row) => {
        setSelectedRow(row)
        setModalOpen(true)
        setRule("changeOrder")
    }

    const handleDelete = (row) => {
        axios.delete(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/deleteFrontBodyMaterialType/${row.id}`, {
            headers: {
                Authorization: `Basic ${btoa(`${username}:${password}`)}`
            }
        })
        .then(res => {
            setRefreshPage(!refreshPage); 
        })
        .catch(err => {
            console.error("Silme işlemi sırasında hata oluştu:", err);
        });
    };

    useEffect(() => {
        const dataList = allData?.map((item, index) => ({
            key: index + 1,
            description: item?.description,
            id: item?.id
        }));

        setModifiedData([...dataList]);
    }, [allData]);

    const columns = [
        {
            title: "Gövde Ön Tipi",
            dataIndex: "description",
            key: "description",
            align: "center",
            width: 120,
        },
       
        {
            title: "İşlem",
            dataIndex: "islem",
            key: "islem",
            align: "center",
            width: 150,
            render: (record, row) => {
                return (
                    <div className='flex flex-row gap-3 justify-center'>
                        <Button
                            onClick={() => handleClick(row)}
                            style={{ fontFamily: "sans-serif" }}
                            className='text-white bg-smartopt font-semibold'
                        >
                            Değiştir
                        </Button>
                        <Popconfirm
                            title="Sil"
                            description="Silmek istediğinize emin misiniz?"
                            onConfirm={() => {
                                handleDelete(row)
                                message.success('İşlem Başarılı');
                            }}
                            onCancel={() => console.log("cancel")}
                            okText="Evet"
                            cancelText="Hayır"
                            okType="primary"
                        >
                            <Button
                                style={{ fontFamily: "sans-serif" }}
                                className='text-white bg-smartopt font-semibold'
                            >
                                Sil
                            </Button>
                        </Popconfirm>
                    </div>
                );
            },
        },


    ];


    return (
        <div style={{ maxHeight: '82vh', overflowY: 'auto' }}>
            <BodyTypeModalFront
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                modifiedData={modifiedData}
                selectedRow={selectedRow}
                rule={rule}
                setRefreshPage={setRefreshPage}
                refreshPage={refreshPage}
            />
            <div className="flex p-4 flex-col h-full gap-2">
                <div className='flex w-max bg-smartopt rounded-lg'>
                    <Button className='text-white font-semibold' onClick={() => {
                        setModalOpen(true)
                        setRule("newOrder")
                    }}>
                        Gövde Tipi Ekle
                    </Button>
                </div>
                <div className="tableAsma flex h-full overflow-auto w-full">
                    <Table
                        bordered
                        sticky
                        dataSource={modifiedData}
                        // loading={loading}
                        // rowClassName={(record, index) => {
                        //     //* biten column'un renginin yeşil olmasını istemiyorsak, || record.is_completed === true kodunu sil
                        //     // console.log("record", record);
                        //     return record.is_started_asma === true ||
                        //         record.is_completed_asma === true
                        //         ? "table-first-item table-height"
                        //         : index % 2 === 0
                        //             ? "table-row-light table-height"
                        //             : "table-row-dark table-height";
                        // }}
                        // onChange={handleTableChange}
                        columns={columns}
                        scroll={{ x: 700 }}
                        pagination={{ pageSize: 6 }}
                    // scrollToFirstRowOnChange={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default BodyTypeFront