import React, { useContext, useEffect, useState } from "react";
import { Table, Button, notification, message, Popconfirm, Popover } from "antd";
import { Excel } from "antd-table-saveas-excel";
import DataCard from "../Components/DataCard";
import { MenuOutlined, ArrowDownOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import TopBarSPV from "../Components/TopbarSPV";
import ChangeTargetModal from "../Components/ModalComponent/ChangeTargetModal";
import TabbarDataCardLayout from "../layouts/TabbarDataCardLayout";
import { CheckCircleTwoTone, WarningTwoTone, LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import SyncIcon from '@mui/icons-material/Sync';
import { baseUrl } from "../config";
import UserContext from "../Context/UserContext";
import TableContext from "../Context/TablePosition";
import { useLocation } from "react-router-dom";

export default function HomeSpv() {
  const [modifiedData, setModifiedData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allData1, setAllData1] = useState([]);

  const [refresh, setRefresh] = useState(false);
  const [faultTitle, setFaultTitle] = useState("");
  const [completed, setCompleted] = useState(0);
  const [alarm, setAlarm] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [batchRefreshTime, setBatchRefreshTime] = useState("");
  const [isModalOpenTarget, setIsModalOpenTarget] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [lastHanger, setLastHanger] = useState({});

  const [socket, setSocket] = useState(null);
  const [socket1, setSocket1] = useState(null);
  const [isPlanning, setIsPlanning] = useState(false);
  const [isInform, setIsInform] = useState(false);

  const { user } = useContext(UserContext)
  const { setCurrentUrl } = useContext(TableContext)
  const location = useLocation();
  const [wsStatus, setWsStatus] = useState(true)

  useEffect(() => {
    localStorage.setItem("urlpaintshop", location.pathname)
    setCurrentUrl(localStorage.getItem("urlpaintshop"));
  }, [location.pathname, setCurrentUrl])

  useEffect(() => {
    const handleOnline = () => {
      console.log("Yeniden Bağlanıyor...");
      const ws = new WebSocket(`${baseUrl}/ws/hang-socket-server/`);
      setSocket(ws);
      setWsStatus(true)

    };

    const handleOffline = () => {
      console.log("Çevrimdışı - WebSocket bağlantısı kapatıldı.");
      if (socket) {
        socket.close();
        setWsStatus(false)
      }
    };
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);

    };
  }, [socket]);

  useEffect(() => {
    const ws = new WebSocket(
      `${baseUrl}/ws/hang-socket-server/`
    );
    setSocket(ws);

    return () => {
      console.log("close home socket");
      ws.close();
    };
  }, []);

  useEffect(() => {
    const ws1 = new WebSocket(
      `${baseUrl}/ws/scheduleplanningtrack-socket-server/`
      // "ws://127.0.0.1:8000/ws/scheduleplanningtrack-socket-server/"
    );
    setSocket1(ws1);

    return () => {
      console.log("close home socket");
      ws1.close();
    };
  }, []);

  useEffect(() => {
    if (socket1) {
      socket1.onopen = function (event) {
        console.log("Socket opened");
      };
    }
    if (socket1) {
      socket1.onmessage = function (e) {
        let newData1 = JSON.parse(e.data);
        setAllData1(newData1)
      }
    }
  }, [socket1]);


  useEffect(() => {
    allData.length > 0 &&
      allData.find((item, index) => {
        if (item.fields.is_completed_asma === true) {
          return setCompleted(index + 1);
        }
      });
    alarm.type === "Alarm" && setIsModalOpen(true);
  }, [allData, alarm.type]);

  useEffect(() => {
    if (socket) {
      socket.onopen = function (event) {
        console.log("Socket opened");
      };
    }

  }, []);

  useEffect(() => {
    let timer;

    if (socket) {
      socket.onmessage = function (e) {
        let newData = JSON.parse(e.data);


        setFaultTitle(
          newData?.system_status
        );

        setAllData(
          newData?.data?.filter(
            (item) => item?.fields?.is_completed_asma === false
          )
        );
        setAlarm(newData);


        setBatchRefreshTime(newData?.last_refreshed_plan);
        setLastHanger(newData.last_hanger);

        let actualProduction =
          newData?.data[completed]?.fields?.actual_production;
        let govdeUretim = newData?.data[completed]?.fields?.uretim;

        if (actualProduction >= govdeUretim) {
          socket.send(
            JSON.stringify({
              event_type: "CompleteEvent",
              add_to_next: false,
              user: user.accountDetail.name,

            })
          );
        }
      };
      timer = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) {
          console.log("send HealthCheck");
          socket.send(
            JSON.stringify({
              event_type: "HealthCheck",
            })
          );
        } else if (socket.readyState === WebSocket.CLOSED) {
          console.log("Socket is closed. Reopening...");
          const newSocket = new WebSocket(`${baseUrl}/ws/hang-socket-server/`);
          setSocket(newSocket);
        }
      }, 8000);
      return () => clearInterval(timer);
    }
  }, [refresh, socket, completed]);

  useEffect(() => {
    const dataList = allData?.map((item, index) => ({
      key: index + 1,
      orderNo: Math.floor(item?.fields?.order_nr),
      spvNo: item?.fields?.sequence,
      date: item?.fields?.date,
      time: item?.fields?.period,
      description: item?.fields?.siparis,
      target: item?.fields?.uretim,
      fiiliMiktar: item?.fields?.actual_production_asma,
      is_completed_asma: item?.fields?.is_completed_asma,
      is_started_asma: item?.fields?.is_started_asma,
    }));

    setModifiedData([...dataList]);
  }, [allData]);
  const handleTableChange = (pagination, filters, sorter, currentTable) => {
    // console.log('Various parameters', pagination, filters, sorter);
    // setFilteredInfo(filters);
    // setSortedInfo(sorter);
    // setCurrentTable(currentTable?.currentDataSource)
  };

  const handleClick = () => {
    const excel = new Excel();
    let newColumns = columns.filter((column) => (
      column.title !== "SmartOpt Sipariş Numarası" &&
      column.title !== "Spv No" &&
      column.title !== "Saat" &&
      column.title !== "Sipariş İçeriği" &&
      column.title !== "Hedef" &&
      column.title !== "Fiili Miktar" &&

      column.key !== "sort"

    ))
    newColumns.push({ title: "SmartOpt Sipariş Numarası", dataIndex: "orderNo" })
    newColumns.push({ title: "Spv No", dataIndex: "spvNo" })
    newColumns.push({ title: "Saat", dataIndex: "time" })
    newColumns.push({ title: "Sipariş İçeriği", dataIndex: "description" })
    newColumns.push({ title: "Hedef", dataIndex: "target" })
    newColumns.push({ title: "Fiili Miktar", dataIndex: "fiiliMiktar" })

    excel
      .addSheet("Sheet1")
      .addColumns(newColumns)
      .addDataSource(modifiedData, {
        str2Percent: true
      })
      .saveAs("Excel.xlsx");
  };



  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, value, icon, duration, style) => {
    api.info({
      message: `Sistem Bilgisi`,
      description:
        `${value}`,
      placement,
      icon: icon || <CheckCircleTwoTone twoToneColor="#52c41a" />,
      duration: duration,
      style: style
    });
  };

  // Draggable table function

  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        }
      )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
      transition,
      ...(isDragging
        ? {
          position: "relative",
          zIndex: 9999,
        }
        : {}),
    };



    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === "sort") {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: "none",
                    cursor: "move",
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setModifiedData((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        const activeSpv = modifiedData.find(item => (item.key - 1) === activeIndex)?.spvNo
        const overSpv = modifiedData.find(item => (item.key - 1) === overIndex)?.spvNo
        // console.log(activeSpv, overSpv)
        socket.send(
          JSON.stringify({
            event_type: "PeriodChangeEvent",
            from: activeSpv,
            to: overSpv,
            user: user.accountDetail.name,

          })
        );

        return arrayMove(previous, activeIndex, overIndex);
      });
    }
    const activeIndex = modifiedData.findIndex((i) => i.key === active.id);
    const overIndex = modifiedData.findIndex((i) => i.key === over?.id);

    // if (allData1.data === "Planning") {
    //   if (activeIndex < 4 || overIndex < 4) {
    //     openNotification(
    //       'top',
    //       "İlk 4 satırın yeri değiştirilemez.",
    //       <WarningTwoTone twoToneColor="red" />
    //     );
    //     return;
    //   }

    // }

  };

  useEffect(() => {
    if (alarm?.service_response) {
      openNotification('topRight', alarm?.service_response, alarm?.service_response === "Başarılı" ? <CheckCircleTwoTone twoToneColor="green" /> : <WarningTwoTone twoToneColor="red" />)
    }

  }, [alarm, socket])

  useEffect(() => {
    if (allData1.data === "Planning") {
      openNotification('topRight', "Yeni Plan Üretiliyor.", <SyncOutlined spin twoToneColor="red" />, isPlanning === "Planning")
      setIsPlanning(true)

    }
    else {
      setIsPlanning(false);
      setIsInform(false)
    }
  }, [allData1.data, isPlanning])

  useEffect(() => {

    if (allData1.data === "Inform Planning") {
      openNotification('topRight', "Plan 5 dakika sonra çalışacaktır", <SyncOutlined spin twoToneColor="red" />, isInform === "Inform Planning")
      setIsInform(true)
    }
    else {
      setIsInform(false)
    }
  }, [allData1.data, isInform])



  const columns = [
    {
      key: "sort",
      width: 45,
    },
    {
      title: "SmartOpt Sipariş Numarası",
      dataIndex: "orderNo",
      key: "orderNo",
      align: "center",
      width: 150,
      render: (row, record) => {
        return (
          <div className="flex flex-row justify-center gap-5">
            <p>{record.orderNo}</p>
            {
              record.spvNo === 1 ? <Button className="opacity-0"><ArrowDownOutlined /></Button>
                :
                <Button onClick={(e) => {
                  socket.send(
                    JSON.stringify({
                      event_type: "PeriodChangeEvent",
                      from: record.spvNo,
                      to: modifiedData[modifiedData.length - 1].spvNo,
                      user: user.accountDetail.name,

                    })
                  );
                }}><ArrowDownOutlined /></Button>
            }

          </div>
        )
      }
    },
    {
      title: "Spv No",
      dataIndex: "spvNo",
      key: "spvNo",
      width: 90,
      align: "center",

    },

    {
      title: "Saat",
      dataIndex: "time",
      key: "time",
      align: "center",
      width: 100,
    },

    {
      title: "Sipariş İçeriği",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: 180,
      render: (record, row) => {
        return (
          <div>
            {
              row.description.includes("Single") ?
                <div className="flex justify-between">
                  <p>{row.description}</p>
                  <Popconfirm
                    title="Single Sipariş Değişikliği"
                    description="Siparişi değiştirmek istediğinize emin misiniz?"
                    onConfirm={() => {
                      socket.send(
                        JSON.stringify({
                          event_type: "TransformOrderEvent",
                          order_nr: row.orderNo,
                          user: user.accountDetail.name,

                        })
                      )
                      message.success('İşlem Başarılı');
                    }}
                    onCancel={() => console.log("cancel")}
                    okText="Evet"
                    cancelText="Hayır"
                    okType="primary"
                  >
                    <Button>
                      <SyncIcon />
                    </Button>
                  </Popconfirm>
                </div>
                : <p>{row.description}</p>

            }
          </div>
        )
      }
    },
    {
      title: "Hedef",
      dataIndex: "target",
      key: "target",
      align: "center",
      width: 150,
      render: (record, row) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
              gap: "15px",
            }}
          >
            <p style={{ flex: 1, textAlign: "center" }}>
              {row.target}
            </p>
            <Button
              onClick={(e) => {
                setSelectedRow(row);
                setIsModalOpenTarget(true);
              }}
              style={{ fontFamily: "sans-serif" }}
            >
              Değiştir
            </Button>
          </div>
        );
      },
    },
    {
      title: "Fiili Miktar",
      dataIndex: "fiiliMiktar",
      key: "fiiliMiktar",
      align: "center",
      width: 100,
    },

  ];

  const getColumns = () => {

    return columns;
  };

  const handleClickExcel = () => {
    const excel = new Excel();
    let newColumns = columns.filter(
      (column) => column.title !== "Spv No" && column.title !== "Sayaç"
    );

    newColumns.push({ title: "Spv No", dataIndex: "spvNo" });
    newColumns.push({ title: "Sayaç", dataIndex: "sayac" });

    //* count kısmıda sayac oldugundan onu exceldeki tablodan cıkarıyoruz.
    const newData = modifiedData.filter((item) => !item.count);

    excel
      .addSheet("test")
      .addColumns(newColumns)
      .addDataSource(modifiedData, {
        str2Percent: true,
      })
      .saveAs("Excel.xlsx");
  };

  return (
    <div className="flex w-full flex-col h-full">
      {contextHolder}

      <ChangeTargetModal
        isModalOpenTarget={isModalOpenTarget}
        setIsModalOpenTarget={setIsModalOpenTarget}
        socket={socket}
        selectedRow={selectedRow}
        allData={allData}

      />
      <TabbarDataCardLayout>
        <TopBarSPV
          handleClickExcel={handleClickExcel}
          socket={socket}
          faultTitle={faultTitle}
          modifiedData={modifiedData}
          cardData={modifiedData.find((item) => item.is_started_asma === true)}
          setRefresh={setRefresh}
          allData={allData}
          alarm={alarm}
        />
        <DataCard
          cardData={allData?.find(
            (item) =>
              item.fields.is_started_asma === true &&
              item.fields.is_completed_asma === false
          )}
          counter={allData[completed]?.fields?.actual_production}
          batchRefreshTime={batchRefreshTime}
          alarm={alarm}
          lastHanger={lastHanger}
        />
      </TabbarDataCardLayout>
      <div className="flex justify-end gap-5">
        <Button className="bg-white w-36 mt-[-12px] flex items-center justify-center" onClick={handleClick}>Excel İndir</Button>
        <Popover content={`Sistem Durumu: ${wsStatus ? "Aktif" : "Aktif Değil"}`}>

          <div
            className={`flex mt-[-10px] mr-4 w-6 h-6 rounded-full ${wsStatus ? 'bg-green-500' : 'bg-red-500'} ${wsStatus ? 'shadow-green-700 shadow-md' : 'shadow-red-700 shadow-md'}`}
          ></div>
        </Popover>
      </div>

      <div className="tableAsma flex h-full overflow-auto w-full">
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={modifiedData.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              bordered
              sticky
              dataSource={modifiedData}
              // loading={loading}
              rowClassName={(record, index) => {
                //* biten column'un renginin yeşil olmasını istemiyorsak, || record.is_completed === true kodunu sil
                // console.log("record", record);
                return record.is_started_asma === true ||
                  record.is_completed_asma === true
                  ? "table-first-item table-height"
                  : index % 2 === 0
                    ? "table-row-light table-height"
                    : "table-row-dark table-height";
              }}
              components={{
                body: {
                  row: Row,
                },
              }}
              rowKey="key"
              onChange={handleTableChange}
              columns={getColumns()}
              pagination={{ pageSize: 100 }}
              scrollToFirstRowOnChange={true}
              scroll={{ x: 900 }}
            />
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}
