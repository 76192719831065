import { useEffect, useState } from "react";
import { Table, DatePicker } from "antd";
import axios from "axios";
import { apiUrl } from "../config";

export default function StokTable() {
    const [modifiedData, setModifiedData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [archiveDate, setArchiveDate] = useState("")

    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_SECRET_KEY;

    const getData = () => {
        setLoading(true)
        axios.get(`${apiUrl}/getDailyStockManagement`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then((response) => setAllData(response.data.filter(item => !item.material_description.includes("Gövde Ön"))))
            .catch((error) => console.log(error))
        setLoading(false)
    }

    // console.log(allData.filter(item=> item.material_description.includes("Gövde Ön")))
    console.log(allData)
    const getDataArchive = () => {
        setLoading(true)
        axios.get(`${apiUrl}/getStockManagementAtDate/${(archiveDate)}`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then((response) => setAllData(response.data.filter(item => !item.material_description.includes("Gövde Ön"))))
            .catch((error) => console.log(error))
        setLoading(false)
    }

    useEffect(() => {
        setAllData([])
        if (archiveDate) getDataArchive()
        else getData();

    }, [archiveDate]);

    useEffect(() => {
        const dataList = allData?.map((item, index) => ({
            key: index + 1,
            material_description: item?.material_description,
            stock_amount: item?.stock_amount,
            date: item?.date,
            malzeme_kodu : item?.malzeme_kodu,
        }));

        setModifiedData([...dataList]);
    }, [allData]);
    const handleTableChange = (pagination, filters, sorter, currentTable) => {
        // console.log('Various parameters', pagination, filters, sorter);
        // setFilteredInfo(filters);
        // setSortedInfo(sorter);
        // setCurrentTable(currentTable?.currentDataSource)
    };

    const onChange = (date, dateString) => {
        setArchiveDate(dateString);
    };
    const columns = [
        {
            title: "Tarih",
            dataIndex: "date",
            key: "date",
            align: "center",
            width: 180,
        },
        {
            title: "Malzeme Kodu",
            dataIndex: "malzeme_kodu",
            key: "malzeme_kodu",
            align: "center",
            width: 180,
        },
        {
            title: "Materyal Adı",
            dataIndex: "material_description",
            key: "material_description",
            align: "center",
            width: 180,
        },
        {
            title: "Üretim Miktarı",
            dataIndex: "stock_amount",
            key: "stock_amount",
            align: "center",
            width: 180,
        },

        // },

    ];

    return (
        <div className="flex flex-col gap-2 overflow-auto">

            <div className="text-center p-2 shadow-md bg-labelColor font-bold rounded-md justify-between flrx flex-col " >
                <h2 className="text-lg">Gövde Üretim Miktarı</h2>
                <div><DatePicker placeholder="Tarih seçiniz" onChange={onChange} /></div>
            </div>
            <div className="tableAsma flex h-full overflow-auto w-full">

                <Table
                    // className="table-main1"
                    bordered
                    sticky
                    loading={loading}
                    dataSource={modifiedData}
                    rowClassName={(record, index) => {
                        return record.is_started_asma === true ||
                            record.is_completed_asma === true
                            ? "table-first-item table-height"
                            : index % 2 === 0
                                ? "table-row-light table-height"
                                : "table-row-dark table-height";
                    }}

                    onChange={handleTableChange}
                    columns={columns}
                    pagination={{ pageSize: 50 }}
                    scrollToFirstRowOnChange={true}


                />
            </div>
        </div>
    );
}
