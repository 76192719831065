import React, { useContext, useState } from "react";
import TopButton from "./TopButton";
import { useMsal } from "@azure/msal-react";
import ChangeOrderModal from "./ModalComponent/ChangeOrderModal/ChangeOrderModal";
import BreakDownModal from "./ModalComponent/BreakDownModal";
import Ariza from "../assets/image/ariza.png";
import Baslat from "../assets/image/baslat.png";
import ExitIcon from "../assets/image/exit-icon.png";
import Pause from "../assets/image/pause.png";
import StopOrderModal from "./ModalComponent/StopOrderModal";
import ManualPlanning from "../assets/image/addOrder.png";
import Cancel from "../assets/image/cancel.png";
import AddOrderModal from "./ModalComponent/AddOrderModal";
import axios from "axios";
import { apiUrl } from "../config";
import UserContext from "../Context/UserContext";

export default function TopBarSPV({
  setRefresh,
  socket,
  faultTitle,
  modifiedData,
  cardData,
  allData,
  alarm
}) {
  const [modalOpenStopOrder, setModalOpenStopOrder] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenAddOrder, setIsModalOpenAddOrder] = useState(false);
  const [reasonData, setReasonData] = useState([])
  const [isOpenBreakDownModal, setisOpenBreakDownModal] = useState(false);
  const { user } = useContext(UserContext)

  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;

  const { instance } = useMsal();
  const buttonData = [
    // { title: startTitle, icon: startTitle === "Çalışıyor" ? Pause : Baslat },
    { title: "Bitir", icon: Cancel },
    { title: "Sipariş Ekle", icon: ManualPlanning },
    {
      title: (faultTitle === "Failure" && "Arıza Bitir") || (faultTitle === "Start" && "Arıza Başlat"),
      icon: Ariza
    },
    { title: "Çıkış", icon: ExitIcon },
  ];


  const faultButton =  () => {
    axios.get(`${apiUrl}/getFailureReasons/${window.location.pathname === "/asma-spv" || window.location.pathname === "/asma" ? "Asma" : "Toplama"}`, {
      auth: {
        username: username,
        password: password
      }
    })
    .then((response) => {
      setReasonData(response.data);
      console.log(response.data); 
    })
    .catch((error) => console.log(error));

    if (faultTitle === "Start") {
      return setisOpenBreakDownModal(true)
    } else {
      axios.post(`${apiUrl}/addFailure`,
        ({
          user: user.accountDetail.name,
          reason_id: 1,
        })
      )
    }
  };
  // console.log(reasonData)
  // console.log(`${apiUrl}/getFailureReasons/${window.location.pathname === "/asma-spv" || window.location.pathname === "/asma" ? "Asma" : "Toplama"}`)
  const handleLogOut = () => {
    instance.logoutRedirect();
    localStorage.clear();
  };

  const AddOrder = () => {
    setIsModalOpenAddOrder(true);
  };

  const handleYesButton = () => {
    if (socket) {
      socket.send(
        JSON.stringify({
          event_type: "CompleteEvent",
          user: user.accountDetail.name,

        })
      );
    }

    setModalOpenStopOrder(false);
    setRefresh((p) => !p);
    window.location.reload();
  };

  return (
    <>
      <ChangeOrderModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        socket={socket}
      />
      <AddOrderModal
        isModalOpenAddOrder={isModalOpenAddOrder}
        setIsModalOpenAddOrder={setIsModalOpenAddOrder}
        socket={socket}
        allData={allData}
      />
      <BreakDownModal
        isOpenBreakDownModal={isOpenBreakDownModal}
        setisOpenBreakDownModal={setisOpenBreakDownModal}
        modifiedData={modifiedData}
        socket={socket}
        cardData={cardData}
        reasonData={reasonData}
      />
      <StopOrderModal
        modalOpenStopOrder={modalOpenStopOrder}
        setModalOpenStopOrder={setModalOpenStopOrder}
        modifiedData={modifiedData}
        socket={socket}
        cardData={cardData}
        setRefresh={setRefresh}
      />
      <div className="flex flex-row items-center justify-center">
        {buttonData &&
          buttonData.map((item, index) => (
            <React.Fragment key={index}>
              <TopButton
                title={item.title}
                icon={item.icon}
                onClick={(() => {
                  switch (index) {
                    case 0:
                      // return handleLogOut;
                      return handleYesButton;
                    case 1:
                      return AddOrder;
                    case 2:
                      return faultButton;
                    // return null
                    case 3:
                      return handleLogOut;
                    // return null;

                    default:
                      return null;
                  }
                })()}
              />
            </React.Fragment>
          ))}
      </div>
    </>
  );
}
