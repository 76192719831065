import React, { useContext } from "react";
import { Modal } from "antd";
import { useState } from "react";
import { apiUrl } from "../../config";
import axios from "axios";

const ChangeReasonModal = ({
  modalOpen,
  setModalOpen,
  selectedRow,
  allData,
  reasonData,
  getDataArchive,
  getData,
  archiveDate
}) => {
  const [formData, setFormData] = useState({
    id: ""
  });

  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios.post(`${apiUrl}/addOrderControlReasonToHistory/${selectedRow.id}/${formData.id}`, null, {
      auth: {
        username: username,
        password: password
      }
    })
      .then(res => {
        if (res.status === 200) {
          setModalOpen(false)
          setFormData({
            id: ""
          })
          if (archiveDate) getDataArchive()
          else getData();
        }
      })
      .catch(err => console.log(err))
  };
  return (
    <>
      <Modal
        centered
        open={modalOpen}
        footer={null}
        onOk={() => setModalOpen(false)}
        onCancel={() => {
          setFormData({
            id: ""
          });
          setModalOpen(false);
        }}
        className="min-w-max top-1/2 min-h-[300px] justify-between "
      >
        <form
          onSubmit={handleSubmit}
          className="flex !min-h-[300px]  flex-col justify-between"
        >
          <div className="flex text-5xl p-5 mt-10 flex-row w-full gap-2 items-center ">
            <div className="flex flex-row">
              <div className="w-56  flex justify-between mt-2">
                <label>Sebep</label>
                <span>:</span>
              </div>
              <select name="id" onChange={handleChange} value={formData.id} className="border-2 border-black w-[500px]">
                <option value="" selected>Seçiniz</option>
                {
                  reasonData.map(item => <option key={item.id} value={item.id}>{item.order_control_reason}</option>)
                }

              </select>
            </div>

          </div>

          <button
            className="h-[80px] bg-mainYellow w-full text-[40px] rounded-b-xl hover:bg-secondYellow"
            type="submit"
            disabled={formData.id === "" ? true : false}

          >
            Gönder
          </button>
        </form>
      </Modal>
    </>
  );
};

export default ChangeReasonModal;
