import React from "react"
import SmartoptLogo from "../assets/image/smartoptLogo.png"

const SuspenseContainer = ({ JsxElement }) => {
    return <React.Suspense
        fallback={<div style={{width:"100%",height:"100%" , display:"flex" , justifyContent:"center" , alignItems:"center",marginTop:"25%" }}>
            <img  style={{width:"100px" }} src={SmartoptLogo} alt="Smartopt Logo"  />
        </div>}
    >
        {JsxElement}
    </React.Suspense>
}

export default SuspenseContainer;