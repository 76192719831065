import React, { useContext, useEffect } from "react";
import { Modal } from "antd";
import { useState } from "react";
import ModalHeaderLayout from "../../../layouts/ModalHeaderLayout";
import UserContext from "../../../Context/UserContext";
import axios from "axios";
import { apiUrl } from "../../../config";

const SingularParameterModal = ({
  modalOpen,
  setModalOpen,
  selectedRow,
  allData,
  rule,
  setRefreshPage,
  refreshPage
}) => {
  const [formData, setFormData] = useState({
    hds_capacity: String(allData.map(item => item.hds_capacity)[0]),
    hds_efficiency: String(allData.map(item => item.hds_efficiency)[0]),
    hds_low_limit: String(allData.map(item => item.hds_low_limit)[0]),
    hds_high_limit: String(allData.map(item => item.hds_high_limit)[0]),
    id: String(allData.map(item => item.id)[0]),
    yer1_inventory_capacity: String(allData.map(item => item.yer1_inventory_capacity)[0]),
    yer2_inventory_capacity: String(allData.map(item => item.yer2_inventory_capacity)[0]),
    num_frozen_period: String(allData.map(item => item.num_frozen_period)[0]),
    min_lot_size: String(allData.map(item => item.min_lot_size)[0]),
    par_asma_toplama: String(allData.map(item => item.par_asma_toplama)[0]),
    parEndStockPenalty: String(allData.map(item => item.parEndStockPenalty)[0]),
    parSafetyUsagePenalty: String(allData.map(item => item.parSafetyUsagePenalty)[0]),
    parLowStockPenalty: String(allData.map(item => item.parLowStockPenalty)[0]),
    parGovdeLowStockPen: String(allData.map(item => item.parGovdeLowStockPen)[0]),
    parChosenStopCoef: String(allData.map(item => item.parChosenStopCoef)[0]),
    parGovdeOnLowStockPen: String(allData.map(item => item.parGovdeOnLowStockPen)[0]),
    parColorChangePenalty: String(allData.map(item => item.parColorChangePenalty)[0]),
    parMaterialGroupingPenalty: String(allData.map(item => item.parMaterialGroupingPenalty)[0]),

  });


  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setFormData({
      hds_capacity: String(allData.map(item => item.hds_capacity)[0]),
      hds_efficiency: String(allData.map(item => item.hds_efficiency)[0]),
      hds_low_limit: String(allData.map(item => item.hds_low_limit)[0]),
      hds_high_limit: String(allData.map(item => item.hds_high_limit)[0]),
      id: String(allData.map(item => item.id)[0]),
      yer1_inventory_capacity: String(allData.map(item => item.yer1_inventory_capacity)[0]),
      yer2_inventory_capacity: String(allData.map(item => item.yer2_inventory_capacity)[0]),
      num_frozen_period: String(allData.map(item => item.num_frozen_period)[0]),
      min_lot_size: String(allData.map(item => item.min_lot_size)[0]),
      par_asma_toplama: String(allData.map(item => item.par_asma_toplama)[0]),
      parEndStockPenalty: String(allData.map(item => item.parEndStockPenalty)[0]),
      parSafetyUsagePenalty: String(allData.map(item => item.parSafetyUsagePenalty)[0]),
      parLowStockPenalty: String(allData.map(item => item.parLowStockPenalty)[0]),
      parGovdeLowStockPen: String(allData.map(item => item.parGovdeLowStockPen)[0]),
      parChosenStopCoef: String(allData.map(item => item.parChosenStopCoef)[0]),
      parGovdeOnLowStockPen: String(allData.map(item => item.parGovdeOnLowStockPen)[0]),
      parColorChangePenalty: String(allData.map(item => item.parColorChangePenalty)[0]),
      parMaterialGroupingPenalty: String(allData.map(item => item.parMaterialGroupingPenalty)[0]),
    });
  }, [modalOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestMethod = axios.put

    requestMethod(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/updateSingularParameter`,
      formData, {
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`
      }
    })
      .then(res => {
        if (res.status === 200) {
          setFormData({
            hds_capacity: String(allData.map(item => item.hds_capacity)[0]),
            hds_efficiency: String(allData.map(item => item.hds_efficiency)[0]),
            hds_low_limit: String(allData.map(item => item.hds_low_limit)[0]),
            hds_high_limit: String(allData.map(item => item.hds_high_limit)[0]),
            id: String(allData.map(item => item.id)[0]),
            yer1_inventory_capacity: String(allData.map(item => item.yer1_inventory_capacity)[0]),
            yer2_inventory_capacity: String(allData.map(item => item.yer2_inventory_capacity)[0]),
            num_frozen_period: String(allData.map(item => item.num_frozen_period)[0]),
            min_lot_size: String(allData.map(item => item.min_lot_size)[0]),
            par_asma_toplama: String(allData.map(item => item.par_asma_toplama)[0]),
            parEndStockPenalty: String(allData.map(item => item.parEndStockPenalty)[0]),
            parSafetyUsagePenalty: String(allData.map(item => item.parSafetyUsagePenalty)[0]),
            parLowStockPenalty: String(allData.map(item => item.parLowStockPenalty)[0]),
            parGovdeLowStockPen: String(allData.map(item => item.parGovdeLowStockPen)[0]),
            parChosenStopCoef: String(allData.map(item => item.parChosenStopCoef)[0]),
            parGovdeOnLowStockPen: String(allData.map(item => item.parGovdeOnLowStockPen)[0]),
            parColorChangePenalty: String(allData.map(item => item.parColorChangePenalty)[0]),
            parMaterialGroupingPenalty: String(allData.map(item => item.parMaterialGroupingPenalty)[0]),
          })
          setModalOpen(false)
          setRefreshPage(!refreshPage)
        }
      })
      .catch(err => console.log(err))
  }
  return (
    <Modal
      title=<ModalHeaderLayout title="Tekil Parametreler" />
      open={modalOpen}
      footer={null}
      onOk={() => setModalOpen(false)}
      onCancel={() => {
        setFormData({
          hds_capacity: String(allData.map(item => item.hds_capacity)[0]),
          hds_efficiency: String(allData.map(item => item.hds_efficiency)[0]),
          hds_low_limit: String(allData.map(item => item.hds_low_limit)[0]),
          hds_high_limit: String(allData.map(item => item.hds_high_limit)[0]),
          id: String(allData.map(item => item.id)[0]),
          yer1_inventory_capacity: String(allData.map(item => item.yer1_inventory_capacity)[0]),
          yer2_inventory_capacity: String(allData.map(item => item.yer2_inventory_capacity)[0]),
          num_frozen_period: String(allData.map(item => item.num_frozen_period)[0]),
          min_lot_size: String(allData.map(item => item.min_lot_size)[0]),
          par_asma_toplama: String(allData.map(item => item.par_asma_toplama)[0]),
          parEndStockPenalty: String(allData.map(item => item.parEndStockPenalty)[0]),
          parSafetyUsagePenalty: String(allData.map(item => item.parSafetyUsagePenalty)[0]),
          parLowStockPenalty: String(allData.map(item => item.parLowStockPenalty)[0]),
          parGovdeLowStockPen: String(allData.map(item => item.parGovdeLowStockPen)[0]),
          parChosenStopCoef: String(allData.map(item => item.parChosenStopCoef)[0]),
          parGovdeOnLowStockPen: String(allData.map(item => item.parGovdeOnLowStockPen)[0]),
          parColorChangePenalty: String(allData.map(item => item.parColorChangePenalty)[0]),
          parMaterialGroupingPenalty: String(allData.map(item => item.parMaterialGroupingPenalty)[0]),
        })
        setModalOpen(false);
      }}
      centered
      className="min-w-max mb-40"
    >
      <form className='bg-white pl-10 pr-10 pb-10 rounded-2xl shadow-2xl' onSubmit={handleSubmit}>
        <div className='grid laptop:grid-cols-2 desktop:grid-cols-3 largeDesktop:grid-cols-4 gap-7'>

          <div>
            <label htmlFor="hds_capacity">HDS Kapasitesi</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="hds_capacity" value={formData.hds_capacity} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="hds_efficiency">HDS Verimlilik</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="hds_efficiency" value={formData.hds_efficiency} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="hds_low_limit">HDS Alt Limit</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="hds_low_limit" value={formData.hds_low_limit} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="hds_high_limit">HDS Üst Limit</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="hds_high_limit" value={formData.hds_high_limit} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="yer1_inventory_capacity">Yer 1 Stok Alanı Kapasitesi</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="yer1_inventory_capacity" value={formData.yer1_inventory_capacity} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="yer2_inventory_capacity">Yer 2 Stok Alanı Kapasitesi</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="yer2_inventory_capacity" value={formData.yer2_inventory_capacity} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="num_frozen_period">Sabit Tutulan Sipariş Sayısı</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="num_frozen_period" value={formData.num_frozen_period} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="min_lot_size">Minimum Sipariş Adedi</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="min_lot_size" value={formData.min_lot_size} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="par_asma_toplama">Asma Toplama Arası Uzaklık</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="par_asma_toplama" value={formData.par_asma_toplama} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="parEndStockPenalty">Bitiş Stok Cezası</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" step="any" min="0" name="parEndStockPenalty" value={formData.parEndStockPenalty} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="parSafetyUsagePenalty">Güvenlik Stoğu Kullanım Cezası</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" step="any" min="0" name="parSafetyUsagePenalty" value={formData.parSafetyUsagePenalty} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="parLowStockPenalty">HDS Düşük Stok Cezası</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" step="any" min="0" name="parLowStockPenalty" value={formData.parLowStockPenalty} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="parGovdeLowStockPen">Gövde Stok Cezası</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" step="any" min="0" name="parGovdeLowStockPen" value={formData.parGovdeLowStockPen} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="parGovdeOnLowStockPen">Gövde Ön Stok Cezası</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" step="any" min="0" name="parGovdeOnLowStockPen" value={formData.parGovdeOnLowStockPen} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="parChosenStopCoef">Duruş Seçilen Periyod Cezası</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" step="any" min="0" name="parChosenStopCoef" value={formData.parChosenStopCoef} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="parColorChangePenalty">Renk Değişim Katsayısı</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" step="any" min="0" name="parColorChangePenalty" value={formData.parColorChangePenalty} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="parMaterialGroupingPenalty">Renk Gruplama Katsayısı</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" step="any" min="0" name="parMaterialGroupingPenalty" value={formData.parMaterialGroupingPenalty} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
        </div>
        <div>
          <button type="submit" className="bg-smartopt hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10 w-full">Submit</button>

        </div>


      </form>
    </Modal>
  );
};

export default SingularParameterModal;
