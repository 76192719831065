import React, { useContext, useEffect } from "react";
import Table1 from "../Components/ReworkHurda/Table1";
import Table2 from "../Components/ReworkHurda/Table2";
import TableContext from "../Context/TablePosition";
import { useLocation } from "react-router-dom";

export default function ReworkHurda() {

  const { setCurrentUrl } = useContext(TableContext)
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("urlpaintshop", location.pathname)
    setCurrentUrl(localStorage.getItem("urlpaintshop"));
  }, [location.pathname, setCurrentUrl])
  return (
    <div className="flex w-full flex-col h-full mt-4 overflow-auto">
      <div className="flex flex-col desktop:flex-row gap-4">
          <Table1 />
          <Table2 />
      </div>
    </div>
  );
}
