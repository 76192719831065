import React from "react";
import CardItem from "./CardItem";
import BoxShadow from "./BoxShadow";
import { useLocation } from 'react-router-dom';

export default function DataCard({
  cardData = {},
  batchRefreshTime,
  lastHanger,
  alarm
}) {

  let location = useLocation();
  if (location.pathname === "/toplama") {
    return (
      <BoxShadow>
        <div className="grid grid-cols-2 ">
          <div className="col-span-2 ">
            <CardItem data={cardData?.description} title="Sipariş:" />
          </div>

          <CardItem data={lastHanger?.hanger_no ? lastHanger.hanger_no : "Bekleniyor.."} title="Askı Numarası:" />
          <CardItem data={cardData?.fiiliMiktarGovde} title="Fiili:" />
          <CardItem data={(lastHanger?.increased === true && "Arttı") || (lastHanger.increased === undefined && "Bekleniyor..") || (lastHanger.increased === false && "Artmadı")} title="İşlem:" />
          <CardItem data={cardData?.target} title="Planlanan:" />
          <CardItem data={lastHanger?.event ? lastHanger.event : "Bekleniyor.."} title="Durum:" />
          <CardItem
            data={
              cardData?.target -
              cardData?.fiiliMiktarGovde || ""
            }
            title="Kalan"
          />
        </div>
      </BoxShadow>
    )

  }
  return (
    <BoxShadow>
      <div className="grid grid-cols-2 text-[13px]">
        <div className="col-span-2">
          <CardItem data={cardData?.fields?.siparis} title="Sipariş:" />
        </div>

        <CardItem
          data={lastHanger?.hanger_no ? lastHanger.hanger_no : "Bekleniyor.."}
          title="Askı Numarası:"
        />

        <CardItem
          data={cardData?.fields?.actual_production_asma}
          title="Fiili:"
        />
        <CardItem
          data={
            (lastHanger?.increased === true && "Arttı") ||
            (lastHanger?.increased === undefined && "Bekleniyor..") ||
            (lastHanger?.increased === false && "Artmadı")
          }
          title="İşlem:"
        />
        <CardItem data={cardData?.fields?.uretim} title="Planlanan:" />
        <CardItem
          data={lastHanger?.event ? lastHanger.event : "Bekleniyor.."}
          title="Durum:"
        />
        <CardItem
          data={
            cardData?.fields?.uretim -
            cardData?.fields?.actual_production_asma || ""
          }
          title="Kalan"
        />
        <CardItem data={alarm?.passed_hanger_num} title="Artmamış Askı Sayısı:" />

        <div className="col-span-2 flex justify-end font-bold -mt-3 text-base">
          Son Güncellenme Tarihi : {batchRefreshTime?.split(" ")[0]} /{" "}
          {batchRefreshTime?.slice(10, 16) || alarm?.last_refreshed_plan?.slice(0, 16)}
        </div>
      </div>
    </BoxShadow>
  );
}
