import React, { useContext, useState } from "react";
import smartLogo from "../assets/image/smartoptLogo.png";
import bshLogo from "../assets/image/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import { navItems } from "../contants";
import { IoIosArrowDown } from "react-icons/io";
import UserContext from "../Context/UserContext";
import { Button, Drawer } from 'antd';
import { MenuOutlined, RightOutlined } from '@ant-design/icons';

const NavBar = () => {
  let location = useLocation();
  const hiddenNavbar = ["/alarm2"];
  const { user } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setActiveSubMenu(null);
  };

  if (hiddenNavbar.includes(location.pathname)) {
    return null;
  }

  const filteredNavItems = (() => {
    switch (true) {
      case user?.accountDetail?.username === "sap5ib@bosch.com":
        return navItems;
      case user?.accountDetail?.idTokenClaims?.roles[0] === "ps_admin":
        return navItems;
        // return navItems.filter((item) => !(item.title === "Kısıt" || item.title === "Beta"));
      case user?.accountDetail?.idTokenClaims?.roles[0] === "ps_yonetim":
        return navItems.filter((item) => !(item.title === "Asma" || item.title === "Toplama" || item.title === "Rework/Hurda" || item.title === "Govde Ön Stok" || item.title === "Alarm" || item.title === "Alarm2" || item.title === "Kontrol Geçmişi" || item.title === "Arıza Geçmişi" || item.title === "Yardım"));
      case user?.accountDetail?.idTokenClaims?.roles[0] === "ps_planlama":
        return navItems.filter((item) => !(item.title === "Asma" || item.title === "Toplama" || item.title === "Alarm" || item.title === "Alarm2"));
      case user?.accountDetail?.idTokenClaims?.roles[0] === "ps_govde":
        return navItems.filter((item) => !(item.title === "Asma SPV" || item.title === "Toplama" || item.title === "Rework/Hurda" || item.title === "Govde Ön Stok" || item.title === "Alarm2" || item.title === "Vardiya Planı"));
      case user?.accountDetail?.idTokenClaims?.roles[0] === "ps_boyahane":
        return navItems.filter((item) => !(item.title === "Asma" || item.title === "Alarm"));
      default:
        return null;
    }
  })();

  const handleSubMenuClick = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  return (
    <div className="h-[70px] px-5 py-5 flex flex-row justify-between items-center font-bold text-[18px] bg-mainNavbarColor">
      <img src={bshLogo} alt="logo" width="50px" height="40px" />
      <div className="flex largeDesktop:hidden w-full justify-end text-white mr-6">
        <Button onClick={showDrawer} className="flex items-center border-white text-xl">
          <MenuOutlined className="text-white " />
        </Button>
        <div className="dark:text-white row-section gap-10">
          <Drawer
            width={260}
            title="Menü"
            placement="right"
            closable={false}
            onClose={onClose}
            open={open}
            key="right"
          >
            {filteredNavItems && Array.isArray(filteredNavItems) && filteredNavItems.map((item, index) => {
              return (
                <div key={index} className="flex mt-5 text-xl font-sans font-semibold flex-col cursor-pointer">
                  <div onClick={() => handleSubMenuClick(index)} className="flex flex-row justify-between items-center p-0 hover:text-blue-500">
                    {item.menuItems ? (
                      <>
                        <span className="hover:text-blue-500" style={{ padding: "0px" }}>{item.title}</span>
                        <span className="mb-1"><RightOutlined /></span>
                      </>
                    ) : (
                      <NavLink
                        className="hover:text-blue-500"
                        style={{ padding: "0px" }}
                        to={item.link}
                        onClick={onClose}
                      >
                        {item.title}
                      </NavLink>
                    )}
                  </div>
                  {activeSubMenu === index && item.menuItems && (
                    <div className="ml-4 mt-1">
                      {item.menuItems.map((menuItem, menuItemIndex) => (
                        <NavLink
                          to={menuItem.link}
                          key={menuItemIndex}
                          className="flex flex-col mt-2 hover:text-blue-500"
                          onClick={onClose}
                        >
                          - {menuItem.title}
                        </NavLink>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </Drawer>
        </div>
      </div>
      <div className="hidden largeDesktop:flex w-full flex-row items-center gap-4 xlargeDesktop:gap-8 ml-4">
        {filteredNavItems && Array.isArray(filteredNavItems) && filteredNavItems.map((item, index) => {
          return item.menuItems ? (
            <div key={index}>
              <div className="navbar text-white rounded-box p-0">
                <div className="flex justify-end flex-1">
                  <div className="flex items-stretch">
                    <div className="dropdown dropdown-end">
                      <div
                        tabIndex="0"
                        className="flex flex-row items-center gap-1 cursor-pointer"
                        onClick={() => handleSubMenuClick(index)}
                      >
                        {item.title}{" "}
                        <IoIosArrowDown />
                      </div>
                      <ul
                        tabIndex="0"
                        className={`p-2 shadow menu dropdown-content bg-mainNavbarColor rounded-box z-[50] ${activeSubMenu === index ? 'block' : 'hidden'}`}
                      >
                        {item.menuItems.map((data, i) => (
                          <li key={i}>
                            <NavLink
                              className="text-mainWhite whitespace-nowrap hover:text-black hover:bg-white"
                              to={data.link}
                              key={i}
                            >
                              {data.title}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <NavLink
              className="text-white whitespace-nowrap"
              to={item.link}
              key={index}
            >
              {item.title}
            </NavLink>
          );
        })}
        {user?.accountDetail?.idTokenClaims?.roles[0] === "ps_admin" && (
          <a
            className="text-mainWhite"
            target="_blank"
            href="https://bsh.api-boyahane.smartopt.com.tr/admin/"
          >
            Admin
          </a>
        )}
      </div>
      <div>
        <img src={smartLogo} alt="logo" width={110} />
      </div>
    </div>
  );
};

export default NavBar;
