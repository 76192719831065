import React from 'react'

const TableFooter = ({dateTime,showData}) => {

  if(showData) return <div className='h-6'></div>
  return (
    <div className='h-6'>Son Güncellenme Tarihi :  {dateTime}</div>
  )
}

export default TableFooter