import { useEffect, useState } from "react";
import { Table, DatePicker, Button } from "antd";
import axios, { all } from "axios";
import { apiUrl } from "../config";
import ChangeReasonModal from "./ModalComponent/ChangeReasonModal";
import { ReloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Excel } from "antd-table-saveas-excel";

export default function ControlHistoryTable() {
    const [modifiedData, setModifiedData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [archiveDate, setArchiveDate] = useState("")
    const [reasonData, setReasonData] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_SECRET_KEY;

    const getData = () => {
        setLoading(true)
        axios.get(`${apiUrl}/getOrderControlHistory`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then((response) => setAllData(response.data))
            .catch((error) => console.log(error))
        setLoading(false)
    }
    const getDataReason = () => {
        setLoading(true)
        axios.get(`${apiUrl}/getOrderControlReasons`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then((response) => setReasonData(response.data))
            .catch((error) => console.log(error))
        setLoading(false)
    }

    const getDataArchive = () => {
        setLoading(true)
        axios.get(`${apiUrl}/getOrderControlHistoryAtDate/${(archiveDate)}`, {
            auth: {
                username: username,
                password: password
            }
        })
            .then((response) => setAllData(response.data))
            .catch((error) => console.log(error))
        setLoading(false)
    }

    useEffect(() => {
        setAllData([])
        getDataReason()
        if (archiveDate) getDataArchive()
        else getData();
    }, [archiveDate]);

    useEffect(() => {
        const dataList = allData?.map((item, index) => ({
            key: index + 1,
            change_info: item?.change_info,
            siparis: item?.effected_order?.siparis,
            created_at: item?.created_at,
            order_no: item?.effected_order?.order_nr,
            created_by: item?.created_by,
            id: item?.id,
            order_control_reason: item?.order_control_reason?.order_control_reason

        }));

        setModifiedData([...dataList]);
    }, [allData]);

    const handleClick = () => {
        const excel = new Excel();
        let newColumns = columns.filter((column) => (
            column.title !== "Sebep" &&
            column.title !== "Değişiklik Bilgisi"


        ))
        newColumns.push({ title: "Sebep", dataIndex: "order_control_reason" })
        newColumns.push({ title: "Değişiklik Bilgisi", dataIndex: "change_info" })
        excel
            .addSheet("Sheet1")
            .addColumns(newColumns)
            .addDataSource(modifiedData, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };


    const handleTableChange = (pagination, filters, sorter, currentTable) => {
    };
    const onChange = (date, dateString) => {
        setArchiveDate(dateString);
    };

    const columns = [
        {
            title: "Saat",
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            width: 120,
            render: (record, row) => {
                return row?.created_at?.slice(11, 16)
            }
        },
        {
            title: "Sipariş Numarası",
            dataIndex: "order_no",
            key: "order_no",
            align: "center",
            width: 130,
        },
        {
            title: "Sipariş",
            dataIndex: "siparis",
            key: "siparis",
            align: "center",
            width: 200,
        },
        {
            title: "Değişiklik Bilgisi",
            dataIndex: "change_info",
            key: "change_info",
            align: "center",
            width: 200,
            render: (record, row) => {
                return <div className="flex flex-col">
                    <div>
                        {row?.change_info.split("Period:")[0]}
                    </div>
                    <div>
                        Period: {row?.change_info.split("Period:")[1]}
                    </div>
                </div>
            }
        },
        {
            title: "Kullanıcı",
            dataIndex: "created_by",
            key: "created_by",
            align: "center",
            width: 200,
        },
        {
            title: "Sebep",
            dataIndex: "order_control_reason",
            key: "order_control_reason",
            align: "center",
            width: 200,
            render: (record, row) => {
                return <div className="flex flex-col gap-2 items-center">
                    <p>{row.order_control_reason}</p>
                    <Button
                        onClick={(e) => {
                            setSelectedRow(row);
                            setModalOpen(true);
                        }}
                        style={{ fontFamily: "sans-serif" }}
                        className="w-max flex items-center justify-center"
                    >
                        {row.order_control_reason === undefined ? <PlusOutlined /> : <ReloadOutlined />}
                    </Button>


                </div>
            }
        },

    ];

    return (
        <>
            <ChangeReasonModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                selectedRow={selectedRow}
                allData={allData}
                reasonData={reasonData}
                getData={getData}
                getDataArchive={getDataArchive}
                archiveDate={archiveDate}
            />
            <div className="flex flex-col gap-2 overflow-auto">
                <div className="text-center p-2 shadow-md bg-labelColor font-bold rounded-md justify-between flrx flex-col " >
                    <h2 className="text-lg" >Plandaki Manuel Değişimler Tablosu</h2>
                    <div><DatePicker onChange={onChange} placeholder="Tarih seçiniz" /></div>
                    <Button className="bg-white w-36 mt-[-12px] flex items-center justify-center mb-1" onClick={handleClick}>Excel İndir</Button>

                    <Table
                        bordered
                        sticky
                        loading={loading}
                        dataSource={modifiedData}
                        rowClassName={(record, index) => {
                            return record.is_started_asma === true ||
                                record.is_completed_asma === true
                                ? "table-first-item table-height"
                                : index % 2 === 0
                                    ? "table-row-light table-height"
                                    : "table-row-dark table-height";
                        }}

                        onChange={handleTableChange}
                        columns={columns}
                        pagination={{ pageSize: 50 }}
                        scrollToFirstRowOnChange={true}
                        scroll={{ x: 1000 }}

                    />
                </div>
            </div>
        </>
    );
}
