import React, { useContext, useEffect } from "react";
import { Modal } from "antd";
import { useState } from "react";
import ModalHeaderLayout from "../../../layouts/ModalHeaderLayout";
import UserContext from "../../../Context/UserContext";
import axios from "axios";
import { apiUrl } from "../../../config";

const BodyTypeModal = ({
  modalOpen,
  setModalOpen,
  selectedRow,
  allData,
  rule,
  setRefreshPage,
  refreshPage
}) => {
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;

  const [formData, setFormData] = useState({
    max_consecutive_production_periods: "",
    description: "",
  });

  useEffect(() => {
    if (selectedRow && rule === "changeOrder") {
      setFormData({
        max_consecutive_production_periods: selectedRow.max_consecutive_production_periods || "",
        description: selectedRow.description || "",
      });
    } else {
      setFormData({
        max_consecutive_production_periods:  "",
        description:  "",
      });
     
    }
  }, [selectedRow, modalOpen]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestMethod = rule === "changeOrder" ? axios.put : axios.post;
    let requestData;
    if (rule === "changeOrder") {
      requestData = { ...formData, id: selectedRow.id };
    }
   
    if (rule === "newOrder") {
      requestData = formData;
    }
    
    requestMethod(`${apiUrl}${window.location.pathname === "/beta-screen" ? "/beta" : ""}/addBodyMaterialType`,
      requestData, {
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`
      }
    }).then(res => {
      if (res.status === 200) {
        setFormData({
          max_consecutive_production_periods: selectedRow.max_consecutive_production_periods ,
          description: selectedRow.description ,
        });
        setModalOpen(false)
        setRefreshPage(!refreshPage)
      }
    })
      .catch(err => console.log(err))
  }
  return (
    <Modal
      title=<ModalHeaderLayout title={rule === "changeOrder" ? "Gövde Tipi Değişikliği" : "Gövde Tipi Ekle"} />
      open={modalOpen}
      footer={null}
      onOk={() => setModalOpen(false)}
      onCancel={() => {
        setModalOpen(false);
      }}
      centered
      className="w-60 mb-40"
    >
      <form className='bg-white p-4 rounded-2xl shadow-2xl' onSubmit={handleSubmit}>
       
        <div className='grid laptop:grid-cols-2 desktop:grid-cols-3 largeDesktop:grid-cols-4 gap-7 min-w-max'>
          <div>
            <label htmlFor="description">Gövde Tipi</label>
            <input onChange={(e) => handleChange(e)} type="text" name="description" value={formData.description} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
          <div>
            <label htmlFor="max_consecutive_production_periods">Maksimum Art Arda Üretim Periyodu:</label>
            <input onChange={(e) => handleChange(e)} onWheel={(e) => e.target.blur()} type="number" min="0" name="max_consecutive_production_periods" value={formData.max_consecutive_production_periods} className="bg-gray-200 rounded h-8 block w-full mt-1" />
          </div>
        </div>
        <div>
          <button type="submit" className="bg-smartopt hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10 w-full">Submit</button>

        </div>


      </form>
    </Modal>
  );
};

export default BodyTypeModal;
