import { useEffect, useState } from "react";
import { Table } from "antd";
import { baseUrl } from "../../config";

export default function Table1() {
  const [modifiedData, setModifiedData] = useState([]);
  const [allData1, setAllData1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [socket1, setSocket1] = useState(null);
  useEffect(() => {
    const handleOnline = () => {
      console.log("Yeniden Bağlanıyor...");
      const ws = new WebSocket(`${baseUrl}/ws/frontbodycache-socket-server/`);
      setSocket1(ws);
    };

    const handleOffline = () => {
      console.log("Çevrimdışı - WebSocket bağlantısı kapatıldı.");
      if (socket1) {
        socket1.close();
      }
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [socket1]);

  useEffect(() => {
    const ws = new WebSocket(
      `${baseUrl}/ws/frontbodycache-socket-server/`

    );
    setSocket1(ws);

    return () => {
      console.log("close home socket");
      ws.close();
    };
  }, []);

  useEffect(() => {

    if (socket1) {
      socket1.onopen = function (event) {
        console.log("Socket opened");
      };
    }
  }, []);

  useEffect(() => {
    let timer;

    if (socket1) {
      socket1.onmessage = function (e) {
        let newData = JSON.parse(e?.data);


        setAllData1(
          newData?.data
        );

      };
      timer = setInterval(() => {
        if (socket1.readyState === WebSocket.OPEN) {
          console.log("send HealthCheck");
          socket1.send(
            JSON.stringify({
              event_type: "HealthCheck",
            })
          );
        } else if (socket1.readyState === WebSocket.CLOSED) {
          console.log("Socket is closed. Reopening...");
          // Yeniden bağlantı kurma işlemini başlat
          const newSocket = new WebSocket(`${baseUrl}/ws/frontbodycache-socket-server/`);
          setSocket1(newSocket);
        }
      }, 8000);
      return () => clearInterval(timer);
    }
  }, [socket1]);

  useEffect(() => {
    const dataList = allData1?.map((item, index) => ({
      key: index + 1,
      malzeme_tanim: item?.fields?.siparis,
      created_at: item?.fields?.created_at,

    }));

    setModifiedData([...dataList]);
  }, [allData1]);
  const handleTableChange = (pagination, filters, sorter, currentTable) => {
    // console.log('Various parameters', pagination, filters, sorter);
    // setFilteredInfo(filters);
    // setSortedInfo(sorter);
    // setCurrentTable(currentTable?.currentDataSource)
  };

  //* table column başlıkları
  const columns = [

    {
      title: "Rework",
      dataIndex: "malzeme_tanim",
      key: "malzeme_tanim",
      align: "center",
      width: 180,
    },
    // {
    //   title: "Tarih",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   align: "center",
    //   width: 180,
    //   render: (record, row) => {
    //     return row.created_at && row.created_at.slice(0, 10) + " " + row?.created_at?.slice(11, 16)

    //   },

    // },

  ];

  return (
    <div className="flex flex-col gap-2">
      <h2 className="text-center p-2 shadow-md bg-labelColor font-bold rounded-md" >Bekleyen Reworkler</h2>

      <Table
        // className="table-main1"
        bordered
        sticky
        loading={loading}
        dataSource={modifiedData}
        rowClassName={(record, index) => {
          return record.is_started_asma === true ||
            record.is_completed_asma === true
            ? "table-first-item table-height"
            : index % 2 === 0
              ? "table-row-light table-height"
              : "table-row-dark table-height";
        }}
      
        onChange={handleTableChange}
        columns={columns}
        pagination={{ pageSize: 50 }}
      />
    </div>
  );
}
