import { useEffect, useState } from "react";
import { Table } from "antd";
import TableFooter from "./TableFooter";
import { apiUrl } from "../config";
import axios from "axios";
import { CheckCircleTwoTone } from '@ant-design/icons';

export default function SafetyStock() {
  const [modifiedData, setModifiedData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true); 
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_SECRET_KEY;

  const getData = () => {
    axios.get(`${apiUrl}/getShortBSHProdResults`, {
      auth: {
        username: username,
        password: password
      }
    })
      .then((response) => {
        setAllData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const dataList = allData?.map((item, index) => ({
      key: index + 1,
      date: item?.date,
      period: item?.period,
      tanim: item?.tanim,
      safety_used: item?.safety_used,
      batch_date: item?.order_batch?.batch_date,
      work_in_process: item?.work_in_process
    }));

    setModifiedData([...dataList]);
  }, [allData]);

  const handleTableChange = (pagination, filters, sorter, currentTable) => {
  };

  const columns = [
    {
      title: "Tarih",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 80,
    },
    {
      title: "Saat",
      dataIndex: "period",
      key: "period",
      align: "center",
      width: 100,
    },
    {
      title: "Tanım",
      dataIndex: "tanim",
      key: "tanim",
      align: "center",
      width: 100,
    },
    {
      title: "Kullanılan Emniyet Stoğu",
      dataIndex: "safety_used",
      key: "safety_used",
      align: "center",
      width: 80,
    },
    {
      title: "Kalan Emniyet Stoğu",
      dataIndex: "work_in_process",
      key: "work_in_process",
      align: "center",
      width: 80,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <h2 className="text-center p-2 shadow-md bg-labelColor font-bold rounded-md">
        Emniyet Stok Kullanım Bilgisi
      </h2>

      <Table
        bordered
        sticky
        dataSource={modifiedData}
        rowClassName={(record, index) => {
          return index % 2 === 0
            ? "table-row-light table-height"
            : "table-row-dark table-height";
        }}
        onChange={handleTableChange}
        columns={columns}
        pagination={{ pageSize: 100 }}
        locale={{
          emptyText: loading ? "Loading..." : (
            <div className="text-black flex flex-row justify-center gap-2">
              <div className="flex items-center">
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </div>
              <p>Emniyet stoklarının kullanımına ihtiyaç yoktur</p>
            </div>
          ),
        }}
        footer={() => (
          <TableFooter
            dateTime={
              allData?.length !== 0 ?
              allData
                ?.map((item) => item?.order_batch?.batch_date)[0]
                ?.slice(0, 10) +
              " " +
              allData?.map((item) => item?.order_batch?.batch_date)[0]?.slice(11, 16)
              : "Boş"
            }
          />
        )}
        loading={loading}
      />
    </div>
  );
}
