import { useContext, useEffect, useState } from "react";
import { Table, Button, Popover } from "antd";
import { Excel } from "antd-table-saveas-excel";
import TopBar from "../Components/TopBar";
import DataCard from "../Components/DataCard";
import ChangeTargetModal from "../Components/ModalComponent/ChangeTargetModal";
import TabbarDataCardLayout from "../layouts/TabbarDataCardLayout";
import { baseUrl } from "../config";
import UserContext from "../Context/UserContext";
import TableContext from "../Context/TablePosition";
import { useLocation } from "react-router-dom";

export default function Asma() {
  const [modifiedData, setModifiedData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [faultTitle, setFaultTitle] = useState("");
  const [completed, setCompleted] = useState(0);
  const [alarm, setAlarm] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [batchRefreshTime, setBatchRefreshTime] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const [isModalOpenTarget, setIsModalOpenTarget] = useState(false);
  const [lastHanger, setLastHanger] = useState({});
  const [socket, setSocket] = useState(null);
  const { user } = useContext(UserContext);
  const { setCurrentUrl } = useContext(TableContext);
  const location = useLocation();
  const [wsStatus, setWsStatus] = useState(true);

  useEffect(() => {
    localStorage.setItem("urlpaintshop", location.pathname);
    setCurrentUrl(localStorage.getItem("urlpaintshop"));
  }, [location.pathname, setCurrentUrl]);

  useEffect(() => {
    const handleOnline = () => {
      console.log("Yeniden Bağlanıyor...");
      const ws = new WebSocket(`${baseUrl}/ws/hang-socket-server/`);
      setSocket(ws);
      setWsStatus(true);
    };

    const handleOffline = () => {
      console.log("Çevrimdışı - WebSocket bağlantısı kapatıldı.");
      if (socket) {
        socket.close();
        setWsStatus(false);
      }
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [socket]);

  useEffect(() => {
    const ws = new WebSocket(`${baseUrl}/ws/hang-socket-server/`);
    setSocket(ws);

    return () => {
      console.log("close home socket");
      ws.close();
    };
  }, []);

  useEffect(() => {
    allData.length > 0 &&
      allData.find((item, index) => {
        if (item.fields.is_completed_asma === true) {
          setCompleted(index + 1);
          return true;
        }
        return false;
      });
    alarm.type === "Alarm" && setIsModalOpen(true);
  }, [allData, alarm.type]);

  useEffect(() => {
    if (socket) {
      socket.onopen = function () {
        console.log("Socket opened");
      };

      socket.onclose = function () {
        console.log("Socket closed. Attempting to reconnect...");
        const ws = new WebSocket(`${baseUrl}/ws/hang-socket-server/`);
        setSocket(ws);
      };

      socket.onmessage = function (e) {
        let newData = JSON.parse(e.data);
        setFaultTitle(newData.system_status);
        setAllData(newData?.data?.filter(item => !item?.fields?.is_completed_asma));
        setLastHanger(newData.last_hanger);
        setAlarm(newData);
        setBatchRefreshTime(newData.last_refreshed);

        let actualProduction = newData?.data[completed]?.fields?.actual_production;
        let govdeUretim = newData?.data[completed]?.fields?.uretim;

        if (actualProduction >= govdeUretim) {
          socket.send(
            JSON.stringify({
              event_type: "CompleteEvent",
              add_to_next: false,
              user: user.accountDetail.name,
            })
          );
        }
      };

      const timer = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) {
          console.log("send HealthCheck");
          socket.send(JSON.stringify({ event_type: "HealthCheck" }));
        } else if (socket.readyState === WebSocket.CLOSED) {
          console.log("Socket is closed. Reopening...");
          const newSocket = new WebSocket(`${baseUrl}/ws/hang-socket-server/`);
          setSocket(newSocket);
        }
      }, 8000);

      return () => clearInterval(timer);
    }
  }, [socket, completed, user.accountDetail.name]);

  useEffect(() => {
    const dataList = allData?.map((item, index) => ({
      key: index + 1,
      orderNo: Math.floor(item?.fields?.order_nr),
      spvNo: item?.fields?.sequence,
      date: item?.fields?.date,
      time: item?.fields?.period,
      description: item?.fields?.siparis,
      target: item?.fields?.uretim,
      fiiliMiktar: item?.fields?.actual_production_asma,
      is_completed_asma: item?.fields?.is_completed_asma,
      is_started_asma: item?.fields?.is_started_asma,
    }));

    setModifiedData([...dataList]);
  }, [allData]);

  const handleTableChange = (pagination, filters, sorter) => {
    // Custom table change logic if needed
  };

  const columns = [
    {
      title: "SmartOpt Sipariş Numarası",
      dataIndex: "orderNo",
      key: "orderNo",
      align: "center",
      width: 120,
    },
    {
      title: "Spv No",
      dataIndex: "spvNo",
      key: "spvNo",
      width: 90,
      align: "center",
    },
    {
      title: "Saat",
      dataIndex: "time",
      key: "time",
      align: "center",
      width: 100,
    },
    {
      title: "Sipariş İçeriği",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: 180,
    },
    {
      title: "Hedef",
      dataIndex: "target",
      key: "target",
      align: "center",
      width: 150,
      render: (text, row) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            gap: "20px",
          }}
        >
          <p style={{ flex: 1, textAlign: "center" }}>{row.target}</p>
          <Button
            onClick={() => {
              setSelectedRow(row);
              setIsModalOpenTarget(true);
            }}
            style={{ fontFamily: "sans-serif" }}
          >
            Değiştir
          </Button>
        </div>
      ),
    },
    {
      title: "Fiili Miktar",
      dataIndex: "fiiliMiktar",
      key: "fiiliMiktar",
      align: "center",
      width: 100,
    },
  ];

  const handleClickExcel = () => {
    const excel = new Excel();
    let newColumns = columns.filter(
      (column) => column.title !== "Spv No" && column.title !== "Sayaç"
    );

    newColumns.push({ title: "Spv No", dataIndex: "spvNo" });
    newColumns.push({ title: "Sayaç", dataIndex: "sayac" });

    const newData = modifiedData.filter((item) => !item.count);

    excel
      .addSheet("test")
      .addColumns(newColumns)
      .addDataSource(modifiedData, {
        str2Percent: true,
      })
      .saveAs("Excel.xlsx");
  };

  return (
    <div className="flex w-full flex-col h-full">
      <ChangeTargetModal
        isModalOpenTarget={isModalOpenTarget}
        setIsModalOpenTarget={setIsModalOpenTarget}
        socket={socket}
        selectedRow={selectedRow}
        allData={allData}
      />
      <TabbarDataCardLayout>
        <TopBar
          handleClickExcel={handleClickExcel}
          socket={socket}
          faultTitle={faultTitle}
          modifiedData={modifiedData}
          cardData={modifiedData.find((item) => item.is_started_asma === true)}
          setRefresh={setRefresh}
          alarm={alarm}
        />
        <DataCard
          cardData={allData?.find(
            (item) =>
              item.fields.is_started_asma === true &&
              item.fields.is_completed_asma === false
          )}
          counter={allData[completed]?.fields?.actual_production}
          batchRefreshTime={batchRefreshTime}
          alarm={alarm}
          lastHanger={lastHanger}
        />
      </TabbarDataCardLayout>
      <div className="flex justify-end gap-5">
        <Popover content={`Sistem Durumu: ${wsStatus ? "Aktif" : "Aktif Değil"}`}>
          <div
            className={`flex mt-[-10px] mr-4 w-6 h-6 rounded-full ${
              wsStatus ? 'bg-green-500' : 'bg-red-500'
            } ${wsStatus ? 'shadow-green-700 shadow-md' : 'shadow-red-700 shadow-md'}`}
          ></div>
        </Popover>
      </div>
      <div className="tableAsma flex h-full overflow-auto w-full mt-2">
        <Table
          bordered
          sticky
          dataSource={modifiedData}
          rowClassName={(record, index) =>
            record.is_started_asma === true || record.is_completed_asma === true
              ? "table-first-item table-height"
              : index % 2 === 0
              ? "table-row-light table-height"
              : "table-row-dark table-height"
          }
          onChange={handleTableChange}
          columns={columns}
          scroll={{ x: 700 }}
          pagination={{ pageSize: 6 }}
          scrollToFirstRowOnChange={true}
        />
      </div>
    </div>
  );
}
