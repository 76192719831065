import React, { useContext, useEffect, useState } from "react";
import { Liquid } from "@ant-design/plots";
import Energy1 from "../assets/image/energy1.png"
import Energy2 from "../assets/image/energy2.png"
import Energy3 from "../assets/image/energy3.png"
import Energy4 from "../assets/image/energy4.png"

import Onsac from "../assets/image/onSac.png";
import Govde from "../assets/image/govde.png";
import { baseUrl } from "../config";
import TableContext from "../Context/TablePosition";
import { useLocation } from "react-router-dom";

const NewDashboard = () => {

  const [socket, setSocket] = useState(null);
  const [govdeOnStok, setGovdeOnStok] = useState({ F14: "", F23: "" });
  const [govdeStok, setGovdeStok] = useState({});
  const [colorChangeTime, setColorChangeTime] = useState("");
  const [plannedStopTime, setPlannedStopTime] = useState("");
  const [reworkTime, setReworkTime] = useState("");
  const [govdeChangeTime, setGovdeChangeTime] = useState("");
  const [vardiyalikMontajTalebiVeUretim, setVardiyalikMontajTalebiVeUretim] = useState("");
  const { setCurrentUrl } = useContext(TableContext)
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("urlpaintshop", location.pathname)
    setCurrentUrl(localStorage.getItem("urlpaintshop"));
  }, [location.pathname, setCurrentUrl])

  useEffect(() => {
    const handleOnline = () => {
      console.log("Yeniden Bağlanıyor...");
      const ws = new WebSocket(`${baseUrl}/ws/dashboard-socket-server/`);
      setSocket(ws);
    };

    const handleOffline = () => {
      console.log("Çevrimdışı - WebSocket bağlantısı kapatıldı.");
      if (socket) {
        socket.close();
      }
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [socket]);

  useEffect(() => {
    const ws = new WebSocket(
      `${baseUrl}/ws/dashboard-socket-server/`
    );
    setSocket(ws);

    return () => {
      console.log("dashboard socket closed");
      ws.close();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.onopen = function (event) {
        console.log("Socket opened");
      };
      socket.onmessage = (e) => {
        let newData = JSON.parse(e.data);
        switch (newData.type) {
          case "Govde_On_Stok":
            return setGovdeOnStok({
              F14: newData.data.F14,
              F23: newData.data.F23,
            });
          case "Govde_Stok":
            return setGovdeStok({
              ...newData.data,
            });
          case "Color_Change_Time":
            return setColorChangeTime(newData.data);
          case "Planned_Stop_Time":
            return setPlannedStopTime(newData.data);
          case "Rework_Time":
            return setReworkTime(newData.data);
          case "Govde_Change_Time":
            return setGovdeChangeTime(newData.data);
          case "Shift_Demand_Prod":
            return setVardiyalikMontajTalebiVeUretim({ ...newData.data });
          default:
            return null;
        }
      };
    }
  }, [socket]);

  const config = {

    percent: 0.25,
    outline: {
      border: 2,
      distance: 0.6,
    },
    wave: {
      length: 18,
    },

    statistic: {
      content: {
        style: {
          fontSize: "18px",
          lineHeight: "20px",
          color: "#000",
        },
        formatter: () => "%25",
      },
    },
  };


  const config1 = {
    percent: 0.25,
    outline: {
      border: 2,
      distance: 0.6,
      color: "#fff"
    },
    wave: {
      length: 18,
    },
    liquidStyle: ({ percent }) => ({
      fill: '#FAAD14',
      // stroke: percent > 0.45 ? '#5B8FF9' : '#FAAD14'
    }),
    statistic: {
      content: {
        style: {
          fontSize: "18px",
          lineHeight: "20px",
          color: "#fff",
        },
        formatter: () => "%25",
      },
    },
  };

  const config2 = {
    percent: 0.25,
    outline: {
      border: 2,
      distance: 0.6,
      color: "#fff"
    },
    wave: {
      length: 18,
    },
    // liquidStyle: ({ percent }) => ({
    //   fill: percent > 0.45 ? '#5B8FF9' : '#FAAD14',
    //   stroke: percent > 0.45 ? '#5B8FF9' : '#FAAD14'
    // }),
    liquidStyle: ({ percent }) => ({
      fill: '#FAAD14',
      // stroke: percent > 0.45 ? '#5B8FF9' : '#FAAD14'
    }),

    statistic: {
      content: {
        style: {
          fontSize: "18px",
          lineHeight: "20px",
          color: "#fff",
        },
        formatter: () => "%25",
      },
    },
  };

  return (
    <div className="bg-stone-900 w-full h-full flex flex-col p-3 gap-4">
      <div className="w-full flex flex-row gap-3 h-3/5">
        <div className="gap-1 w-full h-full text-white bg-slate-50">
          <div className="grid text-center items-center h-[8%] bg-smartopt font-bold  text-lg largeDesktop:text-xl">
            Makine
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-400 opacity-100 dark:opacity-50" />
          <div className="flex flex-row justify-start text-center items-center h-[23%] largeDesktop:gap-5">
            <img src={Govde} alt="govde" className="w-36" />
            <p className="text-black font-extrabold largeDesktop:text-2xl">U Gövde</p>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-400 opacity-100 dark:opacity-50" />
          <div className="flex flex-row justify-start text-center items-center h-[23%] largeDesktop:gap-5">
            <img src={Govde} alt="govde" className="w-36" />
            <p className="text-black font-extrabold largeDesktop:text-2xl">F14 Gövde</p>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-400 opacity-100 dark:opacity-50" />
          <div className="flex flex-row justify-start text-center items-center h-[23%] gap-5">
            <img src={Onsac} alt="govde" className="w-20 largeDesktop:w-24  ml-10" />
            <p className="text-black font-extrabold largeDesktop:text-2xl">F14 Ön-Sac</p>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-400 opacity-100 dark:opacity-50" />
          <div className="flex flex-row justify-start text-center items-center h-[23%] gap-5">
            <img src={Onsac} alt="govde" className="w-20 largeDesktop:w-24  ml-10" />
            <p className="text-black font-extrabold largeDesktop:text-2xl">F23 Ön-Sac</p>
          </div>
        </div>
        <div className="w-full h-full gap-1 bg-white bg-opacity-90">
          <div className="text-center items-center h-[8%] grid text-white bg-smartopt font-bold text-lg largeDesktop:text-xl">
            Beyaz
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-500 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center">
            <div className="items-center h-[67px] largeDesktop:h-[95px] text-center">
              <Liquid {...config} />
            </div>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-500 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center ">
            <div className="items-center h-[67px] largeDesktop:h-[95px]">
              <Liquid {...config} />
            </div>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-500 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center ">
            <div className="items-center h-[67px] largeDesktop:h-[95px]">
              <Liquid {...config} />
            </div>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-500 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center">
            <div className="items-center h-[67px] largeDesktop:h-[95px]">
              <Liquid {...config} />
            </div>
          </div>
        </div>
        <div className="w-full h-full gap-1  bg-white bg-opacity-60">
          <div className="text-center items-center h-[8%] grid text-white bg-smartopt font-bold text-lg largeDesktop:text-xl">
            Gri
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center">
            <div className="items-center h-[67px] largeDesktop:h-[95px] text-center">
              <Liquid {...config1} />
            </div>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center ">
            <div className="items-center h-[67px] largeDesktop:h-[95px]">
              <Liquid {...config1} />
            </div>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center ">
            <div className="items-center h-[67px] largeDesktop:h-[95px]">
              <Liquid {...config1} />
            </div>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center">
            <div className="items-center h-[67px] largeDesktop:h-[95px]">
              <Liquid {...config1} />
            </div>
          </div>
        </div>
        <div className="w-full h-full gap-1 bg-white bg-opacity-50">
          <div className="text-center items-center h-[8%] grid text-white bg-smartopt font-bold text-lg largeDesktop:text-xl">
            Cast Iron
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center">
            <div className="items-center h-[67px] largeDesktop:h-[95px] text-center">
              <Liquid {...config2} />
            </div>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center ">
            <div className="items-center h-[67px] largeDesktop:h-[95px]">
              <Liquid {...config2} />
            </div>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center ">
            <div className="items-center h-[67px] largeDesktop:h-[95px]">
              <Liquid {...config2} />
            </div>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center">
            <div className="items-center h-[67px] largeDesktop:h-[95px]">
              <Liquid {...config2} />
            </div>
          </div>
        </div>
        <div className="w-full h-full gap-1  bg-green-300 bg-opacity-60">
          <div className="text-center items-center h-[8%] grid text-white bg-smartopt font-bold text-lg largeDesktop:text-xl">
            Kazanımlar
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center">
            <div className="items-center flex h-[67px] largeDesktop:h-[95px] text-center justify-center gap-5">
              <img src={Energy2} alt="energy" className=" w-32 largeDesktop:w-40 " />
              <p className=" text-lg largeDesktop:text-3xl font-extrabold text-white	">%25</p>
            </div>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center ">
            <div className="items-center flex h-[67px] largeDesktop:h-[95px] text-center justify-center gap-5">
              <img src={Energy2} alt="energy" className=" w-32 largeDesktop:w-40 " />
              <p className=" text-lg largeDesktop:text-3xl font-extrabold text-white	">%25</p>
            </div>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center ">
            <div className="items-center flex h-[67px] largeDesktop:h-[95px] text-center justify-center gap-5">
              <img src={Energy2} alt="energy" className=" w-32 largeDesktop:w-40 " />
              <p className=" text-lg largeDesktop:text-3xl font-extrabold text-white	">%25</p>
            </div>
          </div>
          <hr className="h-[0.3px] border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
          <div className="h-[23%] grid items-center">
            <div className="items-center flex h-[67px] largeDesktop:h-[95px] text-center justify-center gap-5">
              <img src={Energy2} alt="energy" className=" w-32 largeDesktop:w-40 " />
              <p className=" text-lg largeDesktop:text-3xl font-extrabold text-white	">%25</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-1/5 flex flex-row gap-3">
        <div className="gap-1 w-[14%] text-white border border-slate-500 shadow-md	shadow-slate-400 flex flex-col text-center items-center bg-slate-600">
          <h3 className="h-[20%] bg-smartopt w-full grid items-center font-bold">
            Vardiyalık Montaj Talebi
          </h3>
          <p className="h-[90%] text-center items-center flex text-5xl font-bold">
            {Math.floor(vardiyalikMontajTalebiVeUretim.shift_dem) || ""}
          </p>
        </div>
        <div className="gap-1 w-[14%] text-white border border-slate-500 shadow-md	shadow-slate-400 flex flex-col text-center items-center bg-slate-600">
          <h3 className="h-[20%] bg-smartopt w-full grid items-center font-bold">Vardiyalık Üretim</h3>
          <p className="h-[90%] text-center items-center flex text-5xl font-bold">
            {Math.floor(vardiyalikMontajTalebiVeUretim.shift_prod) || ""}
          </p>
        </div>
        <div className="gap-1 w-[30%] text-white border border-slate-500 shadow-md 	shadow-slate-400 flex flex-col text-center items-center bg-slate-600">
          <h3 className="h-[20%] bg-smartopt w-full grid items-center font-bold">Stok Seviyesi Gövde</h3>
          <div className="h-[90%] flex flex-row w-full gap-2 p-px">
            <div className="gap-1 w-1/3 text-white border border-slate-500  shadow-inner	shadow-slate-400 flex flex-col text-center items-center bg-slate-600">
              <h3 className="h-[27%] bg-smartopt w-full grid items-center font-bold">HDS</h3>
              <p className="h-[90%] text-center items-center flex text-4xl font-bold">
                {govdeStok?.hds_stok}
              </p>
            </div>
            <div className="gap-1 w-1/3 text-white border border-slate-500 shadow-inner	shadow-slate-400 flex flex-col text-center items-center bg-slate-600">
              <h3 className="h-[27%] bg-smartopt w-full grid items-center font-bold">YER 1</h3>
              <p className="h-[90%] text-center items-center flex text-4xl font-bold">
                {govdeStok?.yer_stok1}
              </p>
            </div>
            <div className="gap-1 w-1/3 text-white border border-slate-500 shadow-inner	shadow-slate-400 flex flex-col text-center items-center bg-slate-600">
              <h3 className="h-[27%] bg-smartopt w-full grid items-center font-bold">YER 2</h3>
              <p className="h-[90%] text-center items-center flex text-4xl font-bold">
                {govdeStok?.yer_stok2}
              </p>
            </div>
          </div>
        </div>
        <div className="gap-1 w-[30%] text-white border border-slate-500 shadow-md 	shadow-slate-400 flex flex-col text-center items-center bg-slate-600">
          <h3 className="h-[20%] bg-smartopt w-full grid items-center font-bold">Stok Seviyesi Ön Sac</h3>
          <div className="h-[90%] flex flex-row w-full gap-2 p-px">
            <div className="gap-1 w-1/2 text-white border border-slate-500  shadow-inner	shadow-slate-400 flex flex-col text-center items-center bg-slate-600">
              <h3 className="h-[30%] bg-smartopt w-full grid items-center font-bold">F14</h3>
              <p className="h-[90%] text-center items-center flex text-4xl font-bold">
                {govdeOnStok?.F14}
              </p>
            </div>
            <div className="gap-1 w-1/2 text-white border border-slate-500 shadow-inner	shadow-slate-400 flex flex-col text-center items-center bg-slate-600">
              <h3 className="h-[30%] bg-smartopt w-full grid items-center font-bold">F23</h3>
              <p className="h-[90%] text-center items-center flex text-4xl font-bold">
                {govdeOnStok?.F23}
              </p>
            </div>
          </div>
        </div>
        <div className="gap-1 w-[12%] text-white border border-slate-500 shadow-md	shadow-slate-400 flex flex-col text-center items-center bg-green-300 bg-opacity-60">
          <h3 className="h-[20%] bg-smartopt w-full grid items-center font-bold">
            Toplam Kazanım
          </h3>
          <div className="flex flex-row text-center items-center justify-center mt-5 gap-3 largeDesktop:mt-8">
            <img src={Energy2} alt="energy" className=" w-20 largeDesktop:w-32 " />
            <p className="flex largeDesktop:text-3xl">%25</p>
          </div>

        </div>
      </div>
      <div className="w-full h-1/5 flex flex-row gap-3">
        <div className="gap-1 w-1/4 text-white border border-slate-500 shadow-md	shadow-slate-400 flex flex-col text-center items-center bg-green-500">
          <p className="h-[90%] text-center items-center flex text-4xl font-bold ">
            Arıza Yok
          </p>
        </div>
        <div className="gap-1 w-1/4 text-white border border-slate-500 shadow-md	shadow-slate-400 flex flex-col text-center items-center bg-slate-600">
          <h3 className="h-[30%] bg-smartopt w-full grid items-center font-bold">İlk Renk Değişimi</h3>
          <p className="h-[90%] text-center items-center flex text-5xl font-bold">
            {colorChangeTime.toString()}
          </p>
        </div>
        <div className="gap-1 w-1/4 text-white border border-slate-500 shadow-md	shadow-slate-400 flex flex-col text-center items-center bg-slate-600">
          <h3 className="h-[30%] bg-smartopt w-full grid items-center font-bold">Planlı Duruş</h3>
          <p className="h-[90%] text-center items-center flex text-5xl font-bold">
            {plannedStopTime.toString()}
          </p>
        </div>
        <div className="gap-1 w-1/4 text-white border border-slate-500 shadow-md	shadow-slate-400 flex flex-col text-center items-center bg-slate-600">
          <h3 className="h-[30%] bg-smartopt w-full grid items-center font-bold">Planlı Ön Sac Rework</h3>
          <p className="h-[90%] text-center items-center flex text-5xl font-bold">
            {reworkTime}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewDashboard;
